// conf-rol-assign-modulo.component.ts

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ModuloInterface } from '../../../interfaces/modulo-interface';

@Component({
  selector: 'app-conf-rol-assign-modulo',
  templateUrl: './conf-rol-assign-modulo.component.html',
  styleUrls: ['./conf-rol-assign-modulo.component.css']
})
export class ConfRolAssignModuloComponent implements OnInit {
  treeControl = new NestedTreeControl<ModuloInterface>(node => node.hijos);
  dataSource = new MatTreeNestedDataSource<ModuloInterface>();
  modulosSeleccionados: Set<number> = new Set<number>();

  constructor(
    public dialogRef: MatDialogRef<ConfRolAssignModuloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id_rol: number },
    private moduloConfiguracionService: ModuloConfiguracionService
  ) {}

  ngOnInit(): void {
    this.moduloConfiguracionService.getModulosByRol(this.data.id_rol).subscribe(response => {
      this.dataSource.data = response.data;
      response.modulosAsignados.forEach((modulo: number) => this.modulosSeleccionados.add(modulo));
      this.expandAllNodes(this.dataSource.data);
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  expandAllNodes(nodes: ModuloInterface[]): void {
    nodes.forEach(node => {
      this.treeControl.expand(node);
      if (node.hijos && node.hijos.length > 0) {
        this.expandAllNodes(node.hijos);
      }
    });
  }

  hasChild = (_: number, node: ModuloInterface) => !!node.hijos && node.hijos.length > 0;

  isChecked(node: ModuloInterface): boolean {
    return this.modulosSeleccionados.has(node.id);
  }

  onToggle(node: ModuloInterface): void {
    if (this.isChecked(node)) {
      this.removeModulo(node);
    } else {
      this.addModulo(node);
    }
  }

  addModulo(node: ModuloInterface): void {
    this.modulosSeleccionados.add(node.id);
    if (node.id_padre) {
      this.modulosSeleccionados.add(node.id_padre);
    }
    if (node.hijos) {
      node.hijos.forEach(child => this.addModulo(child));
    }
  }

  removeModulo(node: ModuloInterface): void {
    this.modulosSeleccionados.delete(node.id);
    if (node.hijos) {
      node.hijos.forEach(child => this.removeModulo(child));
    }
  }

  guardarAsignaciones(): void {
    const modulosArray = Array.from(this.modulosSeleccionados).map(id_modulo => ({
      id_modulo,
      activo: 1
    }));
    this.moduloConfiguracionService.assignModulosToRol(this.data.id_rol, modulosArray).subscribe(() => {
      this.dialogRef.close(true);
    });
  }
}
