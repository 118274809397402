// conf-programa-curricular-capacidades-list.component.ts
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ConfProgramaCurricularCapacidadInterface } from '../../../interfaces/conf-programa-curricular-capacidad.interface';

@Component({
  selector: 'app-conf-programa-curricular-capacidades-list',
  templateUrl: './conf-programa-curricular-capacidades-list.component.html',
  styleUrl: './conf-programa-curricular-capacidades-list.component.css'
})
export class ConfProgramaCurricularCapacidadesListComponent implements OnInit {
  capacidadesList: ConfProgramaCurricularCapacidadInterface[] = [];
  filteredCapacidades: any[] = [];
  isFlipped = false;
  capacidadForm: FormGroup;
  isEditing = false;

  displayedColumns: string[] = ['nombre', 'detalle', 'acciones'];


  constructor(
    public dialogRef: MatDialogRef<ConfProgramaCurricularCapacidadesListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private moduloConfiguracionService: ModuloConfiguracionService,
  ) {
    this.capacidadForm = this.fb.group({
      id: [''],
      id_competencia_curricular: [data?.competencia?.id || '', Validators.required],
      nombre: ['', Validators.required],
      descripcion: ['']
    });

  }

  ngOnInit(): void {
    this.loadCapacidades();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  loadCapacidades(): void {
    const params = { id_competencia_curricular: this.data.competencia.id };
    this.moduloConfiguracionService.apiGetCapacidadesCurriculares(params).subscribe((data: any) => {
      this.capacidadesList = data;
      this.filteredCapacidades = [...this.capacidadesList];
    });
  }

  filterCapacidades(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredCapacidades = this.capacidadesList.filter(cap =>
      cap.nombre.toLowerCase().includes(filterValue) ||
      cap.detalle.toLowerCase().includes(filterValue)
    );
  }

  flipCard(): void {
    this.isFlipped = !this.isFlipped;
    if (!this.isFlipped) {
      this.resetForm();
    }
  }





  editCapacidad(capacidad: any): void {
    this.capacidadForm.patchValue(capacidad);
    this.isEditing = true;
    this.flipCard();
  }

  deleteCapacidad(capacidad: any): void {
    this.moduloConfiguracionService.apiDeleteCapacidadesCurricular(capacidad.id).subscribe((response: any) => {
      if (response.success) {
        alert('Capacidad eliminada correctamente.');
        this.loadCapacidades();
      } else {
        alert('Hubo un problema al eliminar la capacidad.');
      }
    });
  }

  saveCapacidad(): void {
    if (this.capacidadForm.valid) {
      const formData = this.capacidadForm.value;
      if (this.isEditing) {
        this.moduloConfiguracionService.apiUpdateCapacidadesCurricular(this.capacidadForm.get('id')?.value, formData).subscribe((response: any) => {
          console.log('Capacidad actualizada:', response);
          this.loadCapacidades();
          this.flipCard();
        });
      } else {
        this.moduloConfiguracionService.apiCreateCapacidadesCurricular(formData).subscribe((response: any) => {
          console.log('Capacidad creada:', response);
          this.loadCapacidades();
          this.flipCard();
        });
      }
    }
  }





  resetForm(): void {
    this.capacidadForm.reset({
      id: '',
      id_competencia_curricular: this.data.competencia.id,
      nombre: '',
      descripcion: ''
    });
    this.isEditing = false;
  }
}
