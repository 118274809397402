<mat-card class="custom-card">
  <mat-card-header>
    <div class="header-content">
      <div class="title-group">
        <div class="title-text">{{titulo}}</div>
      </div>
      <button [matMenuTriggerFor]="menu" aria-label="Menú de opciones" class="menu-button">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu">
      <button *ngFor="let item of menuItems" mat-menu-item (click)="onMenuAction(item.action)">
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.label}}</span>
      </button>
    </mat-menu>
  </mat-card-header>

  <mat-card-content>
    <div *ngIf="codigo" class="code-text">Código: {{codigo}}</div>
    <div class="description-text">{{descripcion ?? 'Sin descripción'}}</div>
    <mat-chip-set>
      <mat-chip *ngFor="let feature of features" class="custom-chip">{{ feature }}</mat-chip>
      <mat-chip *ngIf="activo" class="custom-chip" [ngClass]="activo ? 'active-yes' : 'active-not'">{{ activo ? 'Activo' : 'Desactivado' }}</mat-chip>
    </mat-chip-set>
  </mat-card-content>
</mat-card>
