import { Component } from '@angular/core';

@Component({
  selector: 'app-sv-pdf-ticket-pagos',
  templateUrl: './sv-pdf-ticket-pagos.component.html',
  styleUrl: './sv-pdf-ticket-pagos.component.css'
})
export class SvPdfTicketPagosComponent {

}
