<app-sv-page-content-header
  titulo="Estado Financiero de {{estudiante?.name ?? ''}}{{estudiante ? ' - ' + estudiante.documento_identidad : ''}}">
</app-sv-page-content-header>

<div class="page-content">

  <div class="page-content-header-search">
    <div class="search-container">
      <form [formGroup]="depositoForm" (ngSubmit)="realizarDeposito()">
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>Ingrese monto de depósito</mat-label>
          <input matInput placeholder="Monto de depósito" formControlName="monto" class="search-input" />
          <mat-error *ngIf="depositoForm.get('monto')?.hasError('required')">El monto es requerido</mat-error>
          <mat-error *ngIf="depositoForm.get('monto')?.hasError('min')">El monto debe ser mayor a 0</mat-error>
        </mat-form-field>
        <div class="btn-list-container">
          <button class="btn-status btn-primary" type="submit" [disabled]="depositoForm.invalid">Depositar</button>
        </div>
      </form>
    </div>
  </div>

  Movimientos Académicos

  <!-- <table mat-table [dataSource]="listaMovimientos" class="mat-elevation-z0">

    <ng-container matColumnDef="id_transaccion">
      <th mat-header-cell *matHeaderCellDef> id_transaccion </th>
      <td mat-cell *matCellDef="let element"> {{element.id_transaccion}} </td>
    </ng-container>

    <ng-container matColumnDef="id_cuenta">
      <th mat-header-cell *matHeaderCellDef> id_cuenta </th>
      <td mat-cell *matCellDef="let element"> {{element.id_cuenta}} </td>
    </ng-container>

    <ng-container matColumnDef="id_origen">
      <th mat-header-cell *matHeaderCellDef> id_origen </th>
      <td mat-cell *matCellDef="let element"> {{element.id_origen}} </td>
    </ng-container>

    <ng-container matColumnDef="tipo_origen">
      <th mat-header-cell *matHeaderCellDef> tipo_origen </th>
      <td mat-cell *matCellDef="let element"> {{element.tipo_origen}} </td>
    </ng-container>

    <ng-container matColumnDef="codigo">
      <th mat-header-cell *matHeaderCellDef> codigo </th>
      <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
    </ng-container>

    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef> fecha </th>
      <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
    </ng-container>

    <ng-container matColumnDef="descripcion">
      <th mat-header-cell *matHeaderCellDef> descripcion </th>
      <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
    </ng-container>

    <ng-container matColumnDef="debito">
      <th mat-header-cell *matHeaderCellDef> debito </th>
      <td mat-cell *matCellDef="let element"> {{element.debito}} </td>
    </ng-container>

    <ng-container matColumnDef="credito">
      <th mat-header-cell *matHeaderCellDef> credito </th>
      <td mat-cell *matCellDef="let element"> {{element.credito}} </td>
    </ng-container>

    <ng-container matColumnDef="saldo">
      <th mat-header-cell *matHeaderCellDef> saldo </th>
      <td mat-cell *matCellDef="let element"> {{element.saldo}} </td>
    </ng-container>

    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef> estado </th>
      <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table> -->



  <table mat-table [dataSource]="listaMovimientos" class="mat-elevation-z0">
    <!-- <ng-container matColumnDef="id_transaccion">
      <th mat-header-cell *matHeaderCellDef> ID Transacción </th>
      <td mat-cell *matCellDef="let element"> {{element.id_transaccion}} </td>
    </ng-container>

    <ng-container matColumnDef="id_movimiento">
      <th mat-header-cell *matHeaderCellDef> ID Movimiento </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container> -->

    <ng-container matColumnDef="codigo_transaccion">
      <th mat-header-cell *matHeaderCellDef> Transacción </th>
      <td mat-cell *matCellDef="let element"> {{element.transaccion_codigo}} </td>
    </ng-container>
    <ng-container matColumnDef="codigo_movimiento">
      <th mat-header-cell *matHeaderCellDef> Movimiento </th>
      <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
    </ng-container>
    <ng-container matColumnDef="codigo_comprobante">
      <th mat-header-cell *matHeaderCellDef> Comprobante </th>
      <td mat-cell *matCellDef="let element"> {{element.comprobante_codigo}} </td>
    </ng-container>

    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef> Fecha </th>
      <td mat-cell *matCellDef="let element"> {{element.transaccion_fecha}} </td>
    </ng-container>

    <ng-container matColumnDef="descripcion">
      <th mat-header-cell *matHeaderCellDef> Descripción </th>
      <td mat-cell *matCellDef="let element"> {{element.transaccion_descripcion}} </td>
    </ng-container>

    <ng-container matColumnDef="debito">
      <th mat-header-cell *matHeaderCellDef> Débito </th>
      <td mat-cell *matCellDef="let element"> {{element.debito}} </td>
    </ng-container>

    <ng-container matColumnDef="credito">
      <th mat-header-cell *matHeaderCellDef> Crédito </th>
      <td mat-cell *matCellDef="let element"> {{element.credito}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef> Estado </th>
      <td mat-cell *matCellDef="let element"> {{element.transaccion_estado}} </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <div>Saldo en la cuenta: {{saldoActual}}</div>
</div>
