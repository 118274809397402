import { NgModule } from '@angular/core';

import { VistaModulosRoutingModule } from './vista-modulos-routing.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { VistaModulosComponent } from './components/main/vista-modulos.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CoreModule } from 'src/app/core/core.module';


@NgModule({
  declarations: [
    VistaModulosComponent,
  ],
  imports: [
    CommonModule,
    VistaModulosRoutingModule,

    MatCardModule,
    MatButtonModule,




    BrowserModule,
    AppRoutingModule,

    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,

    CoreModule,


  ]
})
export class VistaModulosModule { }
