<app-sv-page-content-header
  titulo="Lista de Competencias">
</app-sv-page-content-header>
<!--
<table mat-table [dataSource]="listCompetenciasCurriculares" class="mat-elevation-z0">

  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef> Nombre </th>
    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
  </ng-container>

  <ng-container matColumnDef="detalle">
    <th mat-header-cell *matHeaderCellDef> Detalle </th>
    <td mat-cell *matCellDef="let element"> {{element.detalle}} </td>
  </ng-container>

    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element">
        <button class="btn-status-rounded btn-auto-width btn-purple" (click)="openDialogCapacidades(element.id)">
          <mat-icon class="btn-icon">add</mat-icon>
          Competencias
        </button>
      </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table> -->



<div class="container mt-5">
  <h2 class="text-center mb-4"> Programa: {{ serverNombrePrograma }}</h2>
  <h2 class="text-center mb-4"> Área: {{ serverNombreArea }}</h2>
  <button class="btn btn-outline-primary mb-3" (click)="openDialogCrearCompetencia()">
    <i class="fas fa-list-ul me-2"></i>Crear Competencia
  </button>
  <div class="row justify-content-center">
    <div *ngFor="let competencia of listCompetenciasCurriculares" class="col-md-6 mb-4">
      <div class="card shadow-sm h-100">
        <div class="card-body">
          <h3 class="card-title">{{ competencia.nombre }}</h3>
          <div class="mt-3">
            <button class="btn btn-outline-primary me-2" (click)="openDialogCapacidades(competencia)">
              <i class="fas fa-list-ul me-2"></i>Capacidades
            </button>
            <button class="btn btn-outline-success me-2" (click)="openDialogDesempenyos(competencia)">
              <i class="fas fa-chart-line me-2"></i>Desempeños
            </button>

            <button class="btn btn-outline-success me-2" (click)="openDialogEditarCompetencia(competencia)">
              <i class="fas fa-chart-line me-2"></i>Editar
            </button>
            <button class="btn btn-outline-danger me-2" (click)="openDialogEliminarCompetencia(competencia)">
              <i class="fas fa-chart-line me-2"></i>Eliminar
            </button>
            <!-- <button class="btn btn-outline-success me-2" (click)="openDialogEliminar(competencia)">
              <i class="fas fa-chart-line me-2"></i>Eliminar
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
