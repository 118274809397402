import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModuloMatriculaService } from '../../modulo-matricula.service';

@Component({
  selector: 'app-matri-proceso-main-paso2',
  templateUrl: './matri-proceso-main-paso2.component.html',
  styleUrl: './matri-proceso-main-paso2.component.css'
})
export class MatriProcesoMainPaso2Component {

  @Input() xMatriculaForm!: FormGroup;
  @Input() xIdGrupoSeleccionado: any;

  @Output() grupoSeleccionado = new EventEmitter<number>();

  isLoading: boolean = false;

  selectedSede:     number | string = '';
  selectedNivel:    number | string = '';
  selectedGrado:    number | string = '';
  selectedPlanTipo: number | string = '';
  selectedPeriodo:  number | string = '';

  listSedes:        any[] = [];
  listNiveles:      any[] = [];
  listGrados:       any[] = [];
  listPeriodos:     any[] = [];
  listPlanTipos:    any[] = [];

  listPlanesEstudio: any[] = [];

  constructor(
    private fb: FormBuilder,
    private moduloMatriculaService: ModuloMatriculaService
  ) {}

  ngOnInit(): void {
    this.getSedes();
    this.getPlanTipos();
    this.getPeriodos();
    this.getPlanesEstudio();
  }

  getSedes() {
    this.moduloMatriculaService.apiGetSedes().subscribe((data: any) => {
      this.listSedes = data;
      if(this.listSedes.length == 1){
        this.selectedSede = this.listSedes[0].id;
        this.getNiveles();
      }
    });
  }
  getNiveles() {
    const params = { id_sede: this.selectedSede };
    this.moduloMatriculaService.apiGetNiveles(params).subscribe((data: any) => {
      this.listNiveles = data;
      if(this.listNiveles.length == 1){
        this.selectedNivel = this.listNiveles[0].id;
        this.getGrados();
      }
    });
  }
  getGrados() {
    const params = { id_nivel: this.selectedNivel };
    this.moduloMatriculaService.apiGetGrados(params).subscribe((data: any) => {
      this.listGrados = data;
    });
  }
  getPlanTipos() {
    this.moduloMatriculaService.apiGetPlanTipos().subscribe((data: any) => {
      this.listPlanTipos = data;
    });
  }
  getPeriodos() {
    this.moduloMatriculaService.apiGetPeriodos().subscribe((data: any) => {
      this.listPeriodos = data;
    });
  }

  getPlanesEstudio() {
    const params = {
      id_sede       : this.selectedSede,
      id_nivel      : this.selectedNivel,
      id_plan_tipo  : this.selectedPlanTipo,
      id_periodo    : this.selectedPeriodo,
      id_grado      : this.selectedGrado,
    };

    this.isLoading = true;

    this.moduloMatriculaService.apiGetPlanesEstudio(params).subscribe((data: any[]) => {
      this.listPlanesEstudio = data;
      this.isLoading = false;
    });
  }

  onSedeChange(){
    if(this.selectedSede != ''){
      this.getNiveles();
    }else{
     this.listNiveles = [];
     this.listGrados  = [];

     this.selectedNivel = '';
     this.selectedGrado = '';
    }
  }
  onNivelChange(){
    if(this.selectedSede != ''){
      this.getGrados();
    }
  }
  onGradoChange(){}
  onPlanEstudioChange(){}
  onPeriodoChange(){}

  onGrupoSeleccionado(id_grupo: number){
    console.log('id_grupo: ', id_grupo);
    this.xIdGrupoSeleccionado = id_grupo;
    this.xMatriculaForm.get('id_grupo')?.setValue(id_grupo);
    this.grupoSeleccionado.emit(id_grupo);
  }


  getFeatures(planEstudio: any){
    return [
      this.getNombreSede(planEstudio.id_sede),
      this.getNombreNivel(planEstudio.id_nivel),
      this.getNombreGrado(planEstudio.id_grado),
      this.getNombreTipoPlan(planEstudio.id_plan_tipo),
      this.getNombrePeriodo(planEstudio.id_periodo),
    ];
  }

  getNombreNivel(id: number): string {
    const nivel = this.listNiveles.find(n => n.id === id);
    return nivel ? nivel.nombre : '';
  }
  getNombreTipoPlan(id: number): string {
    const tipoPlan = this.listPlanTipos.find(tp => tp.id === id);
    return tipoPlan ? tipoPlan.nombre : '';
  }
  getNombrePeriodo(id: number): string {
    const periodo = this.listPeriodos.find(p => p.id === id);
    return periodo ? periodo.codigo : '';
  }
  getNombreGrado(id: number): string {
    const grado = this.listGrados.find(p => p.id === id);
    return grado ? grado.nombre : 'Sin grado';
  }
  getNombreSede(id: number): string {
    const sede = this.listSedes.find(p => p.id === id);
    return sede ? sede.nombre : '';
  }


}
