import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrarComponent } from './registrar.component';



@NgModule({
  declarations: [
    RegistrarComponent
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule,
    FormsModule,
  ]
})
export class RegistrarModule { }
