import { Injectable, ApplicationRef, ComponentRef, Injector, ChangeDetectorRef } from '@angular/core';
import { SvPdfTicketDepositoComponent } from '../shared-components/sv-pdf-ticket-deposito/sv-pdf-ticket-deposito.component';
import { SvPdfContratoMatriculaComponent } from '../shared-components/pdfs/sv-pdf-contrato-matricula/sv-pdf-contrato-matricula.component';

@Injectable({
  providedIn: 'root'
})
export class SharedPdfComprobantesService {
  constructor(
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  imprimirComprobante(tipo: 'ticket' | 'boleta', params: any) {
    let componente: any;

    switch (tipo) {
      case 'ticket':
        componente = SvPdfTicketDepositoComponent;
        break;
      default:
        console.error('Tipo de comprobante no válido:', tipo);
        return;
    }

    const componentRef: ComponentRef<any> = this.appRef.bootstrap(componente, document.createElement('div'));

    console.log("📌 Antes de asignar datos - instance:", componentRef.instance);

    if (params && componentRef.instance) {
        console.log("✅ Enviando datos al componente con setData()");
        componentRef.instance.setData(params);
    }

    setTimeout(() => {
      const contentElement = componentRef.location.nativeElement;
      document.body.appendChild(contentElement);

      const altura = contentElement.getBoundingClientRect().height;
      const estilos = this.obtenerEstilos();

      this.imprimirElementoTicket(contentElement.innerHTML, altura, estilos);

      document.body.removeChild(contentElement);
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }, 500);
  }


  private imprimirElementoTicket(content: string, altura: number, estilos: string) {
    console.log('La altura es: ', altura);
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0px';
    printFrame.style.height = '0px';
    printFrame.style.border = 'none';
    document.body.appendChild(printFrame);

    const printDocument = printFrame.contentDocument || printFrame.contentWindow?.document;
    if (printDocument) {
      printDocument.open();
      printDocument.write(`
        <html>
          <head>
            <style>
              @page {
                size: 58mm ${altura}px;
                margin: 0;
                background-color: white;
              }
              html, body {
                width: 58mm;
                height: ${altura}px;
                margin: 0;
                padding: 0;
                background-color: white;
                color: black;
                overflow: hidden;
                -webkit-print-color-adjust: economy !important;
                print-color-adjust: economy !important;
              }
              .ticket-container {
                width: 100%;
                height: ${altura}px;
                background-color: white;
              }
              ${estilos}
            </style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `);
      printDocument.close();

      setTimeout(() => {
        printFrame.contentWindow?.print();
      }, 500);

      setTimeout(() => {
        document.body.removeChild(printFrame);
      }, 2000);
    }
  }

  private obtenerEstilos(): string {
    let css = '';
    for (const sheet of Array.from(document.styleSheets)) {
      try {
        for (const rule of Array.from(sheet.cssRules)) {
          css += rule.cssText + '\n';
        }
      } catch (e) {
        console.warn('No se pueden acceder a algunas reglas CSS debido a restricciones de CORS.');
      }
    }
    return css;
  }











  imprimirContrato(params: any) {
    let componente = SvPdfContratoMatriculaComponent;

    const componentRef: ComponentRef<any> = this.appRef.bootstrap(componente, document.createElement('div'));

    console.log("📌 Antes de asignar datos - instance:", componentRef.instance);

    if (params && componentRef.instance) {
        console.log("✅ Enviando datos al componente con setData()");
        componentRef.instance.setData(params);
    }

    setTimeout(() => {
      const contentElement = componentRef.location.nativeElement;
      document.body.appendChild(contentElement);

      const altura = contentElement.getBoundingClientRect().height;
      const estilos = this.obtenerEstilos();

      this.imprimirElementoContrato(contentElement.innerHTML, altura, estilos);

      document.body.removeChild(contentElement);
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }, 500);
  }


  private imprimirElementoContrato(content: string, altura: number, estilos: string) {
    console.log('La altura es: ', altura);
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0px';
    printFrame.style.height = '0px';
    printFrame.style.border = 'none';
    document.body.appendChild(printFrame);

    const printDocument = printFrame.contentDocument || printFrame.contentWindow?.document;
    if (printDocument) {
      printDocument.open();
      printDocument.write(`
        <html>
          <head>
            <style>
              @page {
                size: A4;
                margin: 10mm;
                background-color: white;
              }
              html, body {
                background-color: white;
              }
              ${estilos}
            </style>
          </head>
          <body>
            <div class="contract-container">
              ${content}
            </div>
          </body>
        </html>
      `);
      printDocument.close();

      setTimeout(() => {
        printFrame.contentWindow?.print();
      }, 500);

      setTimeout(() => {
        document.body.removeChild(printFrame);
      }, 2000);
    }
  }



}
