import { Component } from '@angular/core';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ConfUsuariosCreateComponent } from '../conf-usuarios-create/conf-usuarios-create.component';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-conf-usuarios-list',
  templateUrl: './conf-usuarios-list.component.html',
  styleUrl: './conf-usuarios-list.component.css'
})
export class ConfUsuariosListComponent {

  constructor (
    private moduloConfiguracionService: ModuloConfiguracionService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(){
    this.getUsuarios();
  }

  listaUsuarios: Usuario[] = [];

  // Para el paginator
  totalUsuarios: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  searchTerm: string = '';

  getUsuarios(): void {
    // this.moduloConfiguracionService.apiGetUsuarios()
    //   .subscribe((data: Usuario[]) => {
    //     console.log('data: ', data);
    //     this.listaUsuarios = data;
    //   }
    // );
    this.moduloConfiguracionService.apiGetUsuarios(this.pageIndex, this.pageSize, this.searchTerm)
      .subscribe((response: any) => {
        this.listaUsuarios = response.data;
        this.totalUsuarios = response.total;
      }
    );

  }

  // BUSQUEDA
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm = filterValue.trim().toLowerCase();
    if (event.type === 'click' || (event instanceof KeyboardEvent && event.key === 'Enter')) {
      this.pageIndex = 0;
      this.getUsuarios();
    }
  }
  // PAGINACION
  pageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getUsuarios();
  }





  // MODALES

  // openCrearUsuario(){
  //   const dialogRef = this.dialog.open(ConfUsuariosCreateComponent);

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }


  openCrearUsuario(): void {
    const dialogRef = this.dialog.open(ConfUsuariosCreateComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUsuarios();
      }
    });
  }

  openEditarUsuario(usuario: Usuario): void {
    const dialogRef = this.dialog.open(ConfUsuariosCreateComponent, {
      data: { usuario: usuario }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUsuarios();
      }
    });
  }

  deleteUsuario(id: number): void {
    this.moduloConfiguracionService.apiDeleteUsuario(id)
    .subscribe((data: any) => {
        this.getUsuarios();
      });
  }

}


export interface Usuario {
  id:                  number;
  name:                string;
  name_2:              string;
  lastname_1:          string;
  lastname_2:          string;
  email:               string;
  usuario:             string;
  documento_identidad: string;
  tipo_documento:      number;
  domicilio:           string;
  celular:             null;
  nombres:             string;
}
