import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {

  formDataLogin: any = {};

  constructor(
    private router: Router,
    private loginService: LoginService,
  ){}

  formLogin = [
    {
      type: 'text',
      name: 'username',
      placeholder: 'Correo electrónico'
    },
    {
      type: 'password',
      name: 'password',
      placeholder: 'Contraseña'
    }
  ];

  // Credenciales para el administrador
  adminCredentials = {
    username: 'admin',
    password: 'admin'
  };

  // Credenciales para el trabajador
  workerCredentials = {
    username: 'worker',
    password: 'worker'
  };

  clickconsole() {
    if (this.formDataLogin.username === this.adminCredentials.username &&
        this.formDataLogin.password === this.adminCredentials.password) {
      this.router.navigateByUrl('/ficha');

    } else if (this.formDataLogin.username === this.workerCredentials.username &&
               this.formDataLogin.password === this.workerCredentials.password) {
      this.router.navigateByUrl('/contrato');

    } else {
      console.log("Credenciales incorrectas");
      console.log(this.formDataLogin);
    }
  }

  clickLogin(){
    const params = {
      usuario: this.formDataLogin.username,
      password: this.formDataLogin.password,
    };
    this.loginService.login(params).subscribe((data)=>{
      console.log('RESPUESTA LOGIN: ', data)
      if (data.access_token) {
        localStorage.setItem('authToken', data.access_token);
        this.router.navigate(['/menu']);
      }
    });
  }

  // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwMDAvYXBpL2F1dGgvbG9naW4iLCJpYXQiOjE3MTU5ODU3NzcsImV4cCI6MTcxNjU5MDU3NywibmJmIjoxNzE1OTg1Nzc3LCJqdGkiOiJ5QWV6OE5TYUVZS0pPYnplIiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.YJz29kfIuujIqWSGYEppTY415CdmESs_f0jXn9hKyq8

  ToRegistro() {
    this.router.navigateByUrl('/registrar');
  }
}

