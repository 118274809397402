import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-sv-page-content-header',
  templateUrl: './sv-page-content-header.component.html',
  styleUrl: './sv-page-content-header.component.css'
})
export class SvPageContentHeaderComponent {

  @Input() titulo: string = "";

  usuario: any;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.usuario = this.authService.getUsuario();
  }

}
