import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sv-card-matricula-plan-estudio-grupos',
  templateUrl: './sv-card-matricula-plan-estudio-grupos.component.html',
  styleUrl: './sv-card-matricula-plan-estudio-grupos.component.css'
})
export class SvCardMatriculaPlanEstudioGruposComponent {

  @Input() xIdGrupoSeleccionado: any;
  @Input() titulo:    string    = '';
  @Input() grupos:    any[]     = [];
  @Input() features:  string[]  = [];
  @Input() featuresChipsArray?  : Array<{ key: string; value: string; }> = [];

  @Output() grupoSeleccionado = new EventEmitter<number>();

  seleccionarGrupo(id_grupo: number) {
    this.grupoSeleccionado.emit(id_grupo);
  }

}
