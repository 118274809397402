<div class="login-container">
  <h2>{{name}}</h2>
  <form>
    <div *ngFor="let field of formRegistro" class="form-group">
      <label [for]="field.name">{{ field.label }}</label>
      <ng-container *ngIf="field.type !== 'select'">
        <input [type]="field.type" [id]="field.name" [name]="field.name" [placeholder]="field.placeholder"
                      [(ngModel)]="formDataRegistro[field.name] ">
      </ng-container>
      <ng-container *ngIf="field.type === 'select'">
        <select [id]="field.name" [name]="field.name" [(ngModel)]="formDataRegistro[field.name]">
          <option *ngFor="let option of field.options" [value]="option">{{ option }}</option>
        </select>
      </ng-container>
    </div>
    <div class="button-group">
      <button type="submit" (click)="clickconsole()">Registrar</button>
      <button type="submit" (click)="ToLogin()">Iniciar Sesion</button>
    </div>
  </form>
</div>
