// import { HttpClient } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { CanActivateFn, Router } from '@angular/router';
// import { Observable, Subject, catchError, map, of } from 'rxjs';
// import { environment } from 'src/environments/environment';

// export const authGsjGuard: CanActivateFn = (route, state) => {
//   return true;
// };

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthGSJGuard {

//   constructor(private http: HttpClient, private router: Router) { }

//   canActivate(): Observable<boolean> {

//     const token = localStorage.getItem('authToken');

//     if (!token) {
//       this.router.navigate(['/login']);
//       return new Observable<boolean>(observer => observer.next(false));
//     }

//     return this.http.get(environment.apiUrl + '/auth/me')
//       .pipe(
//         map(() => true),
//         catchError((error) => {
//           console.error('Error en la solicitud:', error);
//           this.router.navigate(['/login']);
//           return of(false);
//         })
//       );
//   }
// }



import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGSJGuard implements CanActivate {

  private isAuthenticated = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(): Observable<boolean> {
    if (this.isAuthenticated) {
      return of(true);
    }

    const token = localStorage.getItem('authToken');
    if (!token) {
      this.resetAuthentication();
      this.router.navigate(['/login']);
      return of(false);
    }

    return this.http.get<{ message?: string }>(`${environment.apiUrl}/auth/me`).pipe(
      map((response) => {
        this.isAuthenticated = true;
        this.authService.setUsuario(response);
        return true;
      }),
      catchError((error) => {
        if (error.status === 401) {
          this.resetAuthentication();
          this.router.navigate(['/login']);
        } else {
          console.error('Error en la solicitud:', error);
        }
        return of(false);
      })
    );
  }

  private resetAuthentication(): void {
    this.isAuthenticated = false;
    localStorage.removeItem('authToken');
  }
}
