<h2 mat-dialog-title>{{ isEditing ? 'Editar Secretaria' : 'Crear Secretaria' }}</h2>

<mat-dialog-content>
  <form [formGroup]="secretariaForm" (ngSubmit)="saveSecretaria()">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nombre de la Secretaria</mat-label>
      <input matInput formControlName="nombre" required />
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Sede</mat-label>
      <mat-select formControlName="id_sede" required>
        <mat-option *ngFor="let sede of listSedes" [value]="sede.id">
          {{ sede.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Responsable</mat-label>
      <input
        matInput
        formControlName="nombre_persona"
        placeholder="Buscar persona"
        [readonly]="!!secretariaForm.get('id_persona')?.value"
        [matAutocomplete]="auto"
      />
      <button
        mat-icon-button
        matSuffix
        *ngIf="secretariaForm.get('id_persona')?.value; else searchIcon"
        (click)="clearSelection()">
        <mat-icon>clear</mat-icon>
      </button>
      <ng-template #searchIcon>
        <button
          mat-icon-button
          matSuffix
          (click)="onSearchChange(secretariaForm.get('nombre_persona')?.value)">
          <mat-icon>search</mat-icon>
        </button>
      </ng-template>

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSecretaria($event.option.value)">
        @for (secretaria of searchResults; track secretaria) {
          <mat-option [value]="secretaria">{{secretaria.nombres}}</mat-option>
        }
      </mat-autocomplete>

      <!-- <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displaySecretaria"
        (optionSelected)="selectSecretaria($event.option.value)">
        <mat-option *ngFor="let secretaria of searchResults" [value]="secretaria">
          {{ secretaria.nombres }}
        </mat-option>
      </mat-autocomplete> -->
    </mat-form-field>


    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Detalle</mat-label>
      <textarea matInput formControlName="detalle" rows="3"></textarea>
    </mat-form-field>

  </form>
</mat-dialog-content>


<div mat-dialog-actions align="center">
  <button class="custom-action-button transparent" type="button" (click)="onClose()">Cancelar</button>
  <button class="custom-action-button primary" type="submit" (click)="saveSecretaria()" [disabled]="secretariaForm.invalid">
    {{ isEditing ? 'Actualizar' : 'Crear' }}
  </button>
</div >
