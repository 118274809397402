<div mat-dialog-title>{{ isFlipped ? 'Crear Capacidad Curricular' : 'Lista de Capacidades Curriculares' }}</div>


<div mat-dialog-content>

  <div class="flip-card" [class.flipped]="isFlipped">
    <div class="flip-card-inner">
      <!-- Frente: Lista y Filtro -->
      <div *ngIf="!isFlipped" class="flip-card-front">
        <h2>Capacidades</h2>
        <!-- <mat-form-field appearance="outline" class="w-100">
          <mat-label>Filtrar capacidades</mat-label>
          <input matInput (keyup)="filterCapacidades($event)" placeholder="Buscar...">
        </mat-form-field> -->

        <!-- <mat-list>
          <mat-list-item *ngFor="let capacidad of capacidadesList">
            <h3 matLine>{{ capacidad.nombre }}</h3> qwe
            <p matLine>{{ capacidad.detalle }}</p>
            <button mat-icon-button color="primary" (click)="editCapacidad(capacidad)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="deleteCapacidad(capacidad.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-list-item>
        </mat-list> -->





        <table mat-table [dataSource]="capacidadesList" class="mat-elevation-z0">

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
          </ng-container>

          <ng-container matColumnDef="detalle">
            <th mat-header-cell *matHeaderCellDef> Detalle </th>
            <td mat-cell *matCellDef="let element"> {{element.detalle}} </td>
          </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="editCapacidad(element)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="deleteCapacidad(element)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>












        <!-- <div *ngFor="let capacidad of capacidadesList">
          {{capacidad.nombre}}
          <button mat-icon-button color="primary" (click)="editCapacidad(capacidad)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="deleteCapacidad(capacidad)">
            <mat-icon>delete</mat-icon>
          </button>
        </div> -->

          <button mat-raised-button color="primary" (click)="flipCard()">
            Agregar Nueva Capacidad
          </button>
        </div>

      <!-- Dorso: Formulario de Creación/Edición -->
      <div *ngIf="isFlipped" class="flip-card-back">
        <h2>{{ isEditing ? 'Editar' : 'Crear' }} Capacidad</h2>
        <form [formGroup]="capacidadForm" (ngSubmit)="saveCapacidad()">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" required>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="descripcion" rows="3"></textarea>
          </mat-form-field>

          <div class="button-row">
            <button mat-raised-button color="primary" type="submit" [disabled]="capacidadForm.invalid">
              {{ isEditing ? 'Actualizar' : 'Crear' }}
            </button>
            <button mat-button type="button" (click)="flipCard()">Cancelar</button>
          </div>
        </form>
      </div>
    </div>
  </div>



</div>



<div mat-dialog-actions align="center">
  <button class="custom-action-button transparent" type="button" (click)="onClose()">Cerrar</button>
</div>
