import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScaffoldService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl;

  // apiGetModulosMenu = (params: any)=> this.http.get(this.apiUrl+'/configuration/modulos-home-user', params);
  apiGetModulosSidenav = (params: any)=> this.http.get(this.apiUrl+'/configuration/modulos-menu-user', {params}).pipe(map((r: any) => r.data));

}
