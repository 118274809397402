<div class="tabs">
  <div class="tab-header">
    <div class="active">
      <i class="fa-solid fa-house"></i> Inicio
    </div>
    <div>
      <i class="fa-solid fa-file-import"></i> Ficha
    </div>
    <div>
      <i class="fa-solid fa-file-lines"></i> Contrato
    </div>
    <div>
      <i class="fa-solid fa-file-arrow-up"></i> Subir PDF
    </div>
  </div>
  <div class="tab-indicator"></div>
  <div class="tab-content">

    <div class="active">
      <i class="fa-solid fa-house"></i>
      <h2>Volver a Inicio</h2>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eum similique quisquam officiis neque, cumque dignissimos architecto nisi totam sapiente eos et illum laborum atque vero ea perferendis consectetur veritatis.</p>
      <button class="btn btn-outline-primary" (click)="ToLogin()">Inicio</button>
    </div>

    <div>
      <i class="fa-solid fa-file-import"></i>
      <h2>Generar Ficha</h2>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eum similique quisquam officiis neque, cumque dignissimos architecto nisi totam sapiente eos et illum laborum atque vero ea perferendis consectetur veritatis.</p>
      <button class="btn btn-outline-primary" (click)="ToContrato()">Generer Ficha</button>
    </div>

    <div>
      <i class="fa-solid fa-file-lines"></i>
      <h2>Generar Contrato</h2>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eum similique quisquam officiis neque, cumque dignissimos architecto nisi totam sapiente eos et illum laborum atque vero ea perferendis consectetur veritatis.</p>
      <button class="btn btn-outline-primary" (click)="ToFicha()">Generar Contrato</button>
    </div>

    <div>
      <i class="fa-solid fa-file-arrow-up"></i>
      <h2>Subir Ficha o Contrato</h2>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis eum similique quisquam officiis neque, cumque dignissimos architecto nisi totam sapiente eos et illum laborum atque vero ea perferendis consectetur veritatis.</p>
      <button class="btn btn-outline-primary spaced" (click)="TofichaUpload()">Subir Ficha</button>
      <button class="btn btn-outline-primary" (click)="ToContratoUpload">Subir Contrato</button>
    </div>

  </div>
</div>
