<div class="ticket-container">
  <div class="ticket-logo">
    <img src="/assets/Logo-SJ.png" alt="Logo">
  </div>
  <div class="ticket-owner">
    Colegio San José Juliaca
  </div>
  <div class="ticket-details">
    R.U.C. 20123456789 <br>
    Jr. Gonzales Prada 123 <br>
    Juliaca - Puno
  </div>

  <div class="ticket-divider"></div>

  <div class="ticket-title">
    TICKET {{ responseData.comprobante.cod_comprobante }}
  </div>
  <div class="ticket-serial">
    {{ responseData.transaccion.fecha | date: 'dd/MM/yyyy HH:mm:ss' }}
  </div>

  <div class="ticket-divider"></div>


  <!-- <div *ngIf="responseData">
    {{ responseData }}
    {{ responseData | json }}
  </div> -->


  <div class="invoice-body">
    <table>
      <thead>
        <tr>
          <td><b>Detalle</b></td>
          <td><b>Monto</b></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td> {{ responseData.transaccion.descripcion }}</td>
          <td>S/ {{ responseData.deposito.monto | number: '1.2-2' }}</td>
        </tr>
        <!-- <tr>
          <td>Matrícula</td>
          <td>S/ 200.00</td>
        </tr>
        <tr>
          <td>Mensualidad 1</td>
          <td>S/ 500.00</td>
        </tr>
        <tr>
          <td>Mensualidad 2</td>
          <td>S/ 500.00</td>
        </tr>
        <tr>
          <td>Mensualidad 3</td>
          <td>S/ 500.00</td>
        </tr> -->
      </tbody>
    </table>

    <div class="ticket-divider"></div>

    <table>
      <tbody>
        <tr class="invoice-body-info-item">
          <!-- <td class="info-item-td text-end">Total: S/ 1700.00</td> -->
          <td class="info-item-td text-end">Total: S/ {{ responseData.deposito.monto | number: '1.2-2' }}</td>
        </tr>
      </tbody>
    </table>

  </div>

  <!-- <div class="ticket-divider"></div> -->

</div>
