import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvPageContentHeaderComponent } from './sv-page-content-header/sv-page-content-header.component';
import { SvPdfInvoiceA4Component } from './sv-pdf-invoice-a4/sv-pdf-invoice-a4.component';
import { SvPdfTicketDepositoComponent } from './sv-pdf-ticket-deposito/sv-pdf-ticket-deposito.component';
import { SvCardListComponent } from './sv-card-list/sv-card-list.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { SvCardMatriculaPersonaComponent } from './sv-card-matricula-persona/sv-card-matricula-persona.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SvCardMatriculaPlanEstudioGruposComponent } from './sv-card-matricula-plan-estudio-grupos/sv-card-matricula-plan-estudio-grupos.component';
import { SvLoadingBusComponent } from './sv-loading-bus/sv-loading-bus.component';
import { SvNoResultsFoundComponent } from './sv-no-results-found/sv-no-results-found.component';
import { SvDepositoCardEstudianteExistenteComponent } from './finanzas/sv-deposito-card-estudiante-existente/sv-deposito-card-estudiante-existente.component';
import { SvDepositoCardEstudianteComponent } from './finanzas/sv-deposito-card-estudiante-nuevo/sv-deposito-card-estudiante.component';
import { SvFileUploaderComponent } from './files/sv-file-uploader/sv-file-uploader.component';

@NgModule({
  declarations: [
    SvLoadingBusComponent,
    SvNoResultsFoundComponent,

    SvPageContentHeaderComponent,
    SvPdfInvoiceA4Component,
    SvPdfTicketDepositoComponent,

    SvCardListComponent,
    SvCardMatriculaPersonaComponent,
    SvCardMatriculaPlanEstudioGruposComponent,

    SvDepositoCardEstudianteExistenteComponent,
    SvDepositoCardEstudianteComponent,

    SvFileUploaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,

    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
  ],
  exports: [
    SvLoadingBusComponent,
    SvNoResultsFoundComponent,

    SvPageContentHeaderComponent,
    SvPdfInvoiceA4Component,
    SvPdfTicketDepositoComponent,

    SvCardListComponent,
    SvCardMatriculaPersonaComponent,
    SvCardMatriculaPlanEstudioGruposComponent,

    SvDepositoCardEstudianteExistenteComponent,
    SvDepositoCardEstudianteComponent,

    SvFileUploaderComponent,
  ]
})
export class SvComponentsModule { }
