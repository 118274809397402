<div class="ticket-container">
  <div class="ticket-logo">
    <img src="/assets/Logo-SJ.png" alt="Logo">
  </div>
  <div class="ticket-owner">
    Colegio San José Juliaca
  </div>
  <div class="ticket-details">
    R.U.C. 20123456789 <br>
    <br>
    Jr. Gonzales Prada 123 <br>
    Juliaca - Puno
  </div>

  <div class="ticket-divider"></div>

  <div class="ticket-title">
    Boleta de Venta
  </div>
  <div class="ticket-serial">
    B001 - 00000001 <br>
    08/08/2024 15:15:15
  </div>

  <div class="ticket-divider"></div>

  <div class="ticket-content">
    <div class="ticket-content-detail"></div>
    <div class="ticket-content-total"></div>
  </div>

</div>



<!-- <div class="ticket" id="print-area">
  <div class="ticket-header">
    <h2>Tienda XYZ</h2>
    <p>Dirección: Calle Falsa 123</p>
    <p>Teléfono: (01) 123-4567</p>
  </div>
  <div class="ticket-body">
    <p class="ticket-date">Fecha: 05/12/2024</p>
    <table>
      <thead>
        <tr>
          <th>Descripción</th>
          <th>Cant</th>
          <th>Precio</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Producto 1</td>
          <td>2</td>
          <td>S/ 50.00</td>
          <td>S/ 100.00</td>
        </tr>
        <tr>
          <td>Producto 2</td>
          <td>1</td>
          <td>S/ 100.00</td>
          <td>S/ 100.00</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="ticket-footer">
    <p>Subtotal: S/ 200.00</p>
    <p>IGV (18%): S/ 36.00</p>
    <p>Total: S/ 236.00</p>
    <p class="ticket-thanks">¡Gracias por su compra!</p>
  </div>
</div> -->
