import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IniciarcontratoComponent } from './iniciarcontrato/iniciarcontrato.component';
import { ContratoComponent } from './iniciarcontrato/contrato/contrato.component';
import { FichaComponent } from './iniciarcontrato/ficha/ficha.component';
import { AuthGuard } from './auth.guard';
import { VistaModulosComponent } from './pages/vista-modulos/components/main/vista-modulos.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/auth.interceptor';
import { LoginModule } from './pages/login/login.module';
import { RegistrarModule } from './pages/registrar/registrar.module';
import { VistaModulosModule } from './pages/vista-modulos/vista-modulos.module';
import { ModuloConfiguracionModule } from './pages/modulo-configuracion/modulo-configuracion.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMatPaginatorIntl } from './custom-mat-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ModuloMatriculaModule } from './pages/modulo-matricula/modulo-matricula.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ModuloContabilidadModule } from './pages/modulo-contabilidad/modulo-contabilidad.module';

@NgModule({
  declarations: [
    AppComponent,
    ContratoComponent,
    FichaComponent,
    IniciarcontratoComponent,
  ],
  imports: [

    CoreModule,

    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,



    LoginModule,
    RegistrarModule,

    VistaModulosModule,

    //MODULOS
    ModuloConfiguracionModule,
    ModuloMatriculaModule,
    ModuloContabilidadModule,



  ],
  providers: [
    provideAnimationsAsync(),
    // AuthInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: MAT_DATE_LOCALE, useValue: 'es-PE' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
