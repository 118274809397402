import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuloContabilidadRoutingModule } from './modulo-contabilidad-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule, MatCardSubtitle } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';

import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ContEstudiantesListComponent } from './components/cont-estudiantes-list/cont-estudiantes-list.component';
import { ContEstudianteEstadoFinancieroComponent } from './components/cont-estudiante-estado-financiero/cont-estudiante-estado-financiero.component';
import { ContMainComponent } from './cont-main.component';
import { MatTableModule } from '@angular/material/table';
import { SvComponentsModule } from 'src/app/shared-components/sv-components.module';
import { ContComprobantesListComponent } from './components/cont-comprobantes-list/cont-comprobantes-list.component';
import { ContEstudianteContratosComponent } from './components/cont-estudiante-contratos/cont-estudiante-contratos.component';
import { ContEstudiantePagosComponent } from './components/cont-estudiante-pagos/cont-estudiante-pagos.component';
import { MatListModule, MatListOption } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { FlujoCajaComponent } from './components/flujo-caja/flujo-caja.component';


@NgModule({
  declarations: [
    ContMainComponent,

    ContEstudiantesListComponent,
    ContEstudianteEstadoFinancieroComponent,

    ContEstudianteContratosComponent,
    ContEstudiantePagosComponent,

    ContComprobantesListComponent,
    FlujoCajaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModuloContabilidadRoutingModule,

    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatCardSubtitle,


    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatPaginatorModule,

    MatOptionModule,
    MatListOption,
    MatListModule,

    MatCheckboxModule,

    MatDialogModule,
    SvComponentsModule,

  ]
})
export class ModuloContabilidadModule { }
