import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-iniciarcontrato',
  templateUrl: './iniciarcontrato.component.html',
  styleUrls: ['./iniciarcontrato.component.css']
})
export class IniciarcontratoComponent implements OnInit {
  constructor(private router: Router){}

  ToLogin(){
    this.router.navigateByUrl('/login');
  }
  ToFicha(){
    this.router.navigateByUrl('/ficha');
  }
  ToContrato(){
    this.router.navigateByUrl('/contrato');
  }
  TofichaUpload(){
    this.router.navigateByUrl('/subirficha');
  }
  ToContratoUpload(){
    this.router.navigateByUrl('/subircontrato');
  }

  ngOnInit(): void {
    const _class = (name: string) => {
      return document.getElementsByClassName(name);
    };

    let tabHeader = _class("tab-header")[0] as HTMLElement;
    let tabPanes = tabHeader.getElementsByTagName("div");

    for (let i = 0; i < tabPanes.length; i++) {
      tabPanes[i].addEventListener("click", () => {
        _class("tab-header")[0].getElementsByClassName("active")[0].classList.remove("active");
        tabPanes[i].classList.add("active");

        (_class("tab-indicator")[0] as HTMLElement).style.top = `calc(80px + ${i * 50}px)`;

        (_class("tab-content")[0].getElementsByClassName("active")[0] as HTMLElement).classList.remove("active");
        (_class("tab-content")[0].getElementsByTagName("div")[i] as HTMLElement).classList.add("active");
      });
    }
  }
}
