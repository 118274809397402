<div mat-dialog-title>Lista de Pagos</div>

<div mat-dialog-content>

  <table mat-table [dataSource]="listaPagos" class="mat-elevation-z0">
    <ng-container matColumnDef="select_costo">
      <th mat-header-cell *matHeaderCellDef>Seleccionar</th>
      <td mat-cell *matCellDef="let element">
          <mat-checkbox
              *ngIf="!element.pagado"
              [(ngModel)]="element.selected"
              [checked]="isChecked(element.tipo)"
              (change)="onCostSelectionChange(element)">
          </mat-checkbox>
          <!-- <mat-icon *ngIf="element.pagado">check_circle</mat-icon> -->
      </td>
    </ng-container>


    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef colspan="3"> Nombre </th>
        <td mat-cell *matCellDef="let element" colspan="3"> {{element.nombre}} </td>
    </ng-container>
    <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef> Tipo </th>
        <td mat-cell *matCellDef="let element">
            {{element.tipo == 1 ? 'Matrícula' : element.tipo == 2 ? 'Mensualidad' : '-'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="monto_final">
        <th mat-header-cell *matHeaderCellDef> Monto </th>
        <td mat-cell *matCellDef="let element"> S/ {{element.monto_final}} </td>
    </ng-container>
    <ng-container matColumnDef="fecha_pago_final">
        <th mat-header-cell *matHeaderCellDef> Fecha de Pago </th>
        <td mat-cell *matCellDef="let element">
            {{element.fecha_pago_final | date: 'dd/MM/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="pagado">
      <th mat-header-cell *matHeaderCellDef> Estado </th>
      <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.pagado">check_circle</mat-icon>
          <!-- <span *ngIf="element.pagado">Pagado</span> -->
          <span *ngIf="!element.pagado">Pendiente</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element">
          <button mat-button *ngIf="element.pagado" (click)="descargarComprobante(element.id_comprobante)">
              <mat-icon>download</mat-icon> Descargar Comprobante
          </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <div class="summary">
    <p>Saldo Actual: S/ {{saldoActual}}</p>
    <p>Total a Pagar: S/ {{totalAPagar}}</p>
    <!-- <button mat-raised-button color="primary" [disabled]="totalAPagar > saldoActual" (click)="matricular(6)">Matricular Con Boleta</button>
    <button mat-raised-button color="primary" [disabled]="totalAPagar > saldoActual" (click)="matricular(9)">Matricular Con Ticket</button>

    <button mat-raised-button color="secondary" (click)="generarComprobanteBoletaA4()">Boleta de Venta A4</button>
    <button mat-raised-button color="secondary" (click)="generarComprobanteBoletaTicketera()">Boleta de Venta Ticketera</button> -->

    <div #printSection_BoletaA4 style="display: none;">
      <!-- <app-sv-pdf-invoice-a4 [title]="'Boleta de Venta'" [content]="'Contenido de la boleta...'"></app-sv-pdf-invoice-a4> -->
    </div>
    <div #printSection_BoletaTicketera style="display: none;">
      <!-- <app-sv-pdf-ticket-deposito [title]="'Boleta de Venta'" [content]="'Contenido de la boleta...'"></app-sv-pdf-ticket-deposito> -->
    </div>

  </div>


</div>


<div mat-dialog-actions align="center">
  <button class="custom-action-button transparent" type="button" (click)="onCancel()">Cancelar</button>
  <button class="custom-action-button primary" type="submit" (click)="onSubmit()">{{ data.isEdit ? 'Guardar Cambios' : 'Crear' }}</button>
</div>
