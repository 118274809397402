import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.css']
})
export class RegistrarComponent {

  formDataRegistro: any = {
    tipodocument: 'Seleccione Tipo de Documento',
    operador: 'Seleccione Operador'
  };

  name = "REGISTRO DE USUARIO"
  formRegistro = [
    {
      label: 'Correo:',
      type: 'text',
      name: 'email',
      placeholder: 'Correo electrónico' },

    {
      label: 'Nombre completo:',
      type: 'text',
      name: 'name',
      placeholder: 'Nombre Completo' },

    {
      label: 'Tipo de documento:',
      type: 'select',
      name: 'tipodocument',
      options: ['Seleccione Tipo de Documento', 'DNI', 'Extranjería', 'Pasaporte'],
      placeholder: 'Seleccione un valor' },

    {
      label: 'Documento:',
      type: 'text',
      name: 'dni',
      placeholder: 'Ingrese Num. de Documento' },

    {
      label: 'Numero de celular:',
      type: 'text',
      name: 'phone',
      placeholder: 'Ingrese numero de Celular' },

    {
      label: 'Operador:',
      type: 'select',
      name: 'operador',
      options: ['Seleccione Operador', 'Bitel', 'Claro', 'Movistar'],
      placeholder: 'Seleccione un valor' },

    {
      label: 'Crea una contraseña:',
      type: 'password',
      name: 'password',
      placeholder: 'Contraseña Nueva' },

    {
      label: 'Vuelva a escribir la contraseña:',
      type: 'password',
      name: 'confirmPassword',
      placeholder: 'Confirmar Contraseña' },

    {
      label: 'Domicilio:',
      type: 'text',
      name: 'address',
      placeholder: 'Domicilio Actual' },
  ];
  constructor(private router: Router){}
  clickconsole(){
    console.log(this.formDataRegistro);
  }

  ToLogin(){
    this.router.navigateByUrl('/login');
  }

}
