import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ConfRolesEditComponent } from '../conf-roles-edit/conf-roles-edit.component';
import { ConfRolAssignUsuarioComponent } from '../conf-rol-assign-usuario/conf-rol-assign-usuario.component';
import { ConfRolAssignModuloComponent } from '../conf-rol-assign-modulo/conf-rol-assign-modulo.component';
// import { ConfRolesCreateComponent } from '../conf-roles-create/conf-roles-create.component';

@Component({
  selector: 'app-conf-roles-list',
  templateUrl: './conf-roles-list.component.html',
  styleUrls: ['./conf-roles-list.component.css']
})
export class ConfRolesListComponent implements OnInit {
  displayedColumns: string[] = ['nombre', 'codigo', 'activo', 'acciones'];
  dataSource: RolModel[] = [];

  constructor(
    private moduloConfiguracionService: ModuloConfiguracionService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getRoles();
  }

  getRoles(): void {
    this.moduloConfiguracionService.apiGetRoles()
      .subscribe((data: RolModel[]) => {
        this.dataSource = data;
      });
  }

  openCrearRol(): void {
    const dialogRef = this.dialog.open(ConfRolesEditComponent, {
      data: { rol: null, es_edit: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getRoles();
      }
    });
  }

  openEditarRol(rol: RolModel): void {
    const dialogRef = this.dialog.open(ConfRolesEditComponent, {
      data: { rol: rol, es_edit: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getRoles();
      }
    });
  }

  deleteRol(id: number): void {
    this.moduloConfiguracionService.apiDeleteRol(id).subscribe(() => {
      this.getRoles();
    });
  }


  // ACCIONES

  openAssignUsuario(rol: RolModel){
    const dialogRef = this.dialog.open(ConfRolAssignUsuarioComponent, {
      data: { rol: rol },
      width: '75%',
      height: '75%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) { }
    });
  }

  openAssignModulo(id_rol: number){
    const dialogRef = this.dialog.open(ConfRolAssignModuloComponent, {
      data: { id_rol: id_rol },
      width: '75%',
      height: '75%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) { }
    });
  }


}



export interface RolModel {
  id:       number;
  nombre:   string;
  activo:   number;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

