import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'app-sv-pdf-contrato-matricula',
  templateUrl: './sv-pdf-contrato-matricula.component.html',
  styleUrl: './sv-pdf-contrato-matricula.component.css'
})
export class SvPdfContratoMatriculaComponent {

  title: string = "";
  content: string = "";
  responseData: any;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }



  setData(params: any) {
    console.log("📌 Datos recibidos en setData:", params);
    this.title = params.nombre;
    this.content = params.detalle;
    this.responseData = params.responseDeposito;
    this.cdr.detectChanges();
  }

}
