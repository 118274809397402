<div
  class="file-upload-wrapper"
  [class.success]="uploadSuccess"
  [class.error]="uploadError"
  (drop)="onFileDrop($event)"
  (dragover)="onDragOver($event)"
>
  <input
    type="file"
    accept="image/*"
    (change)="onFileChange($event)"
    hidden
    #fileInput
  />
  <div class="file-dropper" (click)="fileInput.click()">
    <div class="w-100 text-center" *ngIf="!imageName()">
      <mat-icon>upload</mat-icon>
      <!-- <p>{{ "ACTIONS.UPLOAD_PLACEHOLDER" | translate }}</p> -->
      <p> Arrastrar o click para subir una imagen </p>
    </div>
    <div *ngIf="imageName() && (selectedFile || imagePreview)" class="w-100">
      <div class="row w-100">
        <div class="col-6">
          <img
            [src]="imagePreview()"
            alt="Image Preview"
            class="image-preview"
          /></div>
        <div class="col-6 file-info">
          <p>
            <span class="image-name">{{ imageName() }}</span> ({{
              fileSize()
            }} KB)
          </p>

          <button mat-flat-button color="warning" (click)="removeImage($event)"><mat-icon class="delete-icon">delete</mat-icon>Quitar imagen</button>
        </div>
      </div>
      <!-- <mat-icon class="delete-icon" (click)="removeImage()">delete</mat-icon> -->
    </div>
  </div>
</div>
