import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuloConfiguracionRoutingModule } from './modulo-configuracion-routing.module';
import { ConfUsuariosMainComponent } from './conf-usuarios-main.component';
import { ConfRolesListComponent } from './components/rol/conf-roles-list/conf-roles-list.component';
import { ConfUsuariosListComponent } from './components/usuario/conf-usuarios-list/conf-usuarios-list.component';
import { MatListModule } from '@angular/material/list';
import { ConfModulosListComponent } from './components/modulo/conf-modulos-list/conf-modulos-list.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


import { ConfRolesEditComponent } from './components/rol/conf-roles-edit/conf-roles-edit.component';
import { MatCardModule, MatCardSubtitle } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { ConfUsuariosCreateComponent } from './components/usuario/conf-usuarios-create/conf-usuarios-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfModulosCreateComponent } from './components/modulo/conf-modulos-create/conf-modulos-create.component';
import { ConfRolAssignModuloComponent } from './components/rol/conf-rol-assign-modulo/conf-rol-assign-modulo.component';
import { ConfRolAssignUsuarioComponent } from './components/rol/conf-rol-assign-usuario/conf-rol-assign-usuario.component';
import { SvComponentsModule } from 'src/app/shared-components/sv-components.module';
import { ConfProgramaCurricularListComponent } from './components/programa-curricular/conf-programa-curricular-list/conf-programa-curricular-list.component';
import { ConfProgramaCurricularAreasListComponent } from './components/programa-curricular/conf-programa-curricular-areas-list/conf-programa-curricular-areas-list.component';
import { ConfProgramaCurricularCompetenciasListComponent } from './components/programa-curricular/conf-programa-curricular-competencias-list/conf-programa-curricular-competencias-list.component';
import { ConfProgramaCurricularCompetenciasEditComponent } from './components/programa-curricular/conf-programa-curricular-competencias-edit/conf-programa-curricular-competencias-edit.component';
import { ConfProgramaCurricularAreasEditComponent } from './components/programa-curricular/conf-programa-curricular-areas-edit/conf-programa-curricular-areas-edit.component';
import { ConfProgramaCurricularCapacidadesListComponent } from './components/programa-curricular/conf-programa-curricular-capacidades-list/conf-programa-curricular-capacidades-list.component';
import { ConfProgramaCurricularDesempenyosListComponent } from './components/programa-curricular/conf-programa-curricular-desempenyos-list/conf-programa-curricular-desempenyos-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { ConfSoporteSecretariasListComponent } from './components/soporte/conf-soporte-secretarias-list/conf-soporte-secretarias-list.component';
import { ConfSoporteSecuenciasListComponent } from './components/soporte/conf-soporte-secuencias-list/conf-soporte-secuencias-list.component';
import { ConfSoporteSecretariasEditComponent } from './components/soporte/conf-soporte-secretarias-edit/conf-soporte-secretarias-edit.component';



@NgModule({
  declarations: [
    ConfUsuariosMainComponent,

    ConfRolesListComponent,
    ConfRolesEditComponent,
    ConfRolAssignUsuarioComponent,
    ConfRolAssignModuloComponent,

    ConfUsuariosListComponent,
    ConfUsuariosCreateComponent,

    ConfModulosListComponent,
    ConfModulosCreateComponent,

    ConfProgramaCurricularListComponent,
    ConfProgramaCurricularAreasListComponent,
    ConfProgramaCurricularAreasEditComponent,
    ConfProgramaCurricularCompetenciasListComponent,
    ConfProgramaCurricularCompetenciasEditComponent,
    ConfProgramaCurricularCapacidadesListComponent,
    ConfProgramaCurricularDesempenyosListComponent,

    ConfSoporteSecretariasListComponent,
    ConfSoporteSecretariasEditComponent,
    ConfSoporteSecuenciasListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModuloConfiguracionRoutingModule,

    MatListModule,
    MatIconModule,
    MatCardModule,
    MatCardSubtitle,

    MatChipsModule,
    MatAutocompleteModule,
    MatOptionModule,

    MatMenuModule,

    // MatCardContent,
    // MatCardActions,

    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatPaginatorModule,
    MatTabsModule,
    MatTreeModule,
    MatCheckboxModule,

    SvComponentsModule,
  ]
})
export class ModuloConfiguracionModule { }
