<app-sv-page-content-header
  titulo="Lista de Secretarias">
</app-sv-page-content-header>



<div class="page-content">
  <div class="page-content-body h-100">

    <!-- <div class="w-100 d-flex" style="justify-content: space-between;">
      <div style="font-size: 24px; font-weight: bold; display: flex; display: flex; vertical-align: middle; align-items: center; margin-bottom: 1rem; margin-top: -1rem;">Resultados</div>
    </div> -->

    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="h4">Lista de Secretarias</div>
      <button class="custom-action-button primary wpx200 m-0" type="button" (click)="openCrearSecretaria()">
        Crear Secretaria
      </button>
    </div>

    <div class="card-list-container">
      <mat-card *ngFor="let secretaria of listSoporteSecretarias" class="custom-mat-card" appearance="outlined">
        <mat-card-header>
          <div class="header-content">
            <div class="header-text">
              <mat-card-title>{{secretaria.nombre}}</mat-card-title>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menú">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openEditarSecretaria(secretaria)">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
              <button mat-menu-item (click)="deleteSecretaria(secretaria.id)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <mat-card-content>
          <b>Sede:</b> {{secretaria.nombre_sede}}
          <br>
          <b>Responsable:</b> {{secretaria.nombre_persona}}
          <br><br>
          <b>Secuencias Activas:</b>

          <ul>
            <li *ngFor="let secuencia of secretaria.secuencias">
              {{ secuencia.codigo_final }} ({{ secuencia.tipo }})
            </li>
          </ul>
          <!-- <mat-chip-set>
            <mat-chip> {{ getNombreSede   (secretaria.id_sede)   }} </mat-chip>
          </mat-chip-set> -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
