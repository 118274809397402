import { Component, Inject } from '@angular/core';
import { ConfProgramaCurricularDesempenyoInterface } from '../../../interfaces/conf-programa-curricular-desempenyos.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ConfProgramaCurricularGradoInterface } from '../../../interfaces/conf-programa-curricular-grado.interface';
import { ConfProgramaCurricularCompetenciaInterface } from '../../../interfaces/conf-programa-curricular-competencia.interface';

@Component({
  selector: 'app-conf-programa-curricular-desempenyos-list',
  templateUrl: './conf-programa-curricular-desempenyos-list.component.html',
  styleUrl: './conf-programa-curricular-desempenyos-list.component.css'
})
export class ConfProgramaCurricularDesempenyosListComponent {

  xCompetenciaModel?: ConfProgramaCurricularCompetenciaInterface;

  desempenyosList: ConfProgramaCurricularDesempenyoInterface[] = [];
  filteredDesempeños: any[] = [];
  isFlipped = false;
  desempenyoForm: FormGroup;
  isEditing = false;

  listGradosGurriculares: ConfProgramaCurricularGradoInterface[] = []
  idGradoSelected: any;

  displayedColumns: string[] = ['nombre_grado', 'nombre', 'detalle', 'acciones'];

  constructor(
    public dialogRef: MatDialogRef<ConfProgramaCurricularDesempenyosListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private moduloConfiguracionService: ModuloConfiguracionService,
  ) {
    this.xCompetenciaModel = data?.competencia;
    this.desempenyoForm = this.fb.group({
      id: [''],
      id_grado_curricular: ['', Validators.required],
      id_competencia_curricular: [data?.competencia?.id || '', Validators.required],
      nombre: ['', Validators.required],
      descripcion: ['']
    });
  }

  ngOnInit(): void {
    this.loadDesempeños();
    this.loadGrados();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  applyGradoSelected(){
    this.desempenyoForm.get('id_grado_curricular')?.setValue(this.idGradoSelected);
  }

  loadDesempeños(): void {
    const params = {
      id_competencia_curricular: this.data.competencia.id,
      // id_grado_curricular: this.idGradoSelected
    };
    this.moduloConfiguracionService.apiGetDesempenyosCurriculares(params).subscribe((data: any) => {
      this.desempenyosList = data;
      this.filteredDesempeños = [...this.desempenyosList];
    });
  }
  loadGrados(): void {
    const params = { id_competencia_curricular: this.data.competencia.id };
    this.moduloConfiguracionService.apiGetGradosCurriculares(params).subscribe((data: any) => {
      this.listGradosGurriculares = data;
      // this.filteredDesempeños = [...this.desempenyosList];
    });
  }



  filterDesempeños(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredDesempeños = this.desempenyosList.filter(des =>
      des.nombre.toLowerCase().includes(filterValue) ||
      des.detalle.toLowerCase().includes(filterValue)
    );
  }

  flipCard(): void {
    this.isFlipped = !this.isFlipped;
    if (!this.isFlipped) {
      this.resetForm();
    }
  }

  resetForm(): void {
    this.desempenyoForm.reset({
      id: '',
      id_competencia_curricular: this.data.competencia.id,
      id_grado_curricular: this.idGradoSelected,
      nombre: '',
      descripcion: ''
    });
    this.isEditing = false;
  }

  editDesempenyo(desempeño: any): void {
    this.applyGradoSelected();
    this.desempenyoForm.patchValue(desempeño);
    this.isEditing = true;
    this.flipCard();
  }

  deleteDesempenyo(desempeño: any): void {
    this.moduloConfiguracionService.apiDeleteDesempenyosCurricular(desempeño.id).subscribe((response: any) => {
      if (response.success) {
        alert('Desempeño eliminado correctamente.');
        this.loadDesempeños();
      } else {
        alert('Hubo un problema al eliminar el desempeño.');
      }
    });
  }

  saveDesempenyo(): void {
    if (this.desempenyoForm.valid) {
      const formData = this.desempenyoForm.value;
      if (this.isEditing) {
        this.moduloConfiguracionService.apiUpdateDesempenyosCurricular(this.desempenyoForm.get('id')?.value, formData).subscribe((response: any) => {
          console.log('Desempeño actualizado:', response);
          this.loadDesempeños();
          this.flipCard();
        });
      } else {
        this.moduloConfiguracionService.apiCreateDesempenyosCurricular(formData).subscribe((response: any) => {
          console.log('Desempeño creado:', response);
          this.loadDesempeños();
          this.flipCard();
        });
      }
    }
  }

}
