import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sv-card-list',
  templateUrl: './sv-card-list.component.html',
  styleUrl: './sv-card-list.component.css'
})
export class SvCardListComponent {
  @Input() titulo:        string      = '';
  @Input() codigo?:       string      = '';
  @Input() descripcion?:  string      = '';
  @Input() type:          string      = '';
  @Input() features:      string[]    = [];
  @Input() menuItems:     MenuItem[]  = [];
  @Input() activo?:       boolean     = false;

  @Output() actionClicked: EventEmitter<string> = new EventEmitter<string>();

  onMenuAction(action: string) {
    this.actionClicked.emit(action);
  }

}

interface MenuItem {
  icon:   string;
  label:  string;
  action: string;
}
