import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloContabilidadService } from '../../modulo-contabilidad.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cont-estudiante-estado-financiero',
  templateUrl: './cont-estudiante-estado-financiero.component.html',
  styleUrls: ['./cont-estudiante-estado-financiero.component.css']
})
export class ContEstudianteEstadoFinancieroComponent implements OnInit {

  id_estudiante: number = 0;
  depositoForm: FormGroup;
  saldoActual: number = 0;

  constructor(
    private fb: FormBuilder,
    private moduloContabilidadService: ModuloContabilidadService,
    private route: ActivatedRoute,
  ) {
    this.depositoForm = this.fb.group({
      monto: ['', [Validators.required, Validators.min(1)]]
    });
  }

  estudiante: any;
  listaMovimientos: any[] = [];
  // displayedColumns: string[] = [
  //   'id_transaccion',
  //   'id_cuenta',
  //   'id_origen',
  //   'tipo_origen',
  //   'codigo',
  //   'fecha',
  //   'descripcion',
  //   'debito',
  //   'credito',
  //   'saldo',
  //   'estado',
  // ];
  displayedColumns: string[] = [
    // 'id_transaccion',
    // 'id_movimiento',
    'codigo_transaccion',
    'codigo_movimiento',
    'codigo_comprobante',
    'fecha',
    'descripcion',
    'debito',
    'credito',
    // 'estado',
  ];

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id_estudiante = Number(params.get('id'));
      this.getCostos();
    });
  }

  getCostos() {
    const params = {
      id_estudiante: this.id_estudiante,
    };
    this.moduloContabilidadService.apiGetEstadoDeCuenta(params)
      .subscribe((data: any) => {
        this.estudiante = data.estudiante;
        this.listaMovimientos = data.movimientos;
        this.saldoActual = data.saldo;
      });
  }

  realizarDeposito() {
    if (this.depositoForm.valid) {
      const params = {
        id_estudiante: this.id_estudiante,
        monto: this.depositoForm.get('monto')?.value,
      };
      this.moduloContabilidadService.apiRealizarDeposito(params)
        .subscribe((data: any) => {
          this.getCostos();
        });
    }
  }

}
