import { Component, OnInit } from '@angular/core';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { ConfModulosCreateComponent } from '../conf-modulos-create/conf-modulos-create.component';

@Component({
  selector: 'app-conf-modulos-list',
  templateUrl: './conf-modulos-list.component.html',
  styleUrls: ['./conf-modulos-list.component.css']
})
export class ConfModulosListComponent implements OnInit {
  private transformer = (node: ModuloModel, level: number) => {
    return {
      expandable: !!node.hijos && node.hijos.length > 0,
      name: node.nombre,
      level: level,
      nivel: node.nivel,
      id: node.id,
      id_padre: node.id_padre,
      url: node.url,
      activo: node.activo,
      hijos: node.hijos
    };
  };

  treeControl = new FlatTreeControl<ModuloFlatModel>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.hijos
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ModuloFlatModel) => node.expandable;

  constructor(
    private moduloConfiguracionService: ModuloConfiguracionService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getModulos();
  }

  getModulos(): void {
    this.moduloConfiguracionService.apiGetModulos()
      .subscribe((data: ModuloModel[]) => {
        this.dataSource.data = data;
      });
  }

  openCrearModulo(): void {
    const dialogRef = this.dialog.open(ConfModulosCreateComponent, {
      data: { modulo: null, es_edit: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getModulos();
      }
    });
  }

  openCrearSubmodulo(modulo: ModuloFlatModel): void {
    const dialogRef = this.dialog.open(ConfModulosCreateComponent, {
      // data: { parent_id: modulo.id, parent_nivel: modulo.nivel }
      data: { modulo: modulo, es_edit: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getModulos();
      }
    });
  }

  openEditarModulo(modulo: ModuloFlatModel): void {
    console.log('modulo: ', modulo);
    const dialogRef = this.dialog.open(ConfModulosCreateComponent, {
      data: { modulo: modulo, es_edit: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getModulos();
      }
    });
  }

  deleteModulo(id: number): void {
    this.moduloConfiguracionService.apiDeleteModulo(id).subscribe(() => {
      this.getModulos();
    });
  }
}


export interface ModuloModel {
  id: number;
  id_padre: number;
  nivel: number;
  nombre: string;
  url: string;
  activo: number;
  hijos: ModuloModel[];
}

export interface ModuloFlatModel {
  expandable: boolean;
  name: string;
  level: number;
  nivel: number;
  id: number;
  id_padre: number;
  url: string;
  activo: number;
  hijos: ModuloModel[];
}
