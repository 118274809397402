import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloMatriculaService } from '../../modulo-matricula.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanEstudioInterface } from 'src/app/pages/modulo-administracion/interfaces/plan-estudio-interface';
import { ModuloAdministracionService } from 'src/app/pages/modulo-administracion/modulo-administracion.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SharedComponentsService } from 'src/app/shared-components/shared-components.service';

@Component({
  selector: 'app-matri-proceso-main',
  templateUrl: './matri-proceso-main.component.html',
  styleUrls: ['./matri-proceso-main.component.css']
})
export class MatriProcesoMainComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('printSection_BoletaA4', { static: false }) printSection_BoletaA4!: ElementRef;
  @ViewChild('printSection_BoletaTicketera', { static: false }) printSection_BoletaTicketera!: ElementRef;

  xIdEstudiante: number = 0;

  listPlanEstudios: PlanEstudioInterface[] = [];

  listNiveles:    any[] = [];
  listGrados:     any[] = [];
  listPeriodos:   any[] = [];
  listPlanTipos:  any[] = [];

  selectedNivel:    number | string = '';
  selectedGrado:    number | string = '';
  selectedPlanTipo: number | string = '';
  selectedPeriodo:  number | string = '';

  // alumnoForm: FormGroup;
  // parentForm: FormGroup;

  matriculaForm: FormGroup;

  selectedGrupoId: number | null = null;
  selectedGrupo: any = null;
  costos: any[] = [];

  saldoActual: number = 0;
  totalAPagar: number = 0;
  selectedCosts: any[] = [];

  tiposDocumento: any[] = [
    { id: 1, nombre: 'DNI' },
    { id: 2, nombre: 'Pasaporte' },
  ];
  displayedColumns: string[] = ['select_costo', 'nombre', 'tipo', 'monto_inicial', 'monto_final', 'fecha_pago_inicial', 'fecha_pago_final'];

  constructor(
    private fb: FormBuilder,
    private moduloMatriculaService: ModuloMatriculaService,
    private moduloAdministracionService: ModuloAdministracionService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,

    private sharedComponentsService: SharedComponentsService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.sharedComponentsService.setViewContainerRef(this.viewContainerRef);
    // this.loadRouterNavigationData();

    this.matriculaForm = this.fb.group({
      estudiante: this.buildPersona(),
      padre:      this.fb.group({}),
      madre:      this.fb.group({}),
      apoderado:  this.fb.group({}),
    });

  }

  // loadRouterNavigationData() {
  //   const navigation = this.router.getCurrentNavigation();
  //   const state = navigation?.extras.state;
  //   if(state){
  //     this.xIdEstudiante = state['xId_estudiante'];
  //   }
  // }

  loadRouteParams(){
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id && !isNaN(+id)) {
        this.xIdEstudiante = +id;
        this.getPersonas();
      }else if( id !== 'nuevo' ) {
        this.router.navigate(['/modulos/matricula/estudiantes']);
      }
    });
  }

  ngOnInit(): void {
    this.loadRouteParams();
  }

  buildPersona(data: any = {}): FormGroup {
    return this.fb.group({
      id:                   [data.id                  || ''],
      name:                 [data.name                || '', Validators.required],
      lastname_1:           [data.lastname_1          || '', Validators.required],
      lastname_2:           [data.lastname_2          || '', Validators.required],
      email:                [data.email               || '', Validators.email],
      documento_identidad:  [data.documento_identidad || '', Validators.required],
      tipo_documento:       [data.tipo_documento      || '', Validators.required],
      domicilio:            [data.domicilio           || '', Validators.required],
      celular:              [data.celular             || '', Validators.required],
    });
  }



  isChecked(variable: any) {
    return variable == 1 ? true : false;
  }

  getPersonas() {
    this.moduloMatriculaService.apiGetStudentAndParents(this.xIdEstudiante)
      .subscribe((data: any) => {
        this.matriculaForm.get('estudiante')?.patchValue(data.estudiante);
        if (data.padre) {
          this.matriculaForm.setControl('padre', this.buildPersona(data.padre));
        }
        if (data.madre) {
          this.matriculaForm.setControl('madre', this.buildPersona(data.madre));
        }
        if (data.apoderado) {
          this.matriculaForm.setControl('apoderado', this.buildPersona(data.apoderado));
        }
      });
  }

  agregarPariente(relacion: string): void {
    this.matriculaForm.setControl(relacion, this.buildPersona());
  }

  // onSubmit() {
  //   if (!this.isFormValid()) {
  //     console.error('Form is not valid');
  //     return;
  //   }

  //   const processedParents = this.processParentData(this.parentForm.value);
  //   const dataForm = {
  //     usuario: this.alumnoForm.value,
  //     parientes: {
  //       ...this.parentForm.value,
  //       ...processedParents
  //     }
  //   };

  //   this.moduloMatriculaService.apiSaveUsers(dataForm).subscribe(
  //     (response: any) => {
  //       console.log(response);
  //       if (response && response.estudiante && response.estudiante.id) {
  //         this.router.navigate([`/modulos/matricula/proceso/${response.estudiante.id}`]);
  //       }
  //     }
  //   );
  // }

  // isFormValid(): boolean {
  //   if (this.alumnoForm.invalid) {
  //     return false;
  //   }

  //   const parentTypes = ['padre', 'madre', 'apoderado'];
  //   for (let type of parentTypes) {
  //     if (this.parentForm.get(type)?.value && this.parentForm.get(`${type}Form`)?.invalid) {
  //       return false;
  //     }
  //   }

  //   return true;
  // }

  // processParentData(parentData: any) {
  //   const processedData: { [key: string]: any } = {};
  //   ['padre', 'madre', 'apoderado'].forEach(parentType => {
  //     if (parentData[parentType]) {
  //       const formGroup = parentData[`${parentType}Form`];
  //       if (formGroup.id_user) {
  //         processedData[`${parentType}Form`] = { id_user: formGroup.id_user };
  //       } else {
  //         const { id_user, ...rest } = formGroup;
  //         processedData[`${parentType}Form`] = rest;
  //       }
  //     }
  //   });
  //   return processedData;
  // }

  // onParentChange(parentType: string) {
  //   const control = this.parentForm.get(parentType);
  //   if (control?.value) {
  //     this.parentForm.get(`${parentType}Form`)?.enable();
  //   } else {
  //     this.parentForm.get(`${parentType}Form`)?.disable();
  //   }
  // }

  // selectGrupo(grupo: any): void {
  //   console.log('Grupo seleccionado:', grupo);
  //   this.selectedGrupoId = grupo.id;
  //   this.selectedGrupo = grupo;
  // }

  // goToStep3(): void {
  //   if (this.selectedGrupoId) {
  //     this.stepper.selectedIndex = 2;
  //   }
  // }

  onStepChange(event: StepperSelectionEvent): void {
    // if (event.selectedIndex === 1) {
    //   this.onSelectStep2();
    // }
    // if (event.selectedIndex === 2) {
    //   this.getPagoDeMatricula();
    // }
  }

  // getPagoDeMatricula() {
  //   let params = {
  //       id_grupo: this.selectedGrupoId,
  //       id_estudiante: this.xIdEstudiante,
  //   };
  //   if (this.selectedGrupoId) {
  //       this.moduloMatriculaService.apiGetPagoMatricula(params).subscribe((response: any) => {
  //           this.costos = response.costos.map((costo: any) => ({
  //               ...costo,
  //               monto_inicial: costo.monto,
  //               monto_final: costo.monto,
  //               // fecha_pago_inicial: costo.fecha_pago,
  //               // fecha_pago_final: costo.fecha_pago,
  //               fecha_pago_inicial: new Date(costo.fecha_pago),
  //               fecha_pago_final: new Date(costo.fecha_pago),
  //               selected: costo.tipo === 1 // Seleccionar automáticamente los costos de tipo 1
  //           }));
  //           this.saldoActual = response.saldo;
  //           this.totalAPagar = this.costos.filter(costo => costo.selected).reduce((total, costo) => total + costo.monto, 0);
  //           this.selectedCosts = this.costos.filter(costo => costo.selected);
  //       });
  //   }
  // }


  // //   onCostSelectionChange(element: any): void {
  // //     if (element.selected && element.tipo === 2) {
  // //         this.totalAPagar += element.monto;
  // //         this.selectedCosts.push(element);
  // //     } else if (!element.selected && element.tipo === 2) {
  // //         this.totalAPagar -= element.monto;
  // //         this.selectedCosts = this.selectedCosts.filter(cost => cost.id !== element.id);
  // //     }
  // // }

  // // V2
  // //   onCostSelectionChange(element: any): void {
  // //     if (element.selected) {
  // //         this.totalAPagar += element.monto_final;
  // //         this.selectedCosts.push(element);
  // //     } else {
  // //         this.totalAPagar -= element.monto_final;
  // //         this.selectedCosts = this.selectedCosts.filter(cost => cost.id !== element.id);
  // //     }
  // // }

  // onCostSelectionChange(element: any): void {
  //   if (element.selected && element.tipo === 2) {
  //       this.selectedCosts.push(element);
  //   } else if (!element.selected && element.tipo === 2) {
  //       this.selectedCosts = this.selectedCosts.filter(cost => cost.id !== element.id);
  //   }
  //   this.updateTotalAPagar();
  // }
  // updateTotalAPagar(): void {
  //   this.totalAPagar = this.selectedCosts.reduce((total, cost) => {
  //     return total + (cost.monto_final || cost.monto_inicial);
  //   }, 0);
  // }
  // onMontoFinalChange(): void {
  //   this.updateTotalAPagar();
  // }

  // // matricular(): void {
  // //   if (this.totalAPagar <= this.saldoActual) {
  // //       const selectedCostsForBackend = this.selectedCosts.map(cost => ({
  // //           id: cost.id,
  // //           nombre: cost.nombre,
  // //           monto_inicial: cost.monto_inicial,
  // //           monto_final: cost.monto_final,
  // //           fecha_pago_inicial: cost.fecha_pago_inicial,
  // //           fecha_pago_final: cost.fecha_pago_final ? formatDate(cost.fecha_pago_final, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
  // //           tipo: cost.tipo,
  // //         }));

  // //         const allCostsForBackend = this.costos.map(cost => ({
  // //           id: cost.id,
  // //           nombre: cost.nombre,
  // //           monto_inicial: cost.monto_inicial,
  // //           monto_final: cost.monto_final,
  // //           fecha_pago_inicial: cost.fecha_pago_inicial,
  // //           fecha_pago_final: cost.fecha_pago_final ? formatDate(cost.fecha_pago_final, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
  // //           tipo: cost.tipo,
  // //       }));

  // //       this.moduloMatriculaService.apiRealizarMatricula({
  // //           id_estudiante: this.id_estudiante,
  // //           id_grupo: this.selectedGrupoId,
  // //           selected_costos: selectedCostsForBackend,
  // //           all_costos: allCostsForBackend
  // //       }).subscribe(response => {
  // //           console.log('Matrícula realizada con éxito', response);
  // //       });
  // //   }
  // // }

  // matricular(tipo_comprobante: number): void {
  //   if (this.totalAPagar <= this.saldoActual) {
  //     const dialogRef = this.dialog.open(MatriProcesoResumenMatriculaComponent, {
  //       width: '600px',
  //       data: {
  //         saldoActual: this.saldoActual,
  //         totalAPagar: this.totalAPagar,
  //         costos: this.costos,
  //         selectedCosts: this.selectedCosts,
  //         id_estudiante: this.xIdEstudiante,
  //         selectedGrupoId: this.selectedGrupoId,
  //         tipo_comprobante: tipo_comprobante
  //       }
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result) {
  //         // Si se confirmó la matrícula en el diálogo
  //         console.log('Matrícula confirmada');
  //       }
  //     });
  //   }
  // }


  // // generarComprobante() {
  // //   const title = 'Boleta de Venta';
  // //   const content = 'Contenido de la boleta...';

  // //   this.sharedComponentsService.printComponent(title, content);
  // // }
  // generarComprobanteBoletaA4() {
  //   if (!this.printSection_BoletaA4) {
  //     console.error('printSection is not available when generating comprobante!');
  //     return;
  //   }
  //   const printContents = this.printSection_BoletaA4.nativeElement.innerHTML;
  //   this.sharedComponentsService.printBoletaDeVentaA4(printContents);
  // }

  // generarComprobanteBoletaTicketera() {
  //   if (!this.printSection_BoletaTicketera) {
  //     console.error('printSection is not available when generating comprobante!');
  //     return;
  //   }
  //   const printContents = this.printSection_BoletaTicketera.nativeElement.innerHTML;
  //   const cssUrl = 'src/app/shared-components/sv-pdf-invoice-a4/ticket.css';
  //   this.sharedComponentsService.printBoletaDeVentaTicketera(printContents);
  // }



  // onSelectStep2(): void {
  //   this.getNiveles();
  //   this.getPeriodos();
  //   this.getTipoPlanes();
  //   this.getPlanEstudios();
  // }

  // applyFilters() {
  //   this.getPlanEstudios();
  // }

  // getPlanEstudios() {
  //   let params = {
  //     id_nivel: this.selectedNivel,
  //     id_plan_tipo: this.selectedPlanTipo,
  //     id_periodo: this.selectedPeriodo,
  //     id_grado: this.selectedGrado,
  //     with_grupos: true,
  //   };

  //   this.moduloAdministracionService.apiGetPlanEstudios(params)
  //     .subscribe((data: PlanEstudioInterface[]) => {
  //       this.listPlanEstudios = data;
  //     });
  // }

  // onNivelChange() {
  //   this.selectedGrado = '';
  //   this.getGrados();
  //   this.applyFilters();
  // }

  // getGrados() {
  //   if (this.selectedNivel) {
  //     let nivel = this.listNiveles.find(n => n.id === this.selectedNivel);
  //     if (nivel) {
  //       this.listGrados = nivel.grados;
  //     }
  //   } else {
  //     this.listGrados = [];
  //   }
  // }

  // getPeriodos() {
  //   let params = { 'activo': true };
  //   this.moduloAdministracionService.apiGetPeriodos(params)
  //     .subscribe((data: any[]) => {
  //       this.listPeriodos = data;
  //     });
  // }

  // getNiveles() {
  //   let params = { 'activo': true, 'withGrados': true };
  //   this.moduloAdministracionService.getNiveles(params)
  //     .subscribe((data: any[]) => {
  //       this.listNiveles = data;
  //     });
  // }

  // getTipoPlanes() {
  //   this.moduloAdministracionService.getTipoPlanes()
  //     .subscribe((data: any[]) => {
  //       this.listPlanTipos = data;
  //     });
  // }

  // getNombreNivel(id: number): string {
  //   const nivel = this.listNiveles.find(n => n.id === id);
  //   return nivel ? nivel.nombre : '';
  // }

  // getNombreTipoPlan(id: number): string {
  //   const tipoPlan = this.listPlanTipos.find(tp => tp.id === id);
  //   return tipoPlan ? tipoPlan.nombre : '';
  // }

  // getNombrePeriodo(id: number): string {
  //   const periodo = this.listPeriodos.find(p => p.id === id);
  //   return periodo ? periodo.codigo : '';
  // }



}
