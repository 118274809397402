<body>
  <table class="table table-striped-columns container" id="tablaestudiante">
    <thead>
      <tr>
        <th colspan="4"><b>FICHAS DEL DATO DEL ESTUDIANTE</b></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row"><b>APELLIDOS Y NOMBRES</b></th>
        <td colspan="3">{{nombre}}</td>
      </tr>
      <tr>
        <th scope="row">CANTIDAD DE HERMANOS</th>
        <td>{{cantidad}}</td>
        <td>NUMERO DE ORDEN QUE OCUPA DE HERMANOS</td>
        <td>{{numero}}</td>
      </tr>
      <tr>
        <th scope="row">I.E. DE PROCEDENCIA</th>
        <td colspan="3">{{procedencia}}</td>
      </tr>
      <tr>
        <th scope="row">EL ESTUDIANTE HABLA SOLAMENTE CASTELLANO?</th>
        <td>{{idioma}}</td>
        <td>PRESENTA ALGUNA ENFERMEDAD FISICA O RELEVANTE</td>
        <td>{{enfermedad}}</td>
      </tr>
      <tr>
      </tr>
      <tr>
        <th scope="row">TIENE UNO DE ESTOS DISPOSITIVOS EN CASA?</th>
        <td colspan="3" >
          <span *ngFor="let item of itemsdispositivo"> • {{ item }} </span>
        </td>
      </tr>
      <tr>
        <th scope="row">TIENE INTERNET EN CASA?</th>
        <td colspan="3">{{internet}}</td>
      </tr>
    </tbody>
  </table>
  <table class="table table-striped-columns container" id="tablapadre">
    <thead>
      <tr>
        <th colspan="4">DATOS DEL PADRE</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">GRADO DE INSTRUCCION:</th>
        <td>{{instruccionpadre}}</td>
        <th scope="row">OCUPACION:</th>
        <td>{{ocupacionpadre}}</td>
      </tr>
      <tr>
        <th scope="row">DIRECCION DOMICILIARIO:</th>
        <td>{{domiciliopadre}}</td>
        <th scope="row">RELIGION:</th>
        <td>{{religionpadre}}</td>
      </tr>
      <tr>
        <th scope="row">DISTRITO:</th>
        <td>{{distritopadre}}</td>
        <th scope="row">BARRIO O URB.:</th>
        <td>{{barriopadre}}</td>
      </tr>
      <tr>
        <th scope="row">VIVE CON EL EDUCANDO ?</th>
        <td colspan="3">{{viveeducpadre}}</td>
      </tr>
      <tr>
        <th scope="row">IDIOMA QUE HABALA</th>
        <td colspan="3" >
          <span *ngFor="let item1 of itemsidiomapadre"> • {{ item1 }} </span>
        </td>
      </tr>
      <tr>
        <th colspan="2">TIENE ALGUNPROCESO JUDICIAL O LAGUN PROBLEMA ?</th>
        <td colspan="2">{{probpadre}}</td>
      </tr>
    </tbody>
  </table>
  <table class="table table-striped-columns container" id="tablamadre">
    <thead>
      <tr>
        <th colspan="4">DATOS DE LA MADRE</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">GRADO DE INSTRUCCION:</th>
        <td>{{instruccionmadre}}</td>
        <th scope="row">Ocupacion:</th>
        <td>{{ocupacionmadre}}</td>
      </tr>
      <tr>
        <th scope="row">DIRECCION DOMICILIARIO:</th>
        <td>{{domiciliomadre}}</td>
        <th scope="row">RELIGION:</th>
        <td>{{religionmadre}}</td>
      </tr>
      <tr>
        <th scope="row">DISTRITO:</th>
        <td>{{distritomadre}}</td>
        <th scope="row">BARRIO O URB.:</th>
        <td>{{barriomadre}}</td>
      </tr>
      <tr>
        <th scope="row">VIVE CON EL EDUCANDO ?</th>
        <td colspan="3">{{viveeducmadre}}</td>
      </tr>
      <tr>
        <th scope="row">IDIOMA QUE HABLA</th>
        <td colspan="3" >
          <span *ngFor="let item2 of itemsidiomamadre"> • {{ item2 }} </span>
        </td>
      </tr>
      <tr>
        <th colspan="2">TIENE ALGUNPROCESO JUDICIAL O LAGUN PROBLEMA ?</th>
        <td colspan="2">{{probmadre}}</td>
      </tr>
    </tbody>
  </table>

  <div class="container" id="tablass">
    <div class="cardd">
      <h2>FICHA DE DATOS DEL ESTUDIANTE</h2>
      <div class="input-group mb-3">
        <span class="input-group-text dist inputDisName" id="basic-addon1">NOMBRES Y APELLIDOS:</span>
        <input type="text" class="form-control inputDis" [(ngModel)]="nombre" placeholder="NOMBRES Y APELLIDOS" aria-label="Username" aria-describedby="basic-addon1" >
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text inputDisName dist" for="inputGroupSelect01">CANTIDAD DE HERMANOS:</label>
        <select class="form-select selectDis dist4" id="inputGroupSelect01" [(ngModel)]="cantidad">
          <option selected>Seleccionar...</option>
          <option value="1" >1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
        <label class="input-group-text inputDisName" for="inputGroupSelect02">Nº DE ORDEN QUE OCUPA ENTRE LOS HERMANOS:</label>
        <select class="form-select selectDis" id="inputGroupSelect02" [(ngModel)]="numero" >
          <option selected>Seleccionar...</option>
          <option value="Primero">Primero</option>
          <option value="Segundo">Segundo</option>
          <option value="Tercero">Tercero</option>
          <option value="Cuarto">Cuarto</option>
          <option value="Quinto">Quinto</option>
          <option value="Sexto">Sexto</option>
          <option value="Sexto">Septimo</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text dist inputDisName" id="basic-addon1">I.E DE PROCEDENCIA</span>
        <input type="text" class="form-control inputDis" [(ngModel)]="procedencia" placeholder="I.E DE PROCEDENCIA" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text inputDisName" for="inputGroupSelect03">EL ESTUDIANTE HABLA SOLAMENTE CASTELLANO?</label>
        <select class="form-select selectDis" id="inputGroupSelect03" [(ngModel)]="idioma">
          <option selected>Seleccionar...</option>
          <option value="SI" >SI</option>
          <option value="NO">NO</option>
        </select>
        <label class="input-group-text inputDisName" for="inputGroupSelect04" >PRESENTA ALGUNA ENFERMEDAD FISIA O RELEVANTE</label>
        <select class="form-select selectDis" id="inputGroupSelect04" [(ngModel)]="enfermedad">
          <option selected>Seleccionar...</option>
          <option value="SI">SI</option>
          <option value="NO">NO</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text inputDisName" for="inputGroupSelect05">TIENE UNO DE ESTOS DISPOSITIVOS EN CASA?</label>
        <label class="input-group-text label-span dist3 inputDis" for="inputGroupSelect05">
          <span *ngFor="let item of itemsdispositivo">&nbsp; &nbsp;{{ item }}&nbsp;<button class="remove-item" (click)="removeItemDisp(item)">X</button></span>
        </label>
        <select class="form-select selectDis" id="inputGroupSelect05" [(ngModel)]="agregardispositivo" >
            <option selected>Agregar Dispositivos</option>
            <option value="Laptop">Laptop</option>
            <option value="Celular">Celular</option>
            <option value="Tablet">Tablet</option>
            <option value="Computadora">Computadora</option>
        </select>
        <button type="button" class="btn" (click)="addItemDisp()">Agregar</button>
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist1 inputDisName" for="inputGroupSelect06">TIENE INTERNET EN CASA?</label>
        <select class="form-select selectDis" id="inputGroupSelect06" [(ngModel)]="internet">
          <option selected>Seleccionar...</option>
          <option value="SI" >SI</option>
          <option value="NO">NO</option>
        </select>
      </div>
    </div>

    <div class="cardd">
      <h2>DATOS DEL PADRE</h2>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect07">GRADO DE INSTRUCCION:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="instruccionpadre" placeholder="Grado de instruccion del padre" aria-label="Username" aria-describedby="basic-addon1">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect08">OCUPACION:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="ocupacionpadre" placeholder="Ocupacion" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect09">DIRECCION DEL DOMICILIARIO:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="domiciliopadre" placeholder="Domiciliario" aria-label="Username" aria-describedby="basic-addon1">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect10">RELIGION:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="religionpadre" placeholder="Religion" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect11">DISTRITO:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="distritopadre" placeholder="Distrito" aria-label="Username" aria-describedby="basic-addon1">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect12">BARRIO URB.:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="barriopadre" placeholder="Barrio o Urbanizacion" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect13">VIVE CON EL EDUCANDO?</label>
        <select class="form-select selectDis" id="inputGroupSelect01" [(ngModel)]="viveeducpadre">
          <option selected>Seleccionar...</option>
          <option value="SI" >SI</option>
          <option value="NO">NO</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect14">IDIOMA QUE HABLA?</label>
        <label class="input-group-text label-span dist1 inputDis" for="inputGroupSelect14">
          <span *ngFor="let item of itemsidiomapadre">&nbsp; &nbsp;{{ item }}&nbsp; <button class="remove-item" (click)="removeItemPa(item)">X</button></span>
        </label>
        <select class="form-select selectDis" id="inputGroupSelect14" [(ngModel)]="agregaridiomapadre">
          <option selected>Agregar Idioma del Padre</option>
          <option value="Castellano" >Castellano</option>
          <option value="Quechua">Quechua</option>
          <option value="Aymara">Aymara</option>
          <option value="Otros">Otros</option>
        </select>
        <button type="button" class="btn btn-outline-primary" (click)="addItemPa()">Agregar</button>
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist1 inputDisName" for="inputGroupSelect15">TIENE ALGUN PROCESO JUDICIAL O ALGUN PROBLEMA?</label>
        <select class="form-select selectDis" id="inputGroupSelect15" [(ngModel)]="probpadre">
          <option selected>Seleccionar...</option>
          <option value="SI" >SI</option>
          <option value="NO">NO</option>
        </select>
      </div>
    </div>
    <div class="cardd">
      <h2>DATOS DEL MADRE</h2>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect16">GRADO DE INSTRUCCION:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="instruccionmadre" placeholder="Grado de instruccion del padre" aria-label="Username" aria-describedby="basic-addon1">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect17">OCUPACION:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="ocupacionmadre" placeholder="Ocupacion" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect18">DIRECCION DEL DOMICILIARIO:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="domiciliomadre" placeholder="Domiciliario" aria-label="Username" aria-describedby="basic-addon1">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect19">RELIGION:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="religionmadre" placeholder="Religion" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect20">DISTRITO:</label>
        <input type="text" class="form-control inputDis" placeholder="Seleccionar..." [(ngModel)]="distritomadre" placeholder="Distrito" aria-label="Username" aria-describedby="basic-addon1">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect21">BARRIO URB.:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="barriomadre" placeholder="Barrio o Urbanizacion" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect22">VIVE CON EL EDUCANDO?</label>
        <select class="form-select selectDis" id="inputGroupSelect22" [(ngModel)]="viveeducmadre">
          <option selected>Seleccionar...</option>
          <option value="SI" >SI</option>
          <option value="NO">NO</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist  inputDisName" for="inputGroupSelect23">IDIOMA QUE HABLA?</label>
        <label class="input-group-text label-span dist1 inputDis" for="inputGroupSelect23">
          <span *ngFor="let item of itemsidiomamadre">&nbsp; &nbsp;{{ item }}&nbsp;<button class="remove-item" (click)="removeItemMa(item)">X</button></span>
        </label>
        <select class="form-select selectDis" id="inputGroupSelect23" [(ngModel)]="agregaridiomamadre">
          <option selected>Agregar Idioma de la Madre</option>
          <option value="Castellano" >Castellano</option>
          <option value="Quechua">Quechua</option>
          <option value="Aymara">Aymara</option>
          <option value="Otros">Otros</option>
        </select>
        <button type="button" class="btn btn-outline-primary" (click)="addItemMa()">Agregar</button>
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist1 inputDisName" for="inputGroupSelect24">TIENE ALGUN PROCESO JUDICIAL O ALGUN PROBLEMA?</label>
        <select class="form-select selectDis" id="inputGroupSelect24" [(ngModel)]="probmadre">
          <option selected>Seleccionar...</option>
          <option value="SI" >SI</option>
          <option value="NO">NO</option>
        </select>
      </div>
      <div class="col-12 d-flex justify-content-center align-items-center">
        <button type="submit" class="btn btn-primary" (click)="generarFICHA()">Generar Ficha</button>
      </div>

    </div>
  </div>

</body>
