import { Component } from '@angular/core';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfProgramaCurricularAreaInterface } from '../../../interfaces/conf-programa-curricular-area.interface';
import { ConfProgramaCurricularAreasEditComponent } from '../conf-programa-curricular-areas-edit/conf-programa-curricular-areas-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-conf-programa-curricular-areas-list',
  templateUrl: './conf-programa-curricular-areas-list.component.html',
  styleUrl: './conf-programa-curricular-areas-list.component.css'
})
export class ConfProgramaCurricularAreasListComponent {

  xIdProgramaCurricular: any;
  serverNombrePrograma: string = "";
  listAreasCurriculares: ConfProgramaCurricularAreaInterface[] = [];

  displayedColumns: string[] = ['nombre', 'detalle', 'acciones'];
  dataSource: any;

  constructor(
    private moduloConfiguracionService: ModuloConfiguracionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,

    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.xIdProgramaCurricular = Number(params.get('id_programa'));
      console.log('xIdProgramaCurricular: ', this.xIdProgramaCurricular);
      this.getAreasCurriculares();
    });
  }

  getAreasCurriculares(){
    const params =  {id_programa_curricular: this.xIdProgramaCurricular};
    this.moduloConfiguracionService.apiGetAreasCurriculares(params)
      .subscribe((data: any) => {
        this.listAreasCurriculares = data.areas;
        this.serverNombrePrograma = data.nombre;
      });
  }

  goToCompetencias(id_area: any): void {
    this.router.navigate([`/modulos/configuracion/programas-curriculares/${this.xIdProgramaCurricular}/${id_area}`]);
  }







  openDialogCrearArea(): void {
    const dialogRef = this.dialog.open(ConfProgramaCurricularAreasEditComponent, {
      data: {
        isEdit: false,
        id_programa_curricular: this.xIdProgramaCurricular
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAreasCurriculares();
      }
    });
  }

  openDialogEditarArea(area: ConfProgramaCurricularAreaInterface): void {
    const dialogRef = this.dialog.open(ConfProgramaCurricularAreasEditComponent, {
      data: {
        isEdit: true,
        area: area
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAreasCurriculares();
      }
    });
  }

  openDialogEliminarArea(area: ConfProgramaCurricularAreaInterface): void {

    this.moduloConfiguracionService.apiDeleteAreaCurricular(area.id).subscribe((response: any) => {
      if (response.success) {
        alert('Area eliminada correctamente.');
        this.getAreasCurriculares();
      } else {
        alert('Hubo un problema al eliminar el area.');
      }
    });

  }





}

