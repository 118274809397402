<div class="page-content">
  <div class="page-content-header p-0">

    <div class="page-content-header-title">
      <div class="page-content-header-title-text">Filtros de búsqueda</div>
      <button class="custom-action-button primary" (click)="getPlanesEstudio()">Aplicar búsqueda</button>
    </div>

    <div class="page-content-header-filters">

      <div class="page-content-header-filters-select">
        <mat-label>Sede</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedSede" (selectionChange)="onSedeChange()" >
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let sede of listSedes" [value]="sede.id">{{sede.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="page-content-header-filters-select">
        <mat-label>Nivel</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedNivel" (selectionChange)="onNivelChange()" >
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let nivel of listNiveles" [value]="nivel.id">{{nivel.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="page-content-header-filters-select">
        <mat-label>Grado</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic"  >
          <mat-select [(value)]="selectedGrado" (selectionChange)="applyFilters()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let grado of listGrados" [value]="grado.id">{{grado.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="page-content-header-filters-select">
        <mat-label>Periodo</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedPeriodo" (selectionChange)="onPeriodoChange()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let periodo of listPeriodos" [value]="periodo.id">{{periodo.codigo}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="page-content-header-filters-select">
        <mat-label>Tipo de Plan</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedPlanTipo" (selectionChange)="applyFilters()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let tipoPlan of listPlanTipos" [value]="tipoPlan.id">{{tipoPlan.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


    </div>

  </div>

  <div class="page-content-body h-100 p-0 mt-3">

    <ng-container *ngIf="!isLoading; else loadingBus">

      <ng-container *ngIf="listPlanesEstudio.length; else noResultsFound">
        <div class="card-list-container">
          <sv-card-matricula-plan-estudio-grupos
            *ngFor="let plan of listPlanesEstudio"
            [titulo]="plan.nombre"
            [grupos]="plan.grupos"
            [featuresChipsArray]="buildFeaturesArray(plan)"
            [xIdGrupoSeleccionado]="xIdGrupoSeleccionado"
            (grupoSeleccionado)="onGrupoSeleccionado($event, plan)">
          </sv-card-matricula-plan-estudio-grupos>
        </div>
      </ng-container>

      <ng-template #noResultsFound>
        <sv-no-results-found></sv-no-results-found>
      </ng-template>

    </ng-container>

    <ng-template #loadingBus>
      <sv-loading-bus></sv-loading-bus>
    </ng-template>





  </div>

</div>
