import { Component } from '@angular/core';
import { MatriculaContratoReportInterface } from '../../interfaces/matricula-contrato.interface';
import { ModuloContabilidadService } from '../../modulo-contabilidad.service';
import { ModuloMatriculaService } from 'src/app/pages/modulo-matricula/modulo-matricula.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ContEstudiantePagosComponent } from '../cont-estudiante-pagos/cont-estudiante-pagos.component';

@Component({
  selector: 'app-cont-estudiante-contratos',
  templateUrl: './cont-estudiante-contratos.component.html',
  styleUrl: './cont-estudiante-contratos.component.css'
})
export class ContEstudianteContratosComponent {


  xIdEstudiante: any;
  estudiante: any;

  isLoading = false;

  listContratos: MatriculaContratoReportInterface[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private moduloMatriculaService: ModuloMatriculaService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.xIdEstudiante = Number(params.get('id'));
    });

    this.getContratos();
  }

  getContratos() {
    this.isLoading = true;
    let params = { 'id_estudiante': this.xIdEstudiante };
    this.moduloMatriculaService.apiGetContratosEstudiante(params)
      .subscribe((data: any) => {
        this.estudiante = data.estudiante;
        this.listContratos = data.contratos;
        this.isLoading = false;
      });
  }

  openDialogDepositar() {

  }


  openDialogPagos(contrato: MatriculaContratoReportInterface) {
    const dialogRef = this.dialog.open(ContEstudiantePagosComponent, {
      data: {
        contrato: contrato,
        id_estudiante: this.xIdEstudiante,
      },
      minHeight: '50vh',
      minWidth: '75vw',
      maxHeight: '75vh',
      maxWidth: '75vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getContratos();
      }
    });
  }



}
