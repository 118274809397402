
<div class="bottom-buttons">
  <button
    *ngIf="!getPersonaForm('padre') || getPersonaForm('padre')?.get('hidden')?.value"
    class="bottom-button agregar-familiar"
    mat-raised-button
    (click)="addPersona(null, 'padre')">
    Agregar Padre
  </button>
  <button
    *ngIf="getPersonaForm('padre') && !getPersonaForm('padre')?.get('hidden')?.value"
    class="bottom-button retirar-familiar"
    mat-raised-button
    (click)="removePersona('padre')">
    Retirar Padre
  </button>

  <button
    *ngIf="!getPersonaForm('madre') || getPersonaForm('madre')?.get('hidden')?.value"
    class="bottom-button agregar-familiar"
    mat-raised-button
    (click)="addPersona(null, 'madre')">
    Agregar Madre
  </button>
  <button
    *ngIf="getPersonaForm('madre') && !getPersonaForm('madre')?.get('hidden')?.value"
    class="bottom-button retirar-familiar"
    mat-raised-button
    (click)="removePersona('madre')">
    Retirar Madre
  </button>

  <button
    *ngIf="!getPersonaForm('apoderado') || getPersonaForm('apoderado')?.get('hidden')?.value"
    class="bottom-button agregar-familiar"
    mat-raised-button
    (click)="addPersona(null, 'apoderado')">
    Agregar Apoderado
  </button>
  <button
    *ngIf="getPersonaForm('apoderado') && !getPersonaForm('apoderado')?.get('hidden')?.value"
    class="bottom-button retirar-familiar"
    mat-raised-button
    (click)="removePersona('apoderado')">
    Retirar Apoderado
  </button>
</div>

<div class="row d-flex justify-content-center mt-3">
  <div class="col-12 my-3">
    <sv-card-matricula-persona
      [personaForm]="getPersonaForm('estudiante')"
      [relacion]="'estudiante'"
    ></sv-card-matricula-persona>
  </div>

  <div *ngIf="getPersonaForm('padre') && !getPersonaForm('padre')?.get('hidden')?.value" class="col my-3">
    <sv-card-matricula-persona
      [personaForm]="getPersonaForm('padre')"
      [relacion]="'padre'"
      (onRemovePersona)="removePersona('padre')"
      (onSeleccionarResponsable)="seleccionarResponsable('padre')"
    ></sv-card-matricula-persona>
  </div>

  <div *ngIf="getPersonaForm('madre') && !getPersonaForm('madre')?.get('hidden')?.value" class="col my-3">
    <sv-card-matricula-persona
      [personaForm]="getPersonaForm('madre')"
      [relacion]="'madre'"
      (onRemovePersona)="removePersona('madre')"
      (onSeleccionarResponsable)="seleccionarResponsable('madre')"
    ></sv-card-matricula-persona>
  </div>

  <div *ngIf="getPersonaForm('apoderado') && !getPersonaForm('apoderado')?.get('hidden')?.value" class="col my-3">
    <sv-card-matricula-persona
      [personaForm]="getPersonaForm('apoderado')"
      [relacion]="'apoderado'"
      (onRemovePersona)="removePersona('apoderado')"
      (onSeleccionarResponsable)="seleccionarResponsable('apoderado')"
    ></sv-card-matricula-persona>
  </div>
</div>

