<app-sv-page-content-header
  titulo="Programas Curriculares">
</app-sv-page-content-header>


<div class="page-content">

  <div class="container mt-5">
    <h2 class="text-center mb-4">Lista de Programas Curriculares activos</h2>
    <div class="row justify-content-center">
      <div *ngFor="let programa of listProgramasCurriculares" class="col-md-3 mb-4">
        <div class="card h-100 shadow-sm programa-card" (click)="onProgramaClick(programa)">
          <img [src]="programa.url_foto" class="card-img-top programa-img" [alt]="programa.nombre">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title text-center">{{ programa.nombre }}</h5>
            <p class="card-text text-center text-muted mt-auto">Código: {{ programa.codigo }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
