import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModuloMatriculaService } from 'src/app/pages/modulo-matricula/modulo-matricula.service';

@Component({
  selector: 'app-cont-estudiante-pagos',
  templateUrl: './cont-estudiante-pagos.component.html',
  styleUrl: './cont-estudiante-pagos.component.css'
})
export class ContEstudiantePagosComponent {

  xIdEstudiante: any;
  xContrato: any;

  listaPagos: any[] = [];

  saldoActual: number = 0;
  totalAPagar: number = 0;
  selectedCosts: any[] = [];

  displayedColumns: string[] = ['select_costo', 'nombre', 'tipo', 'monto_final', 'fecha_pago_final', 'pagado', 'acciones'];


  constructor(
    public dialogRef: MatDialogRef<ContEstudiantePagosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private moduloMatriculaService: ModuloMatriculaService,

  ) {
    this.xIdEstudiante = data.id_estudiante;
    this.xContrato = data.contrato;
  }

  ngOnInit(){
    this.getPagos();
  }

  getPagos() {
    let params = {
        id_contrato: this.xContrato.id,
        id_estudiante: this.xIdEstudiante,
    };
    this.moduloMatriculaService.apiGetPagosEstudiante(params).subscribe((response: any) => {
        this.listaPagos = response.pagos.map((pago: any) => ({
            ...pago,
            // monto_inicial: costo.monto,
            // monto_final: costo.monto,
            // // fecha_pago_inicial: costo.fecha_pago,
            // // fecha_pago_final: costo.fecha_pago,
            // fecha_pago_inicial: new Date(costo.fecha_pago),
            // fecha_pago_final: new Date(costo.fecha_pago),
            // selected: costo.tipo === 1 // Seleccionar automáticamente los costos de tipo 1
        }));
        this.listaPagos = response.pagos;
        this.saldoActual = response.saldo;
        // this.totalAPagar = this.costos.filter(costo => costo.selected).reduce((total, costo) => total + costo.monto, 0);
        // this.selectedCosts = this.costos.filter(costo => costo.selected);


    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.totalAPagar > this.saldoActual) {
      console.error('No hay suficiente saldo para realizar el pago.');
      return;
    }

    const selectedCostsForBackend = this.selectedCosts.map(cost => ({
      id: cost.id,
      nombre: cost.nombre,
      monto_final: cost.monto_final,
      tabla_costo: 'matricula_contrato_costo_manuals', // Esto asegura que los costos se asocien con la tabla correcta
    }));

    const requestData = {
      id_estudiante: this.xIdEstudiante,
      selected_costos: selectedCostsForBackend,
      tipo_comprobante: 6 // Para boleta, 9 ticket
    };

    this.moduloMatriculaService.apiRealizarPagosEstudiante(requestData).subscribe(
      response => {
        console.log('Pago realizado con éxito', response);
        this.dialogRef.close(true); // Cerramos el diálogo y enviamos un resultado de éxito
      },
      error => {
        console.error('Error al realizar el pago', error);
      }
    );
  }


  isChecked(variable: any) {
    return variable == 1 ? true : false;
  }

  onCostSelectionChange(element: any): void {
    if (element.selected && element.tipo === 2) {
        this.selectedCosts.push(element);
    } else if (!element.selected && element.tipo === 2) {
        this.selectedCosts = this.selectedCosts.filter(cost => cost.id !== element.id);
    }
    this.updateTotalAPagar();
  }
  updateTotalAPagar(): void {
    this.totalAPagar = this.selectedCosts.reduce((total, cost) => {
      return total + (cost.monto_final || cost.monto_inicial);
    }, 0);
  }

  descargarComprobante(idComprobante: number): void {
    // this.moduloMatriculaService.apiDescargarComprobante(idComprobante).subscribe((response: any) => {
    //     const blob = new Blob([response], { type: 'application/pdf' });
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = `comprobante_${idComprobante}.pdf`;
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    // });
  }






}
