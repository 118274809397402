<app-sv-page-content-header
  titulo="Estudiantes Matriculados">
</app-sv-page-content-header>

<div class="page-content">

  <div class="page-content-header-search">
    <div class="w-100 d-flex" style="justify-content: space-between; height: 40px;">
      <div style="font-size: 24px; font-weight: bold; display: flex; display: flex; vertical-align: middle; align-items: center;">Lista de estudiantes</div>
    </div>

    <div class="search-container">
      <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Buscar estudiante</mat-label>
        <input matInput placeholder="Buscar estudiante" (keydown.enter)="applyFilter($event)" class="search-input" />
      </mat-form-field>
      <!-- <button mat-icon-button aria-label="Buscar" (click)="applyFilter($event)">
        <mat-icon>search</mat-icon>
      </button> -->

      <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Nivel</mat-label>
        <mat-select [(value)]="selectedNivel" (selectionChange)="onNivelChange()" >
          <mat-option [value]="''">Todos</mat-option>
          <mat-option *ngFor="let nivel of listNiveles" [value]="nivel.id">{{nivel.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Grado</mat-label>
        <mat-select [(value)]="selectedGrado" (selectionChange)="applyFilters()">
          <mat-option [value]="''">Todos</mat-option>
          <mat-option *ngFor="let grado of listGrados" [value]="grado.id">{{grado.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Tipo de Plan</mat-label>
        <mat-select [(value)]="selectedPlanTipo" (selectionChange)="applyFilters()">
          <mat-option [value]="''">Todos</mat-option>
          <mat-option *ngFor="let tipoPlan of listPlanTipos" [value]="tipoPlan.id">{{tipoPlan.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Periodo</mat-label>
        <mat-select [(value)]="selectedPeriodo" (selectionChange)="applyFilters()">
          <mat-option [value]="''">Todos</mat-option>
          <mat-option *ngFor="let periodo of listPeriodos" [value]="periodo.id">{{periodo.codigo}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="card-list-container">
    <mat-card *ngFor="let estudiante of listaEstudiantes" class="custom-mat-card">
      <mat-card-header class="px-3 py-2">
        <div class="header-content">
          <div class="header-text">
            <mat-card-title>{{estudiante.documento_identidad}} - {{estudiante.nombres}}</mat-card-title>
          </div>
          <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openAnularMatricula(estudiante.id)">
              <mat-icon>assignment</mat-icon>
              <span>Matricular</span>
            </button>
          </mat-menu> -->
        </div>
      </mat-card-header>
      <mat-card-content>
        <!-- <p>Detalles del estudiante {{estudiante.nombres}}</p> -->
        <mat-chip-set *ngFor="let grupo of estudiante.matriculas">
          <mat-chip>{{ getNombrePeriodo(grupo.id_periodo) }}</mat-chip>
          <mat-chip>{{ getNombreNivel(grupo.id_nivel) }}</mat-chip>
          <mat-chip>{{ getNombreTipoPlan(grupo.id_plan_tipo) }}</mat-chip>
          <mat-chip (click)="openAnularMatricula(estudiante.id, grupo.id_grupo)">Anular Matrícula</mat-chip>
        </mat-chip-set>
      </mat-card-content>
      <!-- <mat-card-actions>
        <button mat-button>Un botón</button>
      </mat-card-actions> -->
    </mat-card>
  </div>

  <mat-paginator [length]="totalEstudiantes" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="pageEvent($event)">
  </mat-paginator>

</div>
