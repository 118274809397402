<body>
  <div class="container">
    <div class="cardd">
      <h2>CONTRATO DE PRESTACION DE SERVICIO</h2>
      <div class="input-group mb-3">
        <span class="input-group-text dist inputDisName" id="basic-addon1">NOMBRES Y APELLIDOS:</span>
        <input type="text" class="form-control inputDis" [(ngModel)]="nombre" placeholder="NOMBRES Y APELLIDOS" aria-label="Username" aria-describedby="basic-addon1" >
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect01">DNI:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="dni" placeholder="DNI" aria-label="Username" aria-describedby="basic-addon1">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect01">CELULAR:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="celular" placeholder="Celular" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text dist inputDisName" id="basic-addon1">DOMICILIO:</span>
        <input type="text" class="form-control inputDis" [(ngModel)]="domicilio" placeholder="Domicilio" aria-label="Username" aria-describedby="basic-addon1" >
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text dist inputDisName" id="basic-addon1">DISTRITO:</span>
        <input type="text" class="form-control inputDis" [(ngModel)]="distrito" placeholder="Distrito" aria-label="Username" aria-describedby="basic-addon1" >
      </div>
      <div class="input-group mb-3">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect01">NOMBRE DEL REPRESENTANTE:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="representante" placeholder="Nombre del representante" aria-label="Username" aria-describedby="basic-addon1">
        <label class="input-group-text dist inputDisName" for="inputGroupSelect01">DNI:</label>
        <input type="text" class="form-control inputDis" [(ngModel)]="dnirepresentante" placeholder="DNI del representante" aria-label="Username" aria-describedby="basic-addon1">
      </div>
      <div class="col-12 d-flex justify-content-center align-items-center">
        <button type="submit" class="btn btn-outline-info spaced" (click)="rellenarAutoPaMa()">Relleno Automatico Padre/Madre</button>
        <button type="submit" class="btn btn-outline-info spaced" (click)="limpiarCeldasPaMa()">Limpiar Celdas Padre/Madre</button>
        <button type="submit" class="btn btn-outline-info spaced" (click)="rellenarAutoRep()">Relleno Automatico Representante</button>
        <button type="submit" class="btn btn-outline-info spaced" (click)="limpiarCeldasRep()">Limpiar Celda Representante</button>
      </div>
    </div>

    <div class="cardd">
      <h2>MATRICULAR ALUMNO(A)</h2>
      <div *ngFor="let alumno of alumnos; let i = index">
        <div class="cardd">
          <h2>MATRICULAR {{i+1}}° ALUMNO(A)</h2>
          <div class="input-group mb-3">
            <label class="input-group-text dist inputDisName" for="inputGroupSelect01">NOMBRE DEL ALUMNO:</label>
            <input type="text" class="form-control inputDis" [(ngModel)]="alumno.NombreAlumno" placeholder="Nombre del Alumno" aria-label="Username" aria-describedby="basic-addon1">
            <label class="input-group-text dist inputDisName" for="inputGroupSelect01">DNI DEL ALUMNO:</label>
            <input type="text" class="form-control inputDis" [(ngModel)]="alumno.DNIalumno" placeholder="DNI del alumno" aria-label="Username" aria-describedby="basic-addon1">
          </div>
          <div class="input-group mb-3">
            <label class="input-group-text dist inputDisName" for="inputGroupSelect01">NIVEL DEL ALUMNO:</label>
            <input type="text" class="form-control inputDis" [(ngModel)]="alumno.NivelAlumno" placeholder="NIvel de estudio del alumno" aria-label="Username" aria-describedby="basic-addon1">
            <label class="input-group-text dist inputDisName" for="inputGroupSelect01">GRADO DEL ALUMNO:</label>
            <input type="text" class="form-control inputDis" [(ngModel)]="alumno.GradoAlumno" placeholder="Grado del alumno" aria-label="Username" aria-describedby="basic-addon1">
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text dist inputDisName" id="basic-addon1">I. E. PROCEDENCIA:</span>
            <input type="text" class="form-control inputDis" [(ngModel)]="alumno.IEProcAlumno" placeholder="I. E de Procedencia" aria-label="Username" aria-describedby="basic-addon1" >
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center align-items-center buttons">
        <button type="submit" class="btn spaced" (click)="agregarAlumno()">Agregar un Estudiante</button>
        <button type="submit" class="btn spaced" (click)="limpiarCeldas()">Limpiar Celdas de Estudiantes</button>
        <button type="submit" class="btn spaced" (click)="rellenarAuto()">Rellenar Celdas Automaticamente</button>
        <button type="submit" class="btn spaced" (click)="eliminarUltimoAlumno()">Eliminar Últ. Estudiante</button>
        <button type="submit" class="btn" (click)="generatePDF()">Generar Contrato PDF</button>
      </div>
    </div>
  </div>

  <div class="tables">
    <ng-container *ngFor="let tabla of tablas">
      <table class="table table-striped" [id]="tabla.id">
        <thead>
          <tr>
            <th scope="col" *ngFor="let header of tabla.headers">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tabla.rows">
            <th scope="row">{{ row[0] }}</th>
            <td *ngFor="let cell of row.slice(1)">{{ cell }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
  <div class="tables">
    <table class="table table-striped" id="horarioinvierno">
      <thead>
        <tr>
          <th scope="col">NIVEL</th>
          <th scope="col">INGRESO</th>
          <th scope="col">SALIDA</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Inicial</th>
          <td>8:45 am</td>
          <td rowspan="3">La salida se realizará en el horario habitual.</td>
        </tr>
        <tr>
          <th scope="row">Primaria</th>
          <td>8:00 am</td>
        </tr>
        <tr>
          <th scope="row">Secundaria</th>
          <td>7:55 am</td>
        </tr>
      </tbody>
    </table>

    <ng-container *ngFor="let tabla of totalAlumnos">
      <table class="table table-striped" [id]="tabla.id">
        <thead>
          <tr>
            <td [attr.colspan]="tabla.filas[0].length">{{ tabla.nivel }}</td>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let fila of tabla.filas">
            <td *ngFor="let dato of fila">{{ dato }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>

  </div>
</body>

