<div color="primary" class="toolbar">

  <div class="toolbar-v2-title">
    {{titulo}}
  </div>

  <span class="toolbar-item-spacer"></span>

  <div class="toolbar-v2-user">
    {{usuario.name ?? ''}} {{usuario.name_2 ?? ''}} {{usuario.lastname_1 ?? ''}} {{usuario.lastname_2 ?? ''}}
  </div>


</div>
