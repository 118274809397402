import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sv-pdf-invoice-a4',
  templateUrl: './sv-pdf-invoice-a4.component.html',
  styleUrl: './sv-pdf-invoice-a4.component.css'
})
export class SvPdfInvoiceA4Component {

  @Input() title: string = "";
  @Input() content: string = "";

}
