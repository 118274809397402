<!-- app.component.html -->
<app-sv-page-content-header
  titulo="Configuración de Usuarios">
</app-sv-page-content-header>

<div class="page-content">

  <div class="page-content-header-search">
    <div class="search-container">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Buscar usuario</mat-label>
        <input matInput placeholder="Buscar usuarios" (keydown.enter)="applyFilter($event)" class="search-input" />
      </mat-form-field>
      <button mat-icon-button aria-label="Buscar" (click)="applyFilter($event)">
        <mat-icon>search</mat-icon>
      </button>

    </div>
    <div class="btn-list-container">
      <button class="btn-status btn-primary" (click)="openCrearUsuario()">Crear Usuario</button>
      <button class="btn-status btn-secondary">Test</button>
      <button class="btn-status btn-success">Test</button>
      <button class="btn-status btn-warning">Test</button>
      <button class="btn-status btn-info">Test</button>
    </div>
  </div>

  <div class="card-container">
    <mat-card *ngFor="let usuario of listaUsuarios" class="custom-mat-card">
      <!-- <mat-card-header class="px-3 py-2">
        <div class="header-content">
          <div class="header-text">
            <mat-card-title>{{usuario.nombres}}</mat-card-title>
            <mat-card-subtitle>{{usuario.documento_identidad}}</mat-card-subtitle>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openEditarUsuario(usuario)">
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
            <button mat-menu-item disabled>
              <mat-icon>voicemail</mat-icon>
              <span>Asignar Roles</span>
            </button>
            <button mat-menu-item disabled>
              <mat-icon>password</mat-icon>
              <span>Cambiar contraseña</span>
            </button>
            <button mat-menu-item (click)="deleteUsuario(usuario.id)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </div>
      </mat-card-header> -->


      <mat-card-content>
        <!-- <p>Detalles del usuario {{usuario.nombres}}</p> -->
        <div class="header-content">
          <div class="header-text">
            <b>{{usuario.nombres}}</b>
            <i>{{usuario.documento_identidad}}</i>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openEditarUsuario(usuario)">
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
            <button mat-menu-item disabled>
              <mat-icon>voicemail</mat-icon>
              <span>Asignar Roles</span>
            </button>
            <button mat-menu-item disabled>
              <mat-icon>password</mat-icon>
              <span>Cambiar contraseña</span>
            </button>
            <button mat-menu-item (click)="deleteUsuario(usuario.id)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </div>
      </mat-card-content>


      <!-- <mat-card-actions>
        <button mat-button>Un botón</button>
      </mat-card-actions> -->
    </mat-card>
  </div>

  <mat-paginator [length]="totalUsuarios" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="pageEvent($event)">
  </mat-paginator>

</div>
