import { Component } from '@angular/core';

@Component({
  selector: 'app-conf-soporte-secuencias-list',
  templateUrl: './conf-soporte-secuencias-list.component.html',
  styleUrl: './conf-soporte-secuencias-list.component.css'
})
export class ConfSoporteSecuenciasListComponent {

}
