<div class="esv-page">
  <div class="page-title">
    {{'Matriculando a ' + ((xIdEstudiante != 0)
    ? getPersonaForm('estudiante')?.get('name')?.value + ' ' + getPersonaForm('estudiante')?.get('lastname_1')?.value
    : 'Nuevo Estudiante')}}
  </div>

  <div class="body">

    <div class="stepper-wrapper">
      <mat-horizontal-stepper [linear]="true" #stepper (selectionChange)="onStepChange($event)">

        <mat-step label="Confirmar datos">
          <app-matri-proceso-main-paso1
            [xMatriculaForm]="matriculaForm"
            [xIdEstudiante]="xIdEstudiante">
          </app-matri-proceso-main-paso1>
        </mat-step>

          <mat-step label="Seleccionar grupo" [completed]="id_grupo != ''">
          <app-matri-proceso-main-paso2
            [xMatriculaForm]="matriculaForm"
            [xIdGrupoSeleccionado]="id_grupo"
            [xIdEstudiante]="xIdEstudiante"
            (onGrupoSeleccionadoEmmiter)="onStep2GrupoSelected()">
          </app-matri-proceso-main-paso2>
        </mat-step>

        <mat-step label="Pago de matrícula">
          <app-matri-proceso-main-paso3
            [xMatriculaForm]="matriculaForm"
            [xIdGrupoSeleccionado]="id_grupo"
            [xIdEstudiante]="xIdEstudiante">
          </app-matri-proceso-main-paso3>
        </mat-step>

      </mat-horizontal-stepper>
    </div>

  </div>
</div>

