import { Component } from '@angular/core';
import { VistaModulosService } from '../../vista-modulos.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-vista-modulos',
  templateUrl: './vista-modulos.component.html',
  styleUrls: ['./vista-modulos.component.css'],
})
export class VistaModulosComponent {

  usuario: any;

  constructor (
    private vistaModulosService: VistaModulosService,
    private router: Router,

    private authService: AuthService,
  ) { }

  ngOnInit(){
    this.usuario = this.authService.getUsuario();
    this.getModulosMenu();
    // console.log('asdasd');
  }

  listaModulos: any = [];

  getModulosMenu(): void {
    this.vistaModulosService.apiGetModulosMenu()
      .subscribe((data: any) => {
        this.listaModulos = data;
      });
  }

  navigateToModule(id_modulo: number, url: string, modulo_nombre: string) {
    this.router.navigate(['/modulos', url], {
      state: {
        id_modulo: id_modulo,
        url: url,
        modulo_nombre: modulo_nombre,
      }
    });
  }

  cerrarSesion(){
    this.authService.logout()
      .subscribe((data: any) => {
        if (data.message === 'Successfully logged out') {
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
        }
      });
  }
}
