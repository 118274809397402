import { Component } from '@angular/core';

@Component({
  selector: 'app-matri-reporte-matriculados-grupo',
  templateUrl: './matri-reporte-matriculados-grupo.component.html',
  styleUrl: './matri-reporte-matriculados-grupo.component.css'
})
export class MatriReporteMatriculadosGrupoComponent {

}
