import { Component } from '@angular/core';

import jsPDF from 'jspdf';

import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-contrato',
  templateUrl: './contrato.component.html',
  styleUrls: ['./contrato.component.css']
})
export class ContratoComponent {



  tablas = [
    {
      id: 'costos',
      headers: ['NIVEL', 'Matricula S/.', 'Mensualidad Presencial y/o Semipresencial'],
      rows: [
        ['inicial', '350.00', '380.00'],
        ['Primaria', '350.00', '380.00'],
        ['Secundaria', '350.00', '380.00']
      ]
    },
    {
      id: 'mensualidad',
      headers: ['MENSUALIDAD', 'FECHA DE PAGO'],
      rows: [
        ['PRIMERA - Marzo', 'Viernes 29'],
        ['SEGUNDA - Abril', 'Martes 30'],
        ['TERCERA - Mayo', 'Viernes 31'],
        ['CUARTA - Junio', 'Viernes 28'],
        ['QUINTA - Julio', 'Miercoles 31'],
        ['SEXTA - Agosto', 'Viernes 31'],
        ['SEPTIMA - Septiembre', 'Lunes 30'],
        ['OCTABA - Octubre', 'Jueves 31'],
        ['NOVENA - Noviembre', 'Viernes 29'],
        ['DECIMA - Diciembre', 'Viernes 13'],
      ]
    },
    {
      id: 'horarioverano',
      headers: ['NIVEL', 'INGRESO', 'SALIDA'],
      rows: [
        ['INICIAL', '8:30 am','1:30 pm'],
        ['PRIMARIA', '7:50 am','1:50 pm'],
        ['SECUNDARIA', '7:45 am','2:30 pm'],
      ]
    },
  ];

  totalAlumnos = [
    { id: 'totalalumnosinicial', nivel: 'INICIAL', filas: [['3 años', '4 años', '4 años'], ['25', '25', '25']] },
    { id: 'totalalumnosprimaria', nivel: 'PRIMARIA', filas: [['1º', '2º', '3º', '4º', '5º', '6º'], ['30', '30', '25', '28', '28', '28']] },
    { id: 'totalalumnossecundaria', nivel: 'SECUNDARIA', filas: [['1º', '2º', '3º', '4º', '5º'], ['30', '30', '30', '25', '25']] }
  ];

  nombre: string = '';
  dni: string = '';
  domicilio: string = '';
  distrito: string = '';
  celular: string = '';
  representante: string = '';
  dnirepresentante: string = '';

  textoPDF: string = '';
  textoPDF1: string = '';
  alumnos: any[] = [];

  ultimoIndiceAgregado: number = -1;
  agregarAlumno() {
    this.alumnos.push({
      NombreAlumno: '',
      DNIalumno: '',
      NivelAlumno: '',
      GradoAlumno: '',
      IEProcAlumno: ''
    });
    this.ultimoIndiceAgregado = this.alumnos.length - 1;
  }
  eliminarUltimoAlumno() {
    if (this.alumnos.length > 0) {
        this.alumnos.pop();
    }
  }
  limpiarCeldas() {
    if (this.ultimoIndiceAgregado !== -1) { // Verificamos si se ha agregado algún alumno
        const alumno = this.alumnos[this.ultimoIndiceAgregado];
        alumno.NombreAlumno = '';
        alumno.DNIalumno = '';
        alumno.NivelAlumno = '';
        alumno.GradoAlumno = '';
        alumno.IEProcAlumno = '';
    }
  }
  limpiarCeldasPaMa() {
    this.nombre = '';
    this.dni = '';
    this.domicilio = '';
    this.distrito = '';
    this.celular = '';
  }
  rellenarAutoPaMa(){
    this.nombre = '........................................................................';
    this.dni = '............................';
    this.domicilio = '..............................................................';
    this.distrito = '..............................';
    this.celular = '.....................';
  }
  rellenarAutoRep(){
    this.representante = '............................................................';
    this.dnirepresentante = '.....................';
  }
  limpiarCeldasRep(){
    this.representante = '';
    this.dnirepresentante = '';
  }
  rellenarAuto() {
    if (this.ultimoIndiceAgregado !== -1) {
        const alumno = this.alumnos[this.ultimoIndiceAgregado];
        alumno.NombreAlumno = '..................................................';
        alumno.DNIalumno = '.......................';
        alumno.NivelAlumno = '..............................................';
        alumno.GradoAlumno = '....................';
        alumno.IEProcAlumno = '...........................................................................';
    }
  }

  generatePDF() {
    const doc = new jsPDF();

    const logoImg = '../../assets/LOGOTEXT.png';
    doc.addImage(
      logoImg,
      'JPEG',
      10,
      7,
      32,
      10
    );

    doc.setFont('arial');
    doc.setFontSize(11);

    const titulo = 'CONTRATO DE PRESTACION DE SERVICIOS EDUCATIVOS AÑO 2024';
    const tituloSub = ' __________________________________________________________________';

    const tituloI = 'I.- DE LOS SUJETOS DEL COMPROMISO';
    const tituloII = 'II.- OBJETO DEL CONTRATO:';
    const tituloIII = 'III.- COSTOS ACADEMICOS, NÚMERO Y OPORTUNIDAD DE PAGO DE LA MATRÍCULA Y DE LAS PENSIONES, ASÍ COMO DE LOS POSIBLES DESCUENTOS:';
    this.textoPDF1 = '';
    this.textoPDF1 += `Conste por el presente CONTRATO DE PRESTACIÓN DE SERVICIOS EDUCATIVOS que suscriben de una parte, EL ANEXO DE LA INSTITUCIÓN EDUCATIVA SAN JOSE JULIACA con RUC 10444886850, autorizada mediante R.D. 1509 de fecha 29 de marzo de 2000, R.D.R. 1235 de fecha 19 de febrero 2001, R.D.R. 8021 de fecha 16 de agosto del 2003, R.D.R 0649 de fecha 16 junio del 2004, R.D.R 2301 de fecha 15 de octubre del 2014 y R.D.R. 2358 de fecha 28 de octubre del 2014, y licencia de funcionamiento N° 085-2018, debidamente representada por la promotora Hilda Yoly Quispe Chaiña con DNI 10444886850, con domicilio en el Jr. Gonzales Prada N° 421, a quien en adelante se denominará EL COLEGIO y de otra parte. Sr(a). ${this.nombre}, con DNI Nro. ${this.dni}, señalando domicilio en: ${this.domicilio}, en el distrito de: ${this.distrito}, con celular: ${this.celular}. (en el caso de representación) debidamente representado por don(ña): ${this.representante} con DNI N° ${this.dnirepresentante}, a quien en adelante se denominará EL PADRE DE FAMILIA, TUTOR LEGAL O APODERADO, quienes firman el presente en las siguientes condiciones y cláusulas:\n \n`;
    const texts = '1.1.- EL COLEGIO es una INSTITUCIÓN EDUCATIVA        \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n                        \n \n1.2.- El padre de familia, tutor legal o apoderado del\n \n \n \n \n \n \nPor el presente el padre de familia, tutor legal o apoderado, contrata, elige y procede a matricular en los servicios educativos que brinda el colegio, al menor(es).';
    const paraf11 = 'que brinda SERVICIOS EDUCATIVOS PRESENCIALES en los niveles de inicial, primaria y secundaria de educación Básica Regular en mérito a las RM. N° 916 de fecha 15 de marzo de 1976, y de conformidad con lo establecido en la R.M. N° 483-89- ED, la Ley General de Educación - Ley Nº 28044, su Reglamento, la ley de centros educativos privados - ley N°26549, el reglamento de instituciones educativas privadas de educación básica y educación técnico productiva, aprobado por D.S N° 009-2006-ED, la Ley de Promoción de la Inversión en la Educación, Decreto Legislativo 882 "Ley de promoción de la inversión en la Educacion", y el Reglamento Interno de EL COLEGIO.   Los servicios presenciales y virtuales dependerán de la coyuntura en la que se desarrolle el año lectivo, sin alterar la calidad del mismo.';
    const parraf12 = 'menor cuyos datos se consignan en el presente contrato ha solicitado matricular al estudiante para el presente año escolar 2024.';
    const parraf31 = 'proceso de Matrícula 2024 para la educación básica del Ministerio de Educación y demás normas legales vigentes, y con el propósito de que el padre de familia, tutor legal o apoderado disponga de toda la información respecto al costo del servicio educativo y las características del mismo, puso en conocimiento de los padres de familia, tutores legales o apoderados, antes de finalizar el año escolar 2023 y durante el proceso de matrícula 2024 (Comunicado N° 055 con fecha de diciembre del 2023), mediante carteles, así como el marco doctrinal y axiológico que sustenta la educación del colegio, y por lo tanto, sus fines, objetivos y normas establecidas en el Reglamento Interno del colegio, el cual se encuentra a disposición del padre de familia, tutor legal o apoderado, expresando su compromiso de observar y respetar dicho marco doctrinal, axiológico, ideario, estilo educativo y Reglamento.';
    const parraf32 = 'contraprestación de los servicios materia del presente y bajo la denominación de MATRÍCULA Y DIEZ CUOTAS POR PENSIÓN DE ESTUDIOS, el padre de familia, tutor legal o apoderado abonará por cada uno de sus menores hijos al colegio los siguientes conceptos:';

    this.textoPDF = '';
    this.alumnos.forEach((alumno, index) => {
      this.textoPDF += `Nombres y apellidos: ${alumno.NombreAlumno} DNI N. ${alumno.DNIalumno} en adelante Grado: ${alumno.GradoAlumno} EL ALUMNO, del Nivel: ${alumno.NivelAlumno} I.E.Proc. ${alumno.IEProcAlumno}.\n \n`;
    });

    const text1 = `${this.textoPDF}`;
    const textP = `${this.textoPDF1}`;
    const textd = '3.1.- El colegio, en cumplimiento al instructivo para el \n \n \n \n \n \n \n \n \n \n \n \n \n \n  \n \n \n \n  \n \n3.2.- COSTO DEL SERVICIO EDUCATIVO: Como\n \n \n \n \n \n \n \nNivel Inicial, Primaria y Secundaria:';

    doc.setFont('Arial', 'bold');
    doc.text(titulo, 100, 25, { align: 'center', maxWidth: 200 });
    doc.text(tituloSub, 100, 25, { align: 'center', maxWidth: 200 });
    doc.text(tituloI, 10, 152, { maxWidth: 200 });
    doc.text(tituloII, 10, 261, { maxWidth: 200 });
    doc.text(tituloIII, 110, 113, { align: 'justify', maxWidth: 90 });
    doc.setFont('Arial', 'normal');

    doc.text(textP, 10, 35, { align: 'justify', maxWidth: 90 });
    doc.text(texts, 10, 158, { align: 'justify', maxWidth: 90 });
    doc.text(paraf11, 14, 163, { align: 'justify', maxWidth: 86 });
    doc.text(parraf12, 14, 243, { align: 'justify', maxWidth: 86 });
    doc.text(text1, 110, 35, { align: 'justify', maxWidth: 90 });
    doc.text(textd, 110, 132, { align: 'justify', maxWidth: 90 });
    doc.text(parraf31, 114, 136, { align: 'justify', maxWidth: 86 });
    doc.text(parraf32, 114, 225, { align: 'justify', maxWidth: 86 });


    autoTable(doc, { html: "#costos", theme: 'grid', margin: { left: 110, right: 10, top: 259 }, styles: { fontSize: 5 },
    didParseCell: (data) => {
      data.cell.styles.halign = 'center';
    }});



    doc.addPage();
    doc.addImage(
      logoImg,
      'JPEG',
      10,
      7,
      32,
      10
    );

    const cronograma = 'CRONOGRAMA DE PENSIONES ESCOLARES 2024';
    const tituloIV = 'IV.- LA PROPUESTA PEDAGOGICA, EL PLAN DE ESTUDIOS Y LOS SISTEMAS DE EVALUACIÓN Y CONTROL DE LOS ESTUDIANTES:';
    const tituloV = 'V.- HORARIO DE CLASES Y NUMERO MAXIMO DE ALUMNOS POR AULA';
    const tituloVI = 'VI.- SERVICIOS DE APOYO AL ESTUDIANTE';

    const text2 = `3.3.- Forma y opurtunidad de pago: Todos los pagos por\n \n  \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n3.4.- DESCUENTOS, el PADRE DE FAMILIA, TUTOR\n \n \n \n \na) MATRÍCULA: En caso de hermanos, el primero\n \n \n \n \n \nb) PENSIONES: En caso de hermanos, el primero pagará\n \n \nc) Todo descuento SERÁ EFECTIVO siempre y cuando\n \n \n \nd) Se hará un descuento del 3% al pago anual que \n   realizarán, solo hasta la quincena del mes de abril.  \n \nEL COLEGIO, conforme a Ley, no exige el pago de las pensiones por adelantado, sin embargo, las partes dejan constancia que, si el PADRE DE FAMILIA, TUTOR O APODERADO realiza pagos anticipados o adelantados, serán de forma libre y voluntaria. \n \n \n \n \n4.1.- La propuesta pedagógica. - Trabajamos con el\n \n \n4.2.- El Plan de estudios. Está desarrollado y establecido`;
    const parraf33 = 'estos conceptos se efectuarán mediante cuenta bancaria en el BCP Nro. 405-20632167-0-60 CCI Nro. 00240512063216706098 BANCO DE LA NACIÓN 04-701-580090, a nombre de Adolfo Germán Quispe Chaiña, y/o en SECRETARIA del EL COLEGIO, según normas lo permitan.';
    const parraf34 = 'O APODERADO obtiene un descuento en los siguientes conceptos de acuerdo al Reglamento Interno de EL COLEGIO.';
    const parraf34a = 'pagará el 100% y a partir del segundo hermano en adelante se obtendrá el 50% de descuento por cada hermano, solo en matrícula.                       \nAsimismo, poner en conocimiento que no hay devoluciones en cuestión de pagos de matrícula.';
    const parraf34b = 'el 100%, el segundo hermano obtiene el 5% y el tercer hermano el 10%.';
    const parraf34c = 'cancele dentro de los plazos establecidos según cronograma de pensiones mensuales, en caso de incumplimiento o atraso pierde dicho descuento.';
    const parraf41 = 'CNEB - Curriculo Nacional de Educación Básica, y la Propuesta Educativa de nuestra entidad promotora.';
    const parraf42 = 'para todo el año académico en base a competencias, capacidades y desempeños.';

    const text3 = `4.3.- Sistema de evaluación. Es permanente, porque la\n \n \n \n \n \n \n \nEL COLEGIO En virtud a la RVM 334-2021-MINEDU valorará el proceso de enseñanza y aprendizaje, para tal efecto informará por escrito al padre de familia, tutor legal o apoderado.                       \n \n \n \n5.1.- EI HORARIO REGULAR DE CLASES escolares \n   es el siguiente: De lunes a viernes.                      \n \n \n \n \n \n \n \nHORARIO DE INVIERNO: Desde el mes de mayo hasta finales de agosto.                      \n \n \n \n \n \n \n \nLa modificación ocasional de este horario por alguna particularidad será debidamente informada con antelación. \n \n5.2.- El número máximo de Alumnos por aula es de: \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n          \n \nPara el apoyo del estudiante existe:             \n•  Implementación multimedia en cada aula.                     \n•  Plataforma virtual educativa`;
    const parraf43 = 'meta es valorar las capacidades habilidades para llegar a las competencias. Durante presente año, regirán las normas para la promoción recuperación. Estas disposiciones han sido dadas por el Ministerio de Educación según RVM 334 -2021- MINEDU. Está desarrollado en el reglamento Interno del colegio.';

    doc.setFont('Arial', 'bold');
    doc.setFontSize(10);
    doc.text(cronograma, 10, 58, { maxWidth: 90});
    doc.text(tituloIV, 10, 248, { align:'justify', maxWidth: 90});
    doc.text(tituloV, 110, 81, { align: 'justify', maxWidth: 90 });
    doc.text(tituloVI, 110, 260, { align: 'justify', maxWidth: 90 });
    doc.setFont('Arial', 'normal');
    doc.setFontSize(11);
    doc.text(text2, 10, 26, { align: 'justify', maxWidth: 90 });
    doc.text(parraf33, 14, 30, { align: 'justify', maxWidth: 86 });
    doc.text(parraf34, 14, 133, { align: 'justify', maxWidth: 86 });
    doc.text(parraf34a, 14, 155, { align: 'justify', maxWidth: 86 });
    doc.text(parraf34b, 14, 182, { align: 'justify', maxWidth: 86 });
    doc.text(parraf34c, 14, 195, { align: 'justify', maxWidth: 86 });
    doc.text(parraf41, 14, 267, { align: 'justify', maxWidth: 86 });
    doc.text(parraf42, 14, 280, { align: 'justify', maxWidth: 86 });
    doc.text(text3, 110, 26, { align: 'justify', maxWidth: 90 });
    doc.text(parraf43, 114, 30, { align: 'justify', maxWidth: 86 });

    autoTable(doc, { html: "#mensualidad", theme: 'grid', margin: { left: 10, right: 110, top: 60 }, styles: { fontSize: 5 },
    didParseCell: (data) => {
      data.cell.styles.halign = 'center';
    }});

    autoTable(doc, { html: "#horarioverano", theme: 'grid', startY: 101, margin: { left: 110, right: 10 }, styles: { fontSize: 7 },
    didParseCell: (data) => {
      data.cell.styles.halign = 'center';
    }});

    autoTable(doc, { html: "#horarioinvierno", theme: 'grid', startY: 141, margin: { left: 110, right: 10 }, styles: { fontSize: 7 },
    didParseCell: (data) => {
      data.cell.styles.halign = 'center';
    }});

    autoTable(doc, { html: "#totalalumnosinicial", theme: 'grid', startY: 195, margin: { left: 110, right: 10 }, styles: { fontSize: 7 },
    didParseCell: (data) => {
      data.cell.styles.halign = 'center';
    }});
    autoTable(doc, { html: "#totalalumnosprimaria", theme: 'grid', startY: 215, margin: { left: 110, right: 10 }, styles: { fontSize: 7 },
    didParseCell: (data) => {
      data.cell.styles.halign = 'center';
    }});
    autoTable(doc, { html: "#totalalumnossecundaria", theme: 'grid', startY: 235, margin: { left: 110, right: 10 }, styles: { fontSize: 7 },
    didParseCell: (data) => {
      data.cell.styles.halign = 'center';
    }});

    doc.addPage();
    doc.addImage(
      logoImg,
      'JPEG',
      10,
      7,
      32,
      10
    );

    const tituloVII = 'VII.- VIGENCIA DEL CONTRATO:';
    const tituloVIII = 'VIII. DERECHOS Y OBLIGACIONES DE LAS PARTES';
    const Sub81 = '8.1.- Son obligaciones de EL COLEGIO';
    const Sub82 = '8.2.- Son obligaciones de EL PADRE DE FAMILIA, TUTOR LEGAL O APODERADO:';
    const Sub83 = '8.3.- Son derechos y obligaciones de EL ALUMNO:';
    const tituloIX = 'IX.- MEDIDAS QUE ADOPTA EL COLEGIO FRENTE AL INCUMPLIMIENTO DEL PAGO DE LAS PENSIONES ESCOLARES';
    const Subti = 'EL COLEGIO tiene la facultad de:';

    const text4 = `•  Biblioteca virtual (cientos de libros a disposición de           nuestros estudiantes para descargar e imprimir).             •  Videoteca.                                                    \n•  Talleres de talentos bimestrales.                                \n•  Monitoreo pedagógico.                                           \n•  Informe de rendimiento mensual.                                    \n•  Departamento de Psicología y acompañamiento TOE.  \n•   Monitoreo Psicológico "Escuela de Padres.                                                      \n \n7.1.- La vigencia del presente CONTRATO corresponde\n \n \n \n \n                        \n \na) Brindar los SERVICIOS EDUCATIVOS\n \n                       \n \nb) Informar a EL PADRE DE FAMILIA, TUTOR\n \n \n \n \nc) Brindar la información que requiera EL PADRE DE\n \n \n \n \n                              \n \na) Participar activamente en el proceso educativo de su\n \n \n \n \nb) Cumplir oportunamente con el pago de la matricula y\n   las cuotas de Pensiones mensuales responsablemente.        \nc) Asistir al Colegio cada vez que sea citado para\n \n \nd) Consignar con veracidad la información requerida en\n \n \n \n \ne) Asistir a las citaciones que se le cursen por falta de\n   pago de pensiones escolares.                                 \nf) A autorizar a la Institución que puedan enviar\n                                            \n \ng) Aceptar que si uno de los padres tiene la tenencia legal`;
    const parraf81a = 'PRESENCIALES y/o virtuales (en casos extraordinarios), de forma eficiente.';
    const parraf71 = 'al AÑO ACADÉMICO (LECTIVO) 2024 es decir desde el 01 de marzo hasta el 31 de diciembre del 2024, siendo renovable previo acuerdo de las partes.';
    const parraf81b = 'LEGAL O PODERADO sobre los resultados del proceso educativo y formativo de su menor hijo, dando las indicaciones y orientaciones destinadas a superar las deficiencias académicas o de comportamiento.';
    const parraf81c = 'FAMILIA, TUTOR LEGAL O PODERADO respecto del proceso educativo de su menor hijo de conformidad al reglamento Interno del colegio.';
    const parraf82a = 'menor hijo (a) como son: apoyar con el acceso a internet. seguir las recomendaciones dadas por las autoridades del colegio (Director (a), Docente, Tutor y Psicólogo).';
    const parraf82c = 'información y/o tratar asuntos relacionados con la formación de su hijo(a).';
    const parraf82d = 'la Declaración Jurada de Datos al momento de la matrícula debiendo comunicar en su oportunidad cualquier variación en especial lo referente al domicilio, teléfonos y correos.';
    const parraf82f = 'documentos, comunicados, invitaciones y otros por medio digital.';
    const parraf82g = 'de su menor hijo(a) NO LIMITA de modo alguno el ejercicio de la patria potestad del otro padre del alumno(a), quien goza de todos los derechos como padre del alumno(a) entre los cuales se encuentra el';

    const text5 = `h) A no involucrar a EL COLEGIO, en los procesos\n \n \n \ni) A presentar el poder de representación de su menor hijo\n \n \n \n \n \n \n \n \n \na) Tiene derecho a recibir un servicio de educación\n \n  \nb) Tiene derecho a recibir por parte de todos los\n              \n \nc) Cumplir el Reglamento Interno de EL COLEGIO.    \nd) Respetar y valorar a todas las personas que integran la\n \n \n \n \ne) Asistir puntualmente a las clases y actividades que\n \n \n \nf) Cumplir sus obligaciones académicas de la mejor\n \n \n \n \n \n               \n \n1. Retener los certificados de estudios de los periodos no\n \n \n \n2. No renovar el contrato de prestación de servicio a favor\n \n \n \n \n3. Negarse a renovar para el siguiente año lectivo 2025, la\n \n \n \n \n4. Informar a las Centrales de Riesgo (Infocorp Equifax,\n`;
    const parraf82g2 = 'acceso a la información sobre el estado académico, conductual y administrativo del alumno(a).';
    const parraf82h = 'judiciales o extrajudiciales, sobre asuntos de tenencia, alimentos, régimen de visitas y otros, de su menor hijo(a) o hijos(as).';
    const parraf82i = '(a), contenido en escritura pública, debidamente inscrito en registros públicos de Juliaca, resolución judicial 0 documentos extrajudiciales, o carta poder con firmas notariales legalizadas otorgado por ambos padres, según corresponda, en caso, es representado en el acto de matricula 2024 de su menor hijo u otros actos que requieran su presencia o asistencia.';
    const parraf83a = 'integral según plan curricular, propuesta academica y Reglamento Interno de EL COLEGIO.';
    const parraf83b = 'integrantes de EL COLEGIO, un servicio de calidad y equidad.';
    const parraf83d = 'Insitucion Educativa, convivir en armonía con sus compañeros sin agredirlos verbal ni físicamente, no fomentar en los demás el rechazo hacia otro compañero(a)';
    const parraf83e = 'programe EL COLEGIO enalteciendo con sus actitudes y expresiones el buen nombre de EL COLEGIO.';
    const parraf83f = 'manera posible, para su propio beneficio y crecimiento personal.';
    const parraf91 = 'cancelados, según la Ley N° 27665 que modifica la Ley N° 26549 en su artículo 16°.';
    const parraf92 = 'del alumno(a) para el año siguiente 2025, por falta de puntualidad en el pago de las pensiones de enseñanza correspondientes al servicio educativo proporcionado en el año lectivo 2024.';
    const parraf93 = 'matrícula de los estudiantes cuyos padres o tutores legales o apoderados hayan incumplido con sus obligaciones pactadas en el presente contrato.';
    const parraf94 = 'Certicom, etc.) las deudas por incumplimiento en el pago del costo de servicio educativo en el caso que se adeude dos (2) o más pensiones escolares sean consecutivas o alternas.';

    doc.setFont('Arial', 'bold');
    doc.setFontSize(10);
    doc.text(tituloVII, 10, 63, { maxWidth: 90 });
    doc.text(tituloVIII, 10, 91, { maxWidth: 90 });
    doc.text(Sub81, 10, 97, { maxWidth: 90 });
    doc.text(Sub82, 10, 162, { align: 'justify', maxWidth: 90 });
    doc.text(Sub83, 110, 92, { align: 'justify', maxWidth: 90 });
    doc.text(tituloIX, 110, 184, { align: 'justify', maxWidth: 90 });
    doc.text(Subti, 110, 200, { maxWidth: 90 });
    doc.setFontSize(11);
    doc.setFont('Arial', 'normal');

    doc.text(text4, 10, 26, { align: 'justify', maxWidth: 90 });
    doc.text(parraf71, 14, 75, { align: 'justify', maxWidth: 86 });
    doc.text(parraf81a, 14, 106, { align: 'justify', maxWidth: 86 });
    doc.text(parraf81b, 14, 124, { align: 'justify', maxWidth: 86 });
    doc.text(parraf81c, 14, 148, { align: 'justify', maxWidth: 86 });
    doc.text(parraf82a, 14, 177, { align: 'justify', maxWidth: 86 });
    doc.text(parraf82c, 14, 213, { align: 'justify', maxWidth: 86 });
    doc.text(parraf82d, 14, 227, { align: 'justify', maxWidth: 86 });
    doc.text(parraf82f, 14, 258, { align: 'justify', maxWidth: 86 });
    doc.text(parraf82g, 14, 271, { align: 'justify', maxWidth: 86 });

    doc.text(text5, 110, 36, { align: 'justify', maxWidth: 90 });
    doc.text(parraf82g2, 114, 26, { align: 'justify', maxWidth: 86 });
    doc.text(parraf82h, 114, 40, { align: 'justify', maxWidth: 86 });
    doc.text(parraf82i, 114, 58, { align: 'justify', maxWidth: 86 });
    doc.text(parraf83a, 114, 102, { align: 'justify', maxWidth: 86 });
    doc.text(parraf83b, 114, 116, { align: 'justify', maxWidth: 86 });
    doc.text(parraf83d, 114, 134, { align: 'justify', maxWidth: 86 });
    doc.text(parraf83e, 114, 156, { align: 'justify', maxWidth: 86 });
    doc.text(parraf83f, 114, 174, { align: 'justify', maxWidth: 86 });
    doc.text(parraf91, 114, 210, { align: 'justify', maxWidth: 86 });
    doc.text(parraf92, 114, 227, { align: 'justify', maxWidth: 86 });
    doc.text(parraf93, 114, 250, { align: 'justify', maxWidth: 86 });
    doc.text(parraf94, 114, 272, { align: 'justify', maxWidth: 86 });


    doc.addPage();
    doc.addImage(
      logoImg,
      'JPEG',
      10,
      7,
      32,
      10
    );
    const fechaActual = new Date();
    const fechaFormateada = `${fechaActual.getDate()} de ${obtenerNombreMes(fechaActual.getMonth())} del ${fechaActual.getFullYear()}`;

    function obtenerNombreMes(numeroMes: number): string {
      const meses = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];
      return meses[numeroMes];
    }

    const tituloX = 'X.- DISPOSICIONES COMPLEMENTARIAS:';
    const tituloXI = 'XI.- AUTORIZACIONES DEL EI PADRE DE FAMILIA, TUTOR LEGAL O APODERADO.';
    const tituloXII = 'XII.- DECLARACION DE RECEPCION DOCUMENTOS ANEXO AL CONTRATO.';
    const tituloXIII = 'XIII.- DECLARACION DEL DOMICILIO';
    const tituloXIV = 'XIV.- MEDIOS DE COMUNICACIÓN OFICIALES.';
    const tituloXV = 'XV.- DECLARACION DE CONFORMIDAD:';

    const text6 = ` \n \n10.1.- EL COLEGIO se reserva el derecho de modificar\n \n \n \n10.2.- LA MATRICULA: es el pago que realiza una vez\n \n \n10.3.- En caso de incumplimiento de pago de pensiones\n \n \n \n10.4.- COMUNICACIÓN POR CORREO\n \n \n \n \n \n \n         \n \n \n11.1. De conformidad a la Ley N° 29733 "ley de\n \n \n \n \n \n \n \n \n \n11.2. EI PADRE DE FAMILIA, TUTOR LEGAL O\n \n \n \n \n \n \n \n       \n \n \n \nDE EL PADRE DE FAMILIA, TUTOR LEGAL O APODERADO declara que, al momento de la suscripción del presente, ha recepcionado de EL COLEGIO a través de correo electronico, respecto a las partes pertinentes a los estudiantes: sistema de evaluación, matricula, y régimen económico y los derechos y obligaciones de los padres de familia, etc.                              \n \n \nLos partes declaran como sus respectivos domicilios los indicados en el presente documento, lugares que se consideren válidos para la remisión de documentos, instrucciones y notificaciones, los mismos que deberán ser entregados por cualquier medio escrito.`;
    const parraf101 = 'la plana docente, por motivos de fuerza mayor o por disponibilidad del docente (profesor), garantizando que la calidad del curso no se vea afectado.';
    const parraf102 = 'por cada año académico y previa suscripción del contrato de prestación de servicio educativo.';
    const parraf103 = 'escolares, EL COLEGIO cursará cartas de cobranza de pagos de pensiones, sea vía courier, correo electrónico o cartas notariales.';
    const parraf104 = 'ELECTRONICO: Sin prejuicio de lo establecido en el numeral 8.2 del presente CONTRATO, el padre de familia, tutor legal o apoderado autoriza al Colegio a cursarle todo tipo de comunicaciones al correo electrónico consignado en la introducción del presente y en la Declaración jurada de datos.';
    const parraf111 = 'protección de Datos Personales" y su reglamento aprobado mediante DS N° 003-2013-JUS, el EI PADRE DE FAMILIA, TUTOR LEGAL O APODERADO, autoriza a el EL COLEGIO la utilización y/o el tratamiento de sus datos personales y de su menor hijo, unicamenete para recibir información referente a EL COLEGIO, tales como comunicados, invitaciones, información, requerimiento, u otra información.';
    const parraf112 = 'APODERADO autoriza a EL COLEGIO para la utilización, difusión y reproducción de imágenes de las que sea parte el estudiante, registrados en fotos y/o video, para fines publicitarios, folletos, trípticos de difusión, didácticos, académicos, institucionales, sin limitación de manera libre y voluntaria, en publicidad, página web, redes sociales de EL COLEGIO.';

    const text7 = ` \n \nEI PADRE DE FAMILIA, TUTOR LEGAL O EL APODERADO reconoce y se compromete a utilizar los medios oficiales de comunicación de manera adecuada:                \na. Página Web: www.colegiosanjosejuliaca.edu.pe        \nb. Facebook: www.facebook.com/colegiosanjosejuliaca   \nc. Correo electrónico: colegiosanjosejuliaca@gmail.com \nd. WhatsApp: 943415403                                  \ne. Cualquier documento físico firmado y sellado.          \n \n \n \nEL PADRE, TUTOR LEGAL O APODERADO declara tener pleno conocimiento de las condiciones y características del servicio que brinda el COLEGIO, las que encuentra a su entera satisfacción.                         \n \nLas partes declaran que en la suscripción del presente no ha mediado dolo ni presión que lo invalide, siendo su contenido la espontanea expresión de su voluntad, en ese sentido, es de cumplimiento obligatorio por parte de EL COLEGIO y EL PADRE DE FAMILIA, TUTOR LEGAL O APODERADO, por lo que leído en todas y cada una de sus partes, lo ratifican firmándolo en señal de conformidad.                     \n \nJuliaca, ${fechaFormateada}.                          \n \n \n \n \n \n \n  ______________________________   \n       Rep. EL COLEGIO       \n \n \n \n \n \n \n \n_________________________________ \nFirma del padre/madre/tutor o apoderado                       \nNombre y Apellido: ${this.nombre} \nDNI N° ${this.dni}                        \n \nCelular del Padre:                  \nCelular de la Madre:                       \nCelular del Apoderado:                \n(Parentesco):              \nCelular del Estudiante:                      \n \nNOTA: Reiteramos que los beneficios de descuentos están sujetos a la puntualidad del pago de pensiones. Caso contrario, se perderán dichos descuentos.`;

    doc.setFont('Arial', 'bold');
    doc.setFontSize(10);
    doc.text(tituloX, 10, 26, { maxWidth: 90 });
    doc.text(tituloXI, 10, 119, { align: 'justify', maxWidth: 90 });
    doc.text(tituloXII, 10, 216, { align: 'justify', maxWidth: 90 });
    doc.text(tituloXIII, 10, 260, { maxWidth: 90 });
    doc.text(tituloXIV, 110, 26, { align: 'justify', maxWidth: 90 });
    doc.text(tituloXV, 110, 80, { maxWidth: 90 });
    doc.setFont('Arial', 'normal');
    doc.setFontSize(11);
    doc.text(text6, 10, 26, { align: 'justify', maxWidth: 90 });
    doc.text(parraf101, 16, 39, { align: 'justify', maxWidth: 84 });
    doc.text(parraf102, 16, 57, { align: 'justify', maxWidth: 84 });
    doc.text(parraf103, 16, 70, { align: 'justify', maxWidth: 84 });
    doc.text(parraf104, 16, 89, { align: 'justify', maxWidth: 84 });
    doc.text(parraf111, 16, 133, { align: 'justify', maxWidth: 84 });
    doc.text(parraf112, 16, 178, { align: 'justify', maxWidth: 84 });

    doc.text(text7, 110, 26, { align: 'justify', maxWidth: 90 });

    const logoImg1 = '../../assets/cuadro.png';
    doc.addImage(
      logoImg1,
      'JPEG',
      170,
      185,
      35,
      45
    );
    doc.addImage(
      logoImg,
      'JPEG',
      135,
      166,
      32,
      10
    );
    doc.save('contrato de prestacion de servicios.pdf');
  }


}
