import { Component } from '@angular/core';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ConfSecretariaInterface } from '../../../interfaces/conf-secretaria.interface';
import { AdmiSedeInterface } from 'src/app/pages/modulo-administracion/interfaces/admi-sede.interface';
import { ModuloAdministracionService } from 'src/app/pages/modulo-administracion/modulo-administracion.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfSoporteSecretariasEditComponent } from '../conf-soporte-secretarias-edit/conf-soporte-secretarias-edit.component';

@Component({
  selector: 'app-conf-soporte-secretarias-list',
  templateUrl: './conf-soporte-secretarias-list.component.html',
  styleUrl: './conf-soporte-secretarias-list.component.css'
})
export class ConfSoporteSecretariasListComponent {

  listSoporteSecretarias: ConfSecretariaInterface[] = [];
  listSedes: AdmiSedeInterface[] = [];

  constructor(
    private moduloConfigurationService:   ModuloConfiguracionService,
    private moduloAdministrationService:  ModuloAdministracionService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getSedes();
    this.getSecretarias();
  }

  getSedes() {
    this.moduloAdministrationService.apiGetSedes()
      .subscribe((data: any[]) => {
        this.listSedes = data;
      });
  }

  getSecretarias() {
    this.moduloConfigurationService.apiGetSecretarias()
      .subscribe((data: any[]) => {
        this.listSoporteSecretarias = data;
      });
  }

  openCrearSecretaria(): void {
    const dialogRef = this.dialog.open(ConfSoporteSecretariasEditComponent, {
      width: '500px',
      data: { listSedes: this.listSedes, secretaria: null}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSecretarias();
      }
    });
  }

  openEditarSecretaria(secretaria: ConfSecretariaInterface): void {
    const dialogRef = this.dialog.open(ConfSoporteSecretariasEditComponent, {
      width: '500px',
      data: { listSedes: this.listSedes, secretaria: secretaria }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSecretarias();
      }
    });
  }

  deleteSecretaria(id: number) {
    this.moduloConfigurationService.apiDeleteSecretaria(id).subscribe((response: any) => {
      if (response.success) {
        alert('Secretaria eliminada correctamente');
        this.getSecretarias();
      } else {
        alert('Hubo un problema al eliminar la secretaria');
      }
    });
  }

  getNombreSede(id: number): string {
    const nivel = this.listSedes.find(n => n.id === id);
    return nivel ? nivel.nombre : '';
  }


}
