<app-sv-page-content-header
  [titulo]="'Matriculando a ' + ((xIdEstudiante != 0)
  ? getPersonaForm('estudiante')?.get('name')?.value + ' ' + getPersonaForm('estudiante')?.get('lastname_1')?.value
  : 'Nuevo Estudiante')">
</app-sv-page-content-header>

<div class="page-content">

  <div class="page-content-body h-100">

    <div class="stepper-wrapper">
      <mat-horizontal-stepper [linear]="true" #stepper (selectionChange)="onStepChange($event)">

        <mat-step label="Confirmar datos">
          <app-matri-proceso-main-paso1
            [xMatriculaForm]="matriculaForm"
            [xIdEstudiante]="xIdEstudiante">
          </app-matri-proceso-main-paso1>
        </mat-step>

          <mat-step label="Seleccionar grupo" [completed]="id_grupo != ''">
          <app-matri-proceso-main-paso2
            [xMatriculaForm]="matriculaForm"
            [xIdGrupoSeleccionado]="id_grupo"
            (grupoSeleccionado)="onStep2GrupoSelected()">
          </app-matri-proceso-main-paso2>
        </mat-step>

        <mat-step label="Pago de matrícula">
          <app-matri-proceso-main-paso3
            [xMatriculaForm]="matriculaForm"
            [xIdGrupoSeleccionado]="id_grupo"
            [xIdEstudiante]="xIdEstudiante">
          </app-matri-proceso-main-paso3>
        </mat-step>

      </mat-horizontal-stepper>
    </div>


  </div>



</div>


