<app-sv-page-content-header
  titulo="Configuración de Módulos">
</app-sv-page-content-header>

<div class="page-content">
  <div class="page-content-header-search">
    <div class="search-container"></div>
    <div class="btn-list-container">
      <button class="btn-status btn-primary" (click)="openCrearModulo()">Crear Módulo</button>
    </div>
  </div>

  <ng-container>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled>
          <mat-icon class="mat-icon-rtl-mirror">remove</mat-icon>
        </button>
        {{node.name}}
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openCrearSubmodulo(node)">
            <mat-icon>add</mat-icon>
            <span>Agregar Submódulo</span>
          </button>
          <button mat-menu-item (click)="openEditarModulo(node)">
            <mat-icon>edit</mat-icon>
            <span>Editar</span>
          </button>
          <button mat-menu-item (click)="deleteModulo(node.id)">
            <mat-icon>delete</mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openCrearSubmodulo(node)">
            <mat-icon>add</mat-icon>
            <span>Agregar Submódulo</span>
          </button>
          <button mat-menu-item (click)="openEditarModulo(node)">
            <mat-icon>edit</mat-icon>
            <span>Editar</span>
          </button>
          <button mat-menu-item (click)="deleteModulo(node.id)">
            <mat-icon>delete</mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>
      </mat-tree-node>
    </mat-tree>
  </ng-container>
</div>
