import { Component } from '@angular/core';

@Component({
  selector: 'app-sv-deposito-card-estudiante-existente',
  templateUrl: './sv-deposito-card-estudiante-existente.component.html',
  styleUrl: './sv-deposito-card-estudiante-existente.component.css'
})
export class SvDepositoCardEstudianteExistenteComponent {

}
