import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';

@Component({
  selector: 'app-conf-roles-edit',
  templateUrl: './conf-roles-edit.component.html',
  styleUrls: ['./conf-roles-edit.component.css']
})
export class ConfRolesEditComponent {
  rolForm: FormGroup;
  isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfRolesEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private moduloConfiguracionService: ModuloConfiguracionService
  ) {
    this.isEdit = data.es_edit;

    this.rolForm = this.fb.group({
      id: [data?.rol?.id || ''],
      nombre: [data?.rol?.nombre || '', Validators.required],
      codigo: [data?.rol?.codigo || '', Validators.required],
      activo: [data?.rol?.activo || 1, Validators.required],
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.rolForm.valid) {
      const formData = this.rolForm.value;

      if (this.isEdit) {
        this.moduloConfiguracionService.apiUpdateRol(this.data.rol.id, formData).subscribe((response: any) => {
          this.dialogRef.close(true);
        });
      } else {
        this.moduloConfiguracionService.apiCreateRol(formData).subscribe((response: any) => {
          this.dialogRef.close(true);
        });
      }
    }
  }
}
