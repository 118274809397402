import { Component } from '@angular/core';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { Router } from '@angular/router';
import { ConfProgramaCurricularInterface } from '../../../interfaces/conf-programa-curricular.interface';

@Component({
  selector: 'app-conf-programa-curricular-list',
  templateUrl: './conf-programa-curricular-list.component.html',
  styleUrl: './conf-programa-curricular-list.component.css'
})
export class ConfProgramaCurricularListComponent {

  listProgramasCurriculares: ConfProgramaCurricularInterface[] = [];

  constructor(
    private moduloConfiguracionService: ModuloConfiguracionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getProgramasCurriculares();
  }

  getProgramasCurriculares(){
    this.moduloConfiguracionService.apiGetProgramasCurriculares()
      .subscribe((data: ConfProgramaCurricularInterface[]) => {
        this.listProgramasCurriculares = data;
      });
  }





  onProgramaClick(programa: any): void {
    this.router.navigate([`/modulos/configuracion/programas-curriculares/${programa.id}`]);
  }


}
