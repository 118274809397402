import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatEstudiantesMatriculaService } from '../../../services/estudiantes/matricula/mat-estudiantes-matricula.service';

@Component({
  selector: 'app-matri-estudiantes-list',
  templateUrl: './matri-estudiantes-list.component.html',
  styleUrl: './matri-estudiantes-list.component.css'
})

export class MatriEstudiantesListComponent {

  isLoading     : boolean = false;

  searchTerm    : string = '';

  pageList  : Usuario[] = [];
  pageTotal : number = 0;
  pageSize  : number = 5;
  pageIndex : number = 0;

  menuItems = [
    { icon: 'assignment', action: 'enroll', label: 'Matricular a Grupo'},
  ];

  constructor (
    public dialog   : MatDialog,
    private router  : Router,
    private matEstudiantesMatriculaService  : MatEstudiantesMatriculaService,
  ) { }

  ngOnInit(){
    this.getEstudiantes();
  }

  getEstudiantes(): void {
    const params = {
      search_term : this.searchTerm,
      pageSize    : this.pageSize,
      pageIndex   : this.pageIndex,
    };
    this.isLoading = true;
    this.matEstudiantesMatriculaService.apiGetEstudiantes( params )
    .subscribe((response: any) => {
        this.isLoading = false;
        this.pageList   = response.data.estudiantes;
        this.pageTotal  = response.data.total;
      }
    );

  }

  onMenuAction(action: string, usuario: Usuario) {
    switch (action) {
      case 'enroll':
        this.openProcesoMatricula(usuario.id);
        break;
    }
  }

  pageEvent(event: PageEvent) {
    this.pageSize   = event.pageSize;
    this.pageIndex  = event.pageIndex;
    this.getEstudiantes();
  }




  openNuevoEstudiante(): void {
    this.router.navigate(['/modulos/matricula/estudiantes/matricula/nuevo']);
  }

  openProcesoMatricula(id_estudiante: number): void {
    this.router.navigate([`/modulos/matricula/estudiantes/matricula/${id_estudiante}`],{
      state: { xId_estudiante: id_estudiante }
    });
  }

}


export interface Usuario {
  id                  : number;
  name                : string;
  name_2              : string;
  lastname_1          : string;
  lastname_2          : string;
  email               : string;
  usuario             : string;
  documento_identidad : string;
  tipo_documento      : number;
  domicilio           : string;
  celular             : null;
  nombre_completo     : string;
}
