import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';

@Component({
  selector: 'app-conf-usuarios-create',
  templateUrl: './conf-usuarios-create.component.html',
  styleUrls: ['./conf-usuarios-create.component.css']
})
export class ConfUsuariosCreateComponent {
  usuarioForm: FormGroup;
  isEdit: boolean = false;
  tiposDocumento: any[] = [
    { id: 1, nombre: 'DNI' },
    { id: 2, nombre: 'Pasaporte' },
  ];

  constructor(
    public dialogRef: MatDialogRef<ConfUsuariosCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private moduloConfiguracionService: ModuloConfiguracionService
  ) {
    this.isEdit = data && data.usuario ? true : false;

    this.usuarioForm = this.fb.group({
      name: [data?.usuario?.name || '', Validators.required],
      name_2: [data?.usuario?.name_2 || ''],
      lastname_1: [data?.usuario?.lastname_1 || '', Validators.required],
      lastname_2: [data?.usuario?.lastname_2 || '', Validators.required],
      email: [data?.usuario?.email || '', [Validators.required, Validators.email]],
      password: ['', this.isEdit ? [] : Validators.required],  // Solo para crear
      usuario: [data?.usuario?.usuario || '', Validators.required],
      documento_identidad: [data?.usuario?.documento_identidad || '', Validators.required],
      tipo_documento: [data?.usuario?.tipo_documento || '', Validators.required],
      domicilio: [data?.usuario?.domicilio || '', Validators.required],
      celular: [data?.usuario?.celular || '', Validators.required],
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.usuarioForm.valid) {
      const formData = this.usuarioForm.value;

      if (this.isEdit) {
        console.log('IS EDIT: ', this.isEdit);
        this.moduloConfiguracionService.apiUpdateUsuario(this.data.usuario.id, formData).subscribe((response: any) => {
          console.log(response);
          this.dialogRef.close(true);
        });
      } else {
        console.log('IS EDIT: ', this.isEdit);
        this.moduloConfiguracionService.apiCreateUsuario(formData).subscribe((response: any) => {
          console.log(response);
          this.dialogRef.close(true);
        });
      }
    }
  }
}
