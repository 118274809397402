import { Component } from '@angular/core';

@Component({
  selector: 'sv-no-results-found',
  templateUrl: './sv-no-results-found.component.html',
  styleUrl: './sv-no-results-found.component.css'
})
export class SvNoResultsFoundComponent {

}
