import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ModuloMatriculaService } from '../../modulo-matricula.service';
import { MatDialog } from '@angular/material/dialog';
import { MatriProcesoResumenMatriculaComponent } from '../matri-proceso-resumen-matricula/matri-proceso-resumen-matricula.component';
import { SharedComponentsService } from 'src/app/shared-components/shared-components.service';
import { FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-matri-proceso-main-paso3',
  templateUrl: './matri-proceso-main-paso3.component.html',
  styleUrls: ['./matri-proceso-main-paso3.component.css']
})
export class MatriProcesoMainPaso3Component implements OnInit {

  @Input() xMatriculaForm!: FormGroup;
  @Input() xIdGrupoSeleccionado: number | null = null;
  @Input() xIdEstudiante: number = 0;

  @ViewChild('printSection_BoletaA4', { static: false }) printSection_BoletaA4!: ElementRef;
  @ViewChild('printSection_BoletaTicketera', { static: false }) printSection_BoletaTicketera!: ElementRef;

  isLoading: boolean = true;

  xDepositoForm!: FormGroup;

  costos: any[] = [];
  displayedColumns: string[] = ['select_costo', 'nombre', 'tipo', 'monto_inicial', 'monto_final', 'fecha_pago_inicial', 'fecha_pago_final'];

  saldoActual: number = 0;
  totalAPagar: number = 0;
  selectedCosts: any[] = [];

  mostrarBotonImprimirComprobante: boolean = false;
  mostrarBotonContrato: boolean = false;

  constructor(
    private sharedComponentsService: SharedComponentsService,
    private moduloMatriculaService: ModuloMatriculaService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (this.xIdGrupoSeleccionado) {
      this.getPagoDeMatricula();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['xIdGrupoSeleccionado'] && this.xIdGrupoSeleccionado) {
      this.getPagoDeMatricula();
    }
  }

  getPagoDeMatricula(): void {
    const params = {
      id_grupo: this.xIdGrupoSeleccionado,
      id_estudiante: this.xIdEstudiante,
    };
    if (this.xIdGrupoSeleccionado) {
      this.isLoading = true;
      this.moduloMatriculaService.apiGetPagoMatricula(params).subscribe((response: any) => {
        this.costos = response.costos.map((costo: any) => ({
          ...costo,
          monto_inicial: costo.monto,
          monto_final: costo.monto,
          fecha_pago_inicial: new Date(costo.fecha_pago),
          fecha_pago_final: new Date(costo.fecha_pago),
          selected: costo.tipo === 1 // Seleccionar costos de tipo 1 (matricula/inscripcion) -> Si o si
        }));
        this.saldoActual = response.saldo;
        this.selectedCosts = this.costos.filter(costo => costo.selected);
        this.updateTotalAPagar();

        this.xMatriculaForm.get('lista_costos')?.setValue(this.selectedCosts);

        this.isLoading = false;

      });
    }
  }


  isChecked(variable: any) {
    return variable == 1 ? true : false;
  }


  onCostSelectionChange(element: any): void {
    if (element.selected && element.tipo === 2) {
      this.selectedCosts.push(element);
    } else if (!element.selected && element.tipo === 2) {
      this.selectedCosts = this.selectedCosts.filter(cost => cost.id !== element.id);
    }
    this.updateTotalAPagar();
    this.xMatriculaForm.get('lista_costos')?.setValue(this.selectedCosts);
  }














  onMontoFinalChange(): void {
    this.updateTotalAPagar();
  }

  updateTotalAPagar(): void {
    this.totalAPagar = this.selectedCosts.reduce((total, cost) => total + (cost.monto_final || cost.monto_inicial), 0);
  }

  onDepositoFormChanged(form: FormGroup): void {
    if(this.xIdEstudiante == 0 ){
      this.xDepositoForm = form;
      this.xMatriculaForm.setControl('deposito', form);
    }
  }
  onDepositoRealizadoEvent(value: boolean): void {
    if(value){
      const params = { id_estudiante: this.xIdEstudiante };
      this.sharedComponentsService.apiGetSaldoEstudiante(params).subscribe((response: any) => {
        if(response) {
          console.log('response.data: ', response);
          console.log('response.data.saldo: ', response.saldo);
          this.saldoActual = response.saldo;
        }
      });
    }
  }

  isMontoValido(): boolean {
    if (this.xIdEstudiante) {
      return this.saldoActual >= this.totalAPagar;
    } else {
      return this.xDepositoForm?.valid && this.xDepositoForm?.get('monto')?.value >= this.totalAPagar;

    }
  }

  isMatriculaFormValid(): boolean {
    return this.xMatriculaForm.valid && this.isMontoValido();
  }

  // previsualizarMatricula(tipo_comprobante: number): void {
  //   if (this.totalAPagar <= this.saldoActual) {
  //     const dialogRef = this.dialog.open(MatriProcesoResumenMatriculaComponent, {
  //       width: '600px',
  //       data: {
  //         // saldoActual: this.saldoActual,
  //         // totalAPagar: this.totalAPagar,
  //         // costos: this.costos,
  //         // selectedCosts: this.selectedCosts,
  //         // id_estudiante: this.xIdEstudiante,
  //         // selectedGrupoId: this.xIdGrupoSeleccionado,
  //         // tipo_comprobante: tipo_comprobante
  //         xMatriculaForm: this.xMatriculaForm.value,
  //       }
  //     });

  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result) {
  //         console.log('Matrícula confirmada');
  //       }
  //     });
  //   }
  // }

  previsualizarMatricula(tipo_comprobante: number): void {
    if (this.isMatriculaFormValid()) {
      const dialogRef = this.dialog.open(MatriProcesoResumenMatriculaComponent, {
        width: '75%',
        data: {
          matriculaForm: this.xMatriculaForm.value,
          tipo_comprobante: tipo_comprobante
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.confirmarMatricula();
        }
      });
    }
  }

  confirmarMatricula(): void {
    const matriculaData = this.xMatriculaForm.value;

    matriculaData.lista_costos = this.costos.map((cost: any) => ({
      ...cost,
      fecha_pago_inicial: cost.fecha_pago_inicial ? formatDate(cost.fecha_pago_inicial, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
      fecha_pago_final: cost.fecha_pago_final ? formatDate(cost.fecha_pago_final, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
    }));

    matriculaData.selected_costos = matriculaData.lista_costos.filter((cost: any) => cost.selected);


    this.moduloMatriculaService.apiConfirmarMatricula(matriculaData).subscribe((response: any) => {
        if (response.success) {
          this.mostrarOpcionesImprimir();
        }
      }
    );
  }

  mostrarOpcionesImprimir(): void {
    if (this.xIdEstudiante === 0) {
      // Estudiante nuevo, mostrar opciones de imprimir comprobante y contrato
      this.mostrarBotonImprimirComprobante = true;
      this.mostrarBotonContrato = true;
    } else {
      // Estudiante existente, solo mostrar contrato
      this.mostrarBotonContrato = true;
    }
  }






  generarComprobanteBoletaA4() {
    if (!this.printSection_BoletaA4) {
      console.error('printSection is not available when generating comprobante!');
      return;
    }
    const printContents = this.printSection_BoletaA4.nativeElement.innerHTML;
    this.sharedComponentsService.printBoletaDeVentaA4(printContents);
  }

  generarComprobanteBoletaTicketera() {
    if (!this.printSection_BoletaTicketera) {
      console.error('printSection is not available when generating comprobante!');
      return;
    }
    const printContents = this.printSection_BoletaTicketera.nativeElement.innerHTML;
    this.sharedComponentsService.printBoletaDeVentaTicketera(printContents);
  }


}
