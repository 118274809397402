import { ApplicationRef, ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedComponentsService {

  private viewContainerRef: ViewContainerRef | null = null;

  apiUrl = environment.apiUrl;

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    private http: HttpClient,

  ) {}

  // apiGetModulosMenu = (params: any)=> this.http.get(this.apiUrl+'/configuration/modulos-home-user', params);
  // apiGetModulosMenu = ()=> this.http.get(this.apiUrl+'/configuration/modulos-home-user').pipe(map((r: any) => r.data));

  apiGetSaldoEstudiante   = (params?: any)    =>  this.http.get   (this.apiUrl + '/sv-shared/get-saldo-estudiante' , {params}) .pipe(map((r: any) => r.data));
  apiDepositarCuentaEstudiante = (data: any)  =>  this.http.post  (this.apiUrl + '/sv-shared/depositar-cuenta-estudiante'  , data);

  /////////////////////       PDFs       /////////////////////

  setViewContainerRef(vcr: ViewContainerRef) {
    this.viewContainerRef = vcr;
  }




  printBoletaDeVentaTicketera(content: string) {
    const printFrame = document.createElement('iframe');
    printFrame.style.position = 'absolute';
    printFrame.style.width = '0px';
    printFrame.style.height = '0px';
    printFrame.style.border = 'none';

    document.body.appendChild(printFrame);

    const printDocument = printFrame.contentDocument || printFrame.contentWindow?.document;
    if (printDocument) {
        printDocument.open();
        printDocument.write(content);
        printDocument.close();

        printFrame.contentWindow?.focus();
        printFrame.contentWindow?.print();
    }

    setTimeout(() => {
        document.body.removeChild(printFrame);
    }, 1000);
  }



}
