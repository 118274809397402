<app-sv-page-content-header
  titulo="Buscar a Estudiante">
</app-sv-page-content-header>

<div class="page-content">

  <div class="page-content-header-search">
    <div class="search-container">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Buscar estudiante</mat-label>
        <input matInput placeholder="Buscar estudiante" (keydown.enter)="applyFilter($event)" class="search-input" />
      </mat-form-field>
      <button mat-icon-button aria-label="Buscar" (click)="applyFilter($event)">
        <mat-icon>search</mat-icon>
      </button>

    </div>
  </div>

  <div class="page-content-body h-100">

    <div class="w-100 d-flex" style="justify-content: space-between;">
      <div style="font-size: 24px; font-weight: bold; display: flex; display: flex; vertical-align: middle; align-items: center; margin-bottom: 1rem; margin-top: -1rem;">Resultados</div>
    </div>

    <div class="card-list-container">
      <mat-card *ngFor="let usuario of listaUsuarios" class="custom-mat-card" appearance="outlined">
        <mat-card-header>
          <div class="header-content">
            <div class="header-text">
              <mat-card-title>{{usuario.nombres}}</mat-card-title>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Icon">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="goToEstudianteEstadoDeCuenta(usuario.id)">
                <mat-icon>account_balance</mat-icon>
                <span>Ver Estado de Cuenta</span>
              </button>
              <button mat-menu-item (click)="goToMensualidadesEstudiante(usuario.id)">
                <mat-icon>payments</mat-icon>
                <span>Mensualidades</span>
              </button>
            </mat-menu>
          </div>
        </mat-card-header>
        <mat-card-content>
          <p>{{usuario.documento_identidad}}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>



  <mat-paginator [length]="totalUsuarios" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="pageEvent($event)">
  </mat-paginator>

</div>
