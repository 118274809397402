<body>
  <div class="img-container">

  </div>
  <div class="login-container">
    <img src="../../assets/LOGO.webp" alt="">
    <h6>Por favor, identifíquese</h6>
    <form>
      <div *ngFor="let field of formLogin" class="form-group">
        <label class="label" [for]="field.name"></label>
        <input class="input" [type]="field.type" [name]="field.name" [placeholder]="field.placeholder"
               [(ngModel)]="formDataLogin[field.name]">
      </div>

      <!-- <button type="submit" (click)="clickconsole()">INGRESAR</button> -->
      <button type="submit" (click)="clickLogin()">Ingresar</button>
    </form>
    <div class="signup">
      <p>¿No tienes una cuenta? <a (click)="ToRegistro()">Registrarse</a></p>
    </div>
  </div>
</body>
