import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfUsuariosListComponent } from './components/usuario/conf-usuarios-list/conf-usuarios-list.component';
import { ConfUsuariosMainComponent } from './conf-usuarios-main.component';
import { ConfRolesListComponent } from './components/rol/conf-roles-list/conf-roles-list.component';
import { ConfModulosListComponent } from './components/modulo/conf-modulos-list/conf-modulos-list.component';
import { ConfProgramaCurricularListComponent } from './components/programa-curricular/conf-programa-curricular-list/conf-programa-curricular-list.component';
import { ConfProgramaCurricularAreasListComponent } from './components/programa-curricular/conf-programa-curricular-areas-list/conf-programa-curricular-areas-list.component';
import { ConfProgramaCurricularCompetenciasListComponent } from './components/programa-curricular/conf-programa-curricular-competencias-list/conf-programa-curricular-competencias-list.component';
import { ConfSoporteSecretariasListComponent } from './components/soporte/conf-soporte-secretarias-list/conf-soporte-secretarias-list.component';
import { ConfSoporteSecuenciasListComponent } from './components/soporte/conf-soporte-secuencias-list/conf-soporte-secuencias-list.component';

const routes: Routes = [{
  path: '',
  component: ConfUsuariosMainComponent,
  children: [
    {
      path: 'usuarios',
      component: ConfUsuariosListComponent,
    },
    {
      path: 'roles',
      component: ConfRolesListComponent,
    },
    {
      path: 'modulos',
      component: ConfModulosListComponent,
    },
    {
      path: 'programas-curriculares',
      component: ConfProgramaCurricularListComponent,
    },
    {
      path: 'programas-curriculares/:id_programa',
      component: ConfProgramaCurricularAreasListComponent,
    },
    {
      path: 'programas-curriculares/:id_programa/:id_area',
      component: ConfProgramaCurricularCompetenciasListComponent,
    },
    {
      path: 'soporte',
      children: [
        {
          path: 'secretarias',
          component: ConfSoporteSecretariasListComponent,
        },
        {
          path: 'secuencias',
          component: ConfSoporteSecuenciasListComponent,
        },
        {
          path: '',
          redirectTo: 'secretarias',
          pathMatch: 'full'
        },
      ]
    },

    {
      path: '',
      redirectTo: 'usuarios',
      pathMatch: 'full'
    },

    // Version anterior
    // {
    //   path: 'v1/:idMap',
    //   component: MapObjetiveComponent
    // },
    // {
    //   path: ':idMap',
    //   component: MapSettingComponent,
    //   canActivate: [MapSettingGuard]
    // }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModuloConfiguracionRoutingModule { }
