<ng-container *ngIf="!isLoading; else loadingBus">

  <h3>Costos de Matrícula</h3>
  <table mat-table [dataSource]="costos" class="mat-elevation-z0">

    <ng-container matColumnDef="select_costo">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          [(ngModel)]="element.selected"
          [checked]="isChecked(element.tipo)"
          [disabled]="isChecked(element.tipo)"
          (change)="onCostSelectionChange(element)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef colspan="3"> Nombre </th>
      <td mat-cell *matCellDef="let element" colspan="3"> {{element.nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef> Tipo </th>
      <td mat-cell *matCellDef="let element">
        {{element.tipo == 1 ? 'Matrícula' : element.tipo == 2 ? 'Mensualidad' : '-'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="monto_inicial">
      <th mat-header-cell *matHeaderCellDef> Monto Inicial </th>
      <td mat-cell *matCellDef="let element"> S/ {{element.monto_inicial}} </td>
    </ng-container>

    <ng-container matColumnDef="monto_final">
      <th mat-header-cell *matHeaderCellDef> Monto Final </th>
      <td mat-cell *matCellDef="let element" class="mat-cell">
        <mat-form-field style="width: 100%; height: 60px;" subscriptSizing="dynamic">
          <span class="input-prefix" matTextPrefix>S/&nbsp;</span>
          <input matInput type="number" [(ngModel)]="element.monto_final" (ngModelChange)="onMontoFinalChange()">
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="fecha_pago_inicial">
      <th mat-header-cell *matHeaderCellDef> Fecha de Pago Inicial </th>
      <td mat-cell *matCellDef="let element">
        {{element.fecha_pago_inicial | date: 'dd/MM/yyyy'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="fecha_pago_final">
      <th mat-header-cell *matHeaderCellDef> Fecha de Pago Final </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field style="width: 100%; height: 60px;" subscriptSizing="dynamic">
          <input matInput [matDatepicker]="picker" [(ngModel)]="element.fecha_pago_final" class="mat-input-element">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <div class="summary mt-3">

    <sv-deposito-card-estudiante
      [xIdEstudiante] ="xIdEstudiante"
      (depositoFormValueChanged)="onDepositoFormChanged($event)"
      (depositoRealizadoEvent)="onDepositoRealizadoEvent($event)">
    </sv-deposito-card-estudiante>

    <div class="row justify-content-between">
      <div class="col mb-3">
        <div class="info-box rounded-box total-box">
          <p><strong>Total a Pagar:</strong> S/ {{ totalAPagar }}</p>
        </div>
      </div>

      <ng-container *ngIf="xIdEstudiante != 0">
        <div class="col mb-3">
          <div class="info-box rounded-box saldo-box">
            <p><strong>Saldo Actual:</strong> S/ {{ saldoActual }}</p>
          </div>
        </div>

        <div class="col mb-3">
          <div class="info-box rounded-box" [ngClass]="{
            'pendiente-box': totalAPagar > saldoActual,
            'saldo-favor-box': totalAPagar <= saldoActual
          }">
            <p *ngIf="totalAPagar > saldoActual"><strong>Pendiente:</strong> S/ {{ totalAPagar - saldoActual }}</p>
            <p *ngIf="totalAPagar <= saldoActual"><strong>Saldo a favor:</strong> S/ {{ saldoActual - totalAPagar }}</p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="xIdEstudiante == 0">
        <div class="col mb-3">
          <div class="info-box rounded-box saldo-box">
            <p><strong>Monto Ingresado:</strong> S/ {{ xDepositoForm.get('monto')?.value || 0 }}</p>
          </div>
        </div>

        <div class="col mb-3">
          <div class="info-box rounded-box" [ngClass]="{
            'pendiente-box': xDepositoForm.get('monto')?.value < totalAPagar,
            'saldo-favor-box': xDepositoForm.get('monto')?.value >= totalAPagar
          }">
            <p *ngIf="xDepositoForm?.get('monto')?.value < totalAPagar">
              <strong>Pendiente:</strong> S/ {{ totalAPagar - xDepositoForm.get('monto')?.value }}
            </p>
            <p *ngIf="xDepositoForm?.get('monto')?.value >= totalAPagar">
              <strong>Saldo a favor:</strong> S/ {{ xDepositoForm.get('monto')?.value - totalAPagar }}
            </p>
          </div>
        </div>
      </ng-container>
    </div>



    <!-- <button *ngIf="!mostrarBotonContrato" mat-raised-button color="primary" [disabled]="!isMatriculaFormValid()" (click)="previsualizarMatricula(6)">Previsualizar Matrícula</button> -->
    <button mat-raised-button color="primary" [disabled]="!isMatriculaFormValid()" (click)="previsualizarMatricula(6)">Previsualizar Matrícula</button>








    <!-- <button mat-raised-button color="primary" [disabled]="totalAPagar > saldoActual" (click)="previsualizarMatricula(6)">Matricular Con Boleta</button>
    <button mat-raised-button color="primary" [disabled]="totalAPagar > saldoActual" (click)="previsualizarMatricula(9)">Matricular Con Ticket</button>

    <button mat-raised-button color="secondary" (click)="generarComprobanteBoletaA4()">Boleta de Venta A4</button>
    <button mat-raised-button color="secondary" (click)="generarComprobanteBoletaTicketera()">Boleta de Venta Ticketera</button> -->

    <button *ngIf="mostrarBotonImprimirComprobante" mat-raised-button color="primary" (click)="generarComprobanteBoletaA4()">
      Imprimir Comprobante
    </button>
    <button *ngIf="mostrarBotonContrato" mat-raised-button color="primary" (click)="generarComprobanteBoletaTicketera()">
      Mostrar Contrato
    </button>


    <!-- SOLO PARA PRUEBAS -->

    <button mat-raised-button color="primary" (click)="generarComprobanteBoletaA4()">
      Imprimir Comprobante
    </button>

    <button mat-raised-button color="primary" (click)="generarComprobanteBoletaTicketera()">
      Mostrar Contrato
    </button>

  </div>


</ng-container>



<ng-template #loadingBus>
  <sv-loading-bus></sv-loading-bus>
</ng-template>



<div #printSection_BoletaA4 style="display: none;">
  <app-sv-pdf-invoice-a4 [title]="'Boleta de Venta'" [content]="'Contenido de la boleta...'"></app-sv-pdf-invoice-a4>
</div>
<div #printSection_BoletaTicketera style="display: none;">
  <app-sv-pdf-ticket-deposito [title]="'Boleta de Venta'" [content]="'Contenido de la boleta...'"></app-sv-pdf-ticket-deposito>
</div>

