import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-conf-rol-assign-usuario',
  templateUrl: './conf-rol-assign-usuario.component.html',
  styleUrls: ['./conf-rol-assign-usuario.component.css']
})
export class ConfRolAssignUsuarioComponent implements OnInit {
  rol: any;
  usersWithRole: any[] = [];
  searchResults: any[] = [];
  searchControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<ConfRolAssignUsuarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private moduloConfiguracionService: ModuloConfiguracionService
  ) {}

  ngOnInit(): void {
    this.rol = this.data.rol;
    this.getUsersWithRole();
    this.searchControl.valueChanges.subscribe(searchTerm => {
      this.searchUsers(searchTerm);
    });
  }

  getUsersWithRole(): void {
    this.moduloConfiguracionService.apiGetUsersWithRole(this.rol.id).subscribe((response: any) => {
      this.usersWithRole = response.data;
    });
  }

  searchUsers(searchTerm: string): void {
    this.moduloConfiguracionService.apiSearchUsersNoRole(searchTerm, this.rol.id).subscribe((response: any) => {
      this.searchResults = response.data;
    });
  }

  assignUser(userId: number): void {
    this.moduloConfiguracionService.apiAssignUserToRole(userId, this.rol.id).subscribe(() => {
      this.getUsersWithRole();
    });
  }

  removeUser(userId: number): void {
    this.moduloConfiguracionService.apiRemoveUserFromRole(userId, this.rol.id).subscribe(() => {
      this.getUsersWithRole();
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
