<app-sv-page-content-header
  [titulo]="estudiante ? 'Contratos de ' +  (estudiante.name ?? '') + (estudiante.documento_identidad ?? '') : ''">
</app-sv-page-content-header>

<div class="page-content">

  <div class="page-content-body">

    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="h4">Lista de Contratos</div>
      <button class="custom-action-button primary wpx200 m-0" type="button" (click)="openDialogDepositar()">
        Depositar
      </button>
    </div>

    <div *ngIf="!isLoading" class="card-list-container">
      <ng-container *ngIf="listContratos.length; else emptyList">

        <mat-card *ngFor="let contrato of listContratos" class="custom-mat-card" [ngClass]="contrato.estado ? 'golden' : ''">
          <mat-card-header>
            <div class="header-content">
              <div class="header-text">
                <mat-card-title>{{contrato.codigo}}</mat-card-title>
              </div>
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menú">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openDialogPagos(contrato)">
                  <mat-icon>receipt_long</mat-icon>
                  <span>Pagos</span>
                </button>
              </mat-menu>
            </div>
          </mat-card-header>
          <mat-card-content>
            <b>Nivel:</b> {{contrato.nivel_nombre}}
            <br>
            <b>Grado:</b> {{contrato.grado_nombre ?? 'Todos'}}
            <br>
            <b>Grupo:</b> {{contrato.grupo_codigo}}
            <br>
            <b>Plan:</b> {{contrato.plan_tipo_nombre}}
            <br>
            <b>Periodo:</b> {{contrato.periodo_nombre}}
            <br>
            <b>Activo:</b> {{contrato.estado ? 'Sí' : 'No'}}
            <br>

          </mat-card-content>
        </mat-card>

      </ng-container>
      <ng-template #emptyList>

        <div style="font-weight: bold; font-style: italic; height: 10rem; display: flex; align-items: center; justify-content: center;">
          El estudiante no tiene contratos
        </div>

      </ng-template>


    </div>

  </div>
</div>
