<h2 mat-dialog-title>{{ isEdit ? 'Editar Módulo' : 'Crear Módulo' }}</h2>
<mat-dialog-content>
  isEdit: {{isEdit}}
  <form [formGroup]="moduloForm" (ngSubmit)="onSubmit()">
    <div class="form-row mt-2">
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>URL</mat-label>
        <input matInput formControlName="url" required>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Activo</mat-label>
        <mat-select formControlName="activo" required>
          <mat-option [value]="1">Sí</mat-option>
          <mat-option [value]="0">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-dialog-actions align="end">
      <button mat-button type="button" (click)="onClose()">Cancelar</button>
      <button mat-button color="primary" type="submit">{{ isEdit ? 'Guardar Cambios' : 'Crear' }}</button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
