import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-matricula-proceso-main',
  templateUrl: './matricula-proceso-main.component.html',
  styleUrl: './matricula-proceso-main.component.css'
})
export class MatriculaProcesoMainComponent {

  @ViewChild('stepper') stepper!: MatStepper;

  xIdEstudiante: number = 0;
  matriculaForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.matriculaForm = this.fb.group({
      personas: this.fb.array([]),
      id_grupo: [''],
      lista_costos: [''],
      deposito: [''],
    });
  }

  loadRouteParams(){
    this.activatedRoute.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id && !isNaN(+id)) {
        this.xIdEstudiante = +id;
      }else if( id !== 'nuevo' ) {
        this.router.navigate(['/modulos/matricula/estudiantes']);
      }
    });
  }

  ngOnInit(){
    this.loadRouteParams();
  }

  onStepChange(event: StepperSelectionEvent): void { }

  onStep2GrupoSelected(){
    this.stepper.linear = false;
    this.stepper.next();
    this.stepper.linear = true;
  }



  get personas(): FormArray {
    return this.matriculaForm.get('personas') as FormArray;
  }
  get id_grupo() {
    return this.matriculaForm.get('id_grupo')?.value;
  }

  getPersonaForm(relacion: string): FormGroup | null {
    const persona = this.personas.controls.find(
      (form: AbstractControl) => form.get('relacion')?.value === relacion
    );
    return persona as FormGroup || null;
  }


}
