<app-sv-page-content-header
  titulo="Matricular a Estudiante">
</app-sv-page-content-header>

<div class="page-content">

  <div class="page-content-header-search">
    <div class="w-100 d-flex" style="justify-content: space-between; height: 40px;">
      <div style="font-size: 24px; font-weight: bold; display: flex; display: flex; vertical-align: middle; align-items: center;">Lista de estudiantes</div>
      <button class="btn-status btn-primary" (click)="openNuevoEstudiante()">Matricular nuevo estudiante</button>
    </div>
    <div class="search-container">
      <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Buscar estudiante</mat-label>
        <input matInput placeholder="Buscar estudiante" (keydown.enter)="applyFilter($event)" class="search-input" />
      </mat-form-field>
      <button mat-icon-button aria-label="Buscar" (click)="applyFilter($event)">
        <mat-icon>search</mat-icon>
      </button>

    </div>
  </div>

  <div class="card-container">
    <mat-card *ngFor="let usuario of listaUsuarios" class="custom-mat-card">
      <mat-card-header class="px-3 py-2">
        <div class="header-content">
          <div class="header-text">
            <mat-card-title>{{usuario.documento_identidad}}</mat-card-title>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openProcesoMatricula(usuario.id)">
              <mat-icon>assignment</mat-icon>
              <span>Matricular a grupo</span>
            </button>
          </mat-menu>
        </div>
      </mat-card-header>
      <mat-card-content>
        <p>Detalles del usuario {{usuario.nombres}}</p>
      </mat-card-content>
      <!-- <mat-card-actions>
        <button mat-button>Un botón</button>
      </mat-card-actions> -->
    </mat-card>
  </div>

  <mat-paginator [length]="totalUsuarios" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" (page)="pageEvent($event)">
  </mat-paginator>

</div>
