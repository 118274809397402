import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedComponentsService } from '../../shared-components.service';
import { SharedPdfComprobantesService } from 'src/app/shared-services/shared-pdf-comprobantes.service';

@Component({
  selector: 'sv-deposito-card-estudiante',
  templateUrl: './sv-deposito-card-estudiante.component.html',
  styleUrl: './sv-deposito-card-estudiante.component.css'
})
export class SvDepositoCardEstudianteComponent {

  @Input() xIdEstudiante: number | null = null;
  @Input() mostrarIndicacionesMatricula = false;

  @Output() realizarDeposito = new EventEmitter<FormGroup>();
  @Output() depositoFormValueChanged = new EventEmitter<FormGroup>();
  @Output() depositoRealizadoEvent = new EventEmitter<boolean>();

  @ViewChild('printSection_BoletaTicketera', { static: false }) printSection_BoletaTicketera!: ElementRef;
  @ViewChild('printSection_BoletaTicketera_Test', { static: false }) printSection_BoletaTicketera_Test!: ElementRef;

  depositoForm!: FormGroup;
  depositoRealizado: boolean = false;

  responseDeposito: any;

  tiposComprobante: any[] = [
    { codigo: 'TKT', nombre: 'Ticket' },
    { codigo: 'BOL', nombre: 'Boleta' },
    { codigo: 'FAC', nombre: 'Factura' },
  ];
  tiposDeposito: any[] = [
    { codigo: 'EFEC',       nombre: 'Efectivo' },
    { codigo: 'YAPE',       nombre: 'Yape' },
    { codigo: 'PLIN',       nombre: 'Plin' },
    { codigo: 'BANCO-BCP',  nombre: 'Depósito Bancario - BCP' },
  ];

  constructor(
    private fb: FormBuilder,
    private sharedComponentsService: SharedComponentsService,
    private sharedPdfComprobantesService: SharedPdfComprobantesService,
  ) {
    this.depositoForm = this.fb.group({
      monto:            ['', Validators.required],
      tipo_comprobante: ['TKT', Validators.required],
      nro_receptor:     ['', Validators.required],
      tipo_deposito:    ['EFEC', Validators.required],
      nro_operacion:    [''],
      file:             [''],
    });
  }

  ngOnInit(): void {
    this.depositoFormValueChanged.emit(this.depositoForm);
    this.depositoForm.valueChanges.subscribe(() => {
      this.depositoFormValueChanged.emit(this.depositoForm);
    });
    this.depositoForm.get('tipo_deposito')?.valueChanges.subscribe(value => {
      this.toggleNroDepositoField(value);
    });
  }

  toggleNroDepositoField(tipoDeposito: string) {
    const nroOperacionControl = this.depositoForm.get('nro_operacion');
    const fileControl = this.depositoForm.get('file');

    if (tipoDeposito === 'EFEC') {
      nroOperacionControl?.clearValidators();
      nroOperacionControl?.setValue('');
      nroOperacionControl?.disable();

      fileControl?.clearValidators();
      fileControl?.setValue('');
      fileControl?.disable();
    } else {
      nroOperacionControl?.setValidators(Validators.required);
      nroOperacionControl?.enable();

      fileControl?.setValidators(Validators.required);
      fileControl?.enable();
    }
    nroOperacionControl?.updateValueAndValidity();
    fileControl?.updateValueAndValidity();
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.depositoForm.patchValue({
        file: file
      });
      this.depositoForm.get('file')?.updateValueAndValidity();
    } else {
      event.target.value = null;
    }
  }

  onFileEvent(file: File | null) {
    if (file) {
      this.depositoForm.patchValue({ file });
    } else {
      // this.depositoForm.patchValue({ file: null });
      return ;
    }
    this.depositoForm.get('file')?.updateValueAndValidity();
  }

  confirmarDeposito() {
    if (this.depositoForm.valid) {
      this.realizarDeposito.emit(this.depositoForm);
    } else {
      this.depositoForm.markAllAsTouched();
    }
  }




  depositarCuentaEstudiante(){
    // const params = {
    //   id_estudiante: this.xIdEstudiante,
    //   deposito: this.depositoForm.value,
    // };
    // this.sharedComponentsService.apiDepositarCuentaEstudiante(params).subscribe((response: any) => {
    //   if(response.success) {
    //     this.depositoRealizado = true;
    //     this.depositoRealizadoEvent.emit(true);
    //   }
    // });

    const formData = new FormData();

    formData.append('id_estudiante', this.xIdEstudiante!.toString());

    Object.keys(this.depositoForm.controls).forEach(key => {
      const controlValue = this.depositoForm.get(key)?.value;
      formData.append(`deposito[${key}]`, controlValue);
    });

    this.sharedComponentsService.apiDepositarCuentaEstudiante(formData).subscribe((response: any) => {
        if (response.success) {
          this.responseDeposito = response.data;
          this.depositoRealizado = true;
          this.depositoRealizadoEvent.emit(true);
        }
    });

  }

  activarDeposito(){
    this.depositoForm = this.fb.group({
      monto:            ['', Validators.required],
      tipo_comprobante: ['TKT', Validators.required],
      nro_receptor:     ['', Validators.required],
      tipo_deposito:    ['EFEC', Validators.required],
      nro_operacion:    [''],
      file:             [''],
    });
    this.depositoRealizado = false;
  }








  imprimirComprobante(){
    const params = {
      nombre: 'Depósito realizado',
      detalle: 'test detalle',
      responseDeposito: this.responseDeposito,
    };
    this.sharedPdfComprobantesService.imprimirComprobante('ticket', params);
  }

  printBoletaDeVentaTicketeraTest() { }









}
