import { Component } from '@angular/core';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ActivatedRoute } from '@angular/router';
import { ConfProgramaCurricularCompetenciaInterface } from '../../../interfaces/conf-programa-curricular-competencia.interface';
import { MatDialog } from '@angular/material/dialog';
import { ConfProgramaCurricularCompetenciasEditComponent } from '../conf-programa-curricular-competencias-edit/conf-programa-curricular-competencias-edit.component';
import { ConfProgramaCurricularCapacidadesListComponent } from '../conf-programa-curricular-capacidades-list/conf-programa-curricular-capacidades-list.component';
import { ConfProgramaCurricularDesempenyosListComponent } from '../conf-programa-curricular-desempenyos-list/conf-programa-curricular-desempenyos-list.component';

@Component({
  selector: 'app-conf-programa-curricular-competencias-list',
  templateUrl: './conf-programa-curricular-competencias-list.component.html',
  styleUrl: './conf-programa-curricular-competencias-list.component.css'
})
export class ConfProgramaCurricularCompetenciasListComponent {

  xIdAreaCurricular: any;
  listCompetenciasCurriculares: ConfProgramaCurricularCompetenciaInterface[] = [];

  serverNombrePrograma: string = "";
  serverNombreArea: string = "";
  serverIdArea: number = 0;

  displayedColumns: string[] = ['nombre', 'detalle', 'acciones'];
  dataSource: any;

  constructor(
    private moduloConfiguracionService: ModuloConfiguracionService,
    // private router: Router,
    private activatedRoute: ActivatedRoute,

    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.xIdAreaCurricular = Number(params.get('id_area'));
      console.log('xIdAreaCurricular: ', this.xIdAreaCurricular);
      this.getCompetenciasCurriculares();
    });
  }

  getCompetenciasCurriculares(){
    const params =  {id_area_curricular: this.xIdAreaCurricular};
    this.moduloConfiguracionService.apiGetCompetenciasCurriculares(params)
      .subscribe((data: any) => {
        this.listCompetenciasCurriculares = data.competencias;
        this.serverNombrePrograma = data.nombre_programa;
        this.serverNombreArea = data.nombre_area;
        this.serverIdArea = data.id_area;
      });
  }

  openDialogCapacidades(competencia: ConfProgramaCurricularCompetenciaInterface): void {
    const dialogRef = this.dialog.open(ConfProgramaCurricularCapacidadesListComponent, {
      data: {
        competencia: competencia
      },
      minHeight: '50vh',
      minWidth: '75vw',
      maxHeight: '75vh',
      maxWidth: '75vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCompetenciasCurriculares();
      }
    });
  }

  openDialogDesempenyos(competencia: ConfProgramaCurricularCompetenciaInterface): void {
    const dialogRef = this.dialog.open(ConfProgramaCurricularDesempenyosListComponent, {
      data: {
        competencia: competencia
      },
      minHeight: '50vh',
      minWidth: '75vw',
      maxHeight: '75vh',
      maxWidth: '75vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCompetenciasCurriculares();
      }
    });
  }


  openDialogCrearCompetencia(): void {
    const dialogRef = this.dialog.open(ConfProgramaCurricularCompetenciasEditComponent, {
      data: {
        isEdit: false,
        id_area_curricular: this.serverIdArea
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCompetenciasCurriculares();
      }
    });
  }

  openDialogEditarCompetencia(competencia: ConfProgramaCurricularCompetenciaInterface): void {
    const dialogRef = this.dialog.open(ConfProgramaCurricularCompetenciasEditComponent, {
      data: {
        isEdit: true,
        competencia: competencia
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCompetenciasCurriculares();
      }
    });
  }

  openDialogEliminarCompetencia(competencia: ConfProgramaCurricularCompetenciaInterface): void {
    // const dialogRef = this.dialog.open(ConfProgramaCurricularCompetenciasEditComponent, {
    //   data: {
    //     isEdit: true,
    //     competencia: competencia
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.getCompetenciasCurriculares();
    //   }
    // });

    this.moduloConfiguracionService.apiDeleteCompetenciaCurricular(competencia.id).subscribe((response: any) => {
      if (response.success) {
        alert('Competencia eliminada correctamente.');
        this.getCompetenciasCurriculares();
      } else {
        alert('Hubo un problema al eliminar la competencia.');
      }
    });

  }

}

