import { Component } from '@angular/core';

@Component({
  selector: 'sv-loading-bus',
  templateUrl: './sv-loading-bus.component.html',
  styleUrl: './sv-loading-bus.component.css'
})
export class SvLoadingBusComponent {

}
