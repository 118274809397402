import { Component } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-ficha',
  templateUrl: './ficha.component.html',
  styleUrls: ['./ficha.component.css']
})
export class FichaComponent {

  nombre: string = '';
  cantidad: string = 'Seleccionar...';
  numero: string = 'Seleccionar...';
  procedencia: string = '';
  idioma: string = 'Seleccionar...';
  enfermedad: string = 'Seleccionar...';

  agregardispositivo: string = 'Agregar Dispositivos';
  itemsdispositivo: string[] = [];

  addItemDisp() {
    if (this.agregardispositivo.trim() !== '' && !this.itemsdispositivo.includes(this.agregardispositivo)) {
      this.itemsdispositivo.push(this.agregardispositivo);
      this.agregardispositivo = 'Agregar Dispositivos';
    }
  }
  removeItemDisp(item: string = '') {
    const index = this.itemsdispositivo.indexOf(item);
    if (index !== -1) {
      this.itemsdispositivo.splice(index, 1);
    }
  }

  internet: string = 'Seleccionar...';
  instruccion: string ='';
  ocupacion: string = '';
  instruccionpadre: string = '';
  ocupacionpadre: string = '';
  domiciliopadre: string = '';
  religionpadre: string = '';
  distritopadre: string = '';
  barriopadre: string = '';
  viveeducpadre: string = 'Seleccionar...';
  agregaridiomapadre: string = 'Agregar Idioma del Padre';
  itemsidiomapadre: string[] = [];

  addItemPa() {
    if (this.agregaridiomapadre.trim() !== '' && !this.itemsidiomapadre.includes(this.agregaridiomapadre)) {
      this.itemsidiomapadre.push(this.agregaridiomapadre);
      this.agregaridiomapadre = 'Agregar Idioma del Padre';
    }
  }
  removeItemPa(item: string) {
    const index = this.itemsidiomapadre.indexOf(item);
    if (index !== -1) {
      this.itemsidiomapadre.splice(index, 1);
    }
  }

  probpadre: string = 'Seleccionar...';
  instruccionmadre: string = '';
  ocupacionmadre: string = '';
  domiciliomadre: string = '';
  religionmadre: string = '';
  distritomadre: string = '';
  barriomadre: string = '';
  viveeducmadre: string = 'Seleccionar...';
  agregaridiomamadre: string = 'Agregar Idioma de la Madre';
  itemsidiomamadre: string[] = [];

  addItemMa() {
    if (this.agregaridiomamadre.trim() !== '' && !this.itemsidiomamadre.includes(this.agregaridiomamadre)) {
      this.itemsidiomamadre.push(this.agregaridiomamadre);
      this.agregaridiomamadre = 'Agregar Idioma de la Madre';
    }
  }
  removeItemMa(item: string) {
    const index = this.itemsidiomamadre.indexOf(item);
    if (index !== -1) {
      this.itemsidiomamadre.splice(index, 1);
    }
  }
  probmadre: string = 'Seleccionar...';

  generarFICHA() {
    const doc = new jsPDF();

    const logoImg = '../../assets/LOGOTEXT.png';
    doc.addImage(
      logoImg,
      'JPEG',
      15,
      7,
      30,
      10
    );

    autoTable(doc, {
      html: "#tablaestudiante",
      theme: 'grid',
      startY: 20,
      margin: { left: 15, right: 10 },
      styles: { fontSize: 7, minCellHeight: 10, cellPadding: 2, valign: 'middle'},
      columnStyles: {
        0: { cellWidth: 45, halign: 'center' },
        1: { cellWidth: 45, halign: 'center' },
        2: { cellWidth: 45, halign: 'center' },
        3: { cellWidth: 45, halign: 'center' }
      },
      didParseCell: (data) => {
        data.cell.styles.halign = 'center';

        if (data.section === 'head' && data.row.index === 0 && data.column.index === 0) {
          data.cell.styles.fillColor = [0, 255, 255];
          data.cell.styles.textColor = [0, 0, 0];
        }
        const applyBold = (rowIndex: number, columnIndex: number) => {
          if (data.row.index === rowIndex && data.column.index === columnIndex) {
            data.cell.styles.fontStyle = 'bold';
          }
        };
        applyBold(0, 0);
        applyBold(1, 0); applyBold(1, 2);
        applyBold(2, 0);
        applyBold(3, 0); applyBold(3, 2);
        applyBold(4, 0);
        applyBold(5, 0);
      }
    });
    autoTable(doc, {
      html: "#tablapadre",
      theme: 'grid',
      startY: 95,
      margin: { left: 15, right: 10 },
      styles: { fontSize: 7, minCellHeight: 10, cellPadding: 2, valign: 'middle'},
      columnStyles: {
        0: { cellWidth: 45, halign: 'center' },
        1: { cellWidth: 45, halign: 'center' },
        2: { cellWidth: 45, halign: 'center' },
        3: { cellWidth: 45, halign: 'center' }
      },
      didParseCell: (data) => {
        data.cell.styles.halign = 'center';

        if (data.section === 'head' && data.row.index === 0 && data.column.index === 0) {
            data.cell.styles.fillColor = [0, 255, 255];
            data.cell.styles.textColor = [0, 0, 0];
        }
        const applyBold = (rowIndex: number, columnIndex: number) => {
          if (data.row.index === rowIndex && data.column.index === columnIndex) {
            data.cell.styles.fontStyle = 'bold';
          }
        };
        applyBold(0, 0); applyBold(0, 2);
        applyBold(1, 0); applyBold(1, 2);
        applyBold(2, 0); applyBold(2, 2);
        applyBold(3, 0); applyBold(3, 2);
        applyBold(4, 0);
        applyBold(5, 0);
      }
    });

    autoTable(doc, {
      html: "#tablamadre",
      theme: 'grid',
      startY: 167,
      margin: { left: 15, right: 10 },
      styles: { fontSize: 7, minCellHeight: 10, cellPadding: 2, valign: 'middle'},
      columnStyles: {
        0: { cellWidth: 45, halign: 'center' },
        1: { cellWidth: 45, halign: 'center' },
        2: { cellWidth: 45, halign: 'center' },
        3: { cellWidth: 45, halign: 'center' }
      },
      didParseCell: (data) => {
        data.cell.styles.halign = 'center';

        if (data.section === 'head' && data.row.index === 0 && data.column.index === 0) {
          data.cell.styles.fillColor = [0, 255, 255];
          data.cell.styles.textColor = [0, 0, 0];
        }
        const applyBold = (rowIndex: number, columnIndex: number) => {
          if (data.row.index === rowIndex && data.column.index === columnIndex) {
            data.cell.styles.fontStyle = 'bold';
          }
        };
        applyBold(0, 0); applyBold(0, 2);
        applyBold(1, 0); applyBold(1, 2);
        applyBold(2, 0); applyBold(2, 2);
        applyBold(3, 0); applyBold(3, 2);
        applyBold(4, 0);
        applyBold(5, 0);
      }
    });


    const text = `_______________________\nV°B° DIRECCION`;

    const text1 = `_______________________\nPADRE Y/O MADRE`;
    const text2 = `______________________________\nJr. Gonzales Prada N° 433\nJuliaca - Perú`;

    doc.setFontSize(10);
    doc.setFont('bold');
    doc.text(text, 60, 270, { align: 'center'});
    doc.text(text1, 150, 270, { align: 'center' });
    doc.text(text2, 15, 280);

    doc.save('Ficha de Datos.pdf');
  }


}
