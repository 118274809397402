import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { ModuloMatriculaService } from 'src/app/pages/modulo-matricula/modulo-matricula.service';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-matri-proceso-resumen-matricula',
  templateUrl: './matri-proceso-resumen-matricula.component.html',
  styleUrls: ['./matri-proceso-resumen-matricula.component.css']
})
export class MatriProcesoResumenMatriculaComponent {

  matriculaForm    : FormGroup;
  tipo_comprobante : number = 0;
  grupoDetalles    : any;

  esNuevo: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MatriProcesoResumenMatriculaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private moduloMatriculaService: ModuloMatriculaService
  ) {
    this.matriculaForm     = data && data.matriculaForm;
    this.tipo_comprobante  = data && data.tipo_comprobante;
    this.grupoDetalles     = data && data.matriculaForm.get('grupoDetalles').value;

    this.esNuevo = !!this.matriculaForm.get('deposito')?.value;
  }

  ngOnInit(){
    console.log('matriculaForm: ', this.matriculaForm.value);
    console.log('grupoDetalles: ', this.grupoDetalles);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  // Devuelve el array de personas
  getPersonasArray(): any[] {
    return (this.matriculaForm.get('personas') as FormArray).value;
  }

  confirmarMatricula(): void {
    // console.log('this.data: ', this.data);
    // console.log('this.data.selectedCosts: ', this.data.selectedCosts);
    // console.log('this.data.costos: ', this.data.costos);
    // const selectedCostsForBackend = this.data.selectedCosts.map((cost: any) => ({
    //   id                  : cost.id,
    //   nombre              : cost.nombre,
    //   monto_inicial       : cost.monto_inicial,
    //   monto_final         : cost.monto_final,
    //   fecha_pago_inicial  : cost.fecha_pago_inicial ? formatDate(cost.fecha_pago_inicial, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
    //   fecha_pago_final    : cost.fecha_pago_final ? formatDate(cost.fecha_pago_final, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
    //   tipo                : cost.tipo,
    // }));

    // const allCostsForBackend = this.data.costos.map((cost: any) => ({
    //   id                  : cost.id,
    //   nombre              : cost.nombre,
    //   monto_inicial       : cost.monto_inicial,
    //   monto_final         : cost.monto_final,
    //   fecha_pago_inicial  : cost.fecha_pago_inicial ? formatDate(cost.fecha_pago_inicial, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
    //   fecha_pago_final    : cost.fecha_pago_final ? formatDate(cost.fecha_pago_final, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
    //   tipo                : cost.tipo,
    // }));

    // this.moduloMatriculaService.apiRealizarMatricula({
    //   id_estudiante     : this.data.id_estudiante,
    //   id_grupo          : this.data.selectedGrupoId,
    //   selected_costos   : selectedCostsForBackend,
    //   all_costos        : allCostsForBackend,
    //   tipo_comprobante  : this.data.tipo_comprobante
    // }).subscribe(response => {
    //   console.log('Matrícula realizada con éxito', response);
    //   this.dialogRef.close(true);
    // });

    this.dialogRef.close(true);

  }
}
