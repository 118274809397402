<div mat-dialog-title>Asignar Módulos al Rol</div>


<div mat-dialog-content>

  <div class="page-content">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="custom-tree mat-elevation-z0">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <div class="custom-tree-node">
          <mat-checkbox [checked]="isChecked(node)" (change)="onToggle(node)" class="custom-checkbox">
            {{node.nombre}}
          </mat-checkbox>
        </div>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="custom-tree-node">
          <mat-checkbox [checked]="isChecked(node)" (change)="onToggle(node)" class="custom-checkbox">
            {{node.nombre}}
          </mat-checkbox>
          <button mat-icon-button matTreeNodeToggle class="custom-toggle-button">
            <mat-icon>
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
        </div>
        <div [class.tree-invisible]="!treeControl.isExpanded(node)" class="nested-level">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

</div>




<div mat-dialog-actions align="center">
  <button class="custom-action-button transparent" type="button" (click)="onClose()">Cerrar</button>
  <button class="custom-action-button primary" type="submit" (click)="guardarAsignaciones()">Asignar Módulos</button>
</div>

