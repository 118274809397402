import { HttpInterceptor, HttpInterceptorFn } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req);
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem('authToken');

    // if (authToken && !request.url.includes('/login')) {
    // }
    request = request.clone({
      setHeaders: { Authorization: `Bearer ${authToken}` }
    });

    return next.handle(request);
  }
}
