import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModuloAdministracionService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl;


  apiGetSedes    = (params?: any)=> this.http.get(this.apiUrl+'/administration/sede', {params}).pipe(map((r: any) => r.data));

  getNiveles    = (params?: any)=> this.http.get(this.apiUrl+'/administration/nivel', {params}).pipe(map((r: any) => r.data));

  apiGetGrupos    = (params?: any)          => this.http.get    (`${this.apiUrl}/administration/grupo`, {params}).pipe(map((r: any) => r.data));
  apiCreateGrupo  = (data: any)             => this.http.post   (`${this.apiUrl}/administration/grupo`, data);
  apiUpdateGrupo  = (id: number, data: any) => this.http.put    (`${this.apiUrl}/administration/grupo/${id}`, data);
  apiDeleteGrupo  = (id: number)            => this.http.delete (`${this.apiUrl}/administration/grupo/${id}`);


  getTipoPlanes = (params?: any)=> this.http.get(this.apiUrl+'/administration/acad-tipo-plan', {params}).pipe(map((r: any) => r.data));

  apiGetPeriodos      = (params?: any)=> this.http.get(this.apiUrl+'/administration/periodo', {params}).pipe(map((r: any) => r.data));


  apiGetPlanEstudios    = (params?: any)          => this.http.get    (`${this.apiUrl}/administration/plan-estudio`, {params}).pipe(map((r: any) => r.data));
  apiCreatePlanEstudio  = (data: any)             => this.http.post   (`${this.apiUrl}/administration/plan-estudio`, data);
  apiUpdatePlanEstudio  = (id: number, data: any) => this.http.put    (`${this.apiUrl}/administration/plan-estudio/${id}`, data);
  apiDeletePlanEstudio  = (id: number)            => this.http.delete (`${this.apiUrl}/administration/plan-estudio/${id}`);

  apiGetMatriculaPlanCostos    = (params?: any)           => this.http.get    (`${this.apiUrl}/administration/matricula-plan-costo`, {params}).pipe(map((r: any) => r.data));
  apiCreateMatriculaPlanCosto  = (data: any)              => this.http.post   (`${this.apiUrl}/administration/matricula-plan-costo`, data);
  apiUpdateMatriculaPlanCosto  = (id: number, data: any)  => this.http.put    (`${this.apiUrl}/administration/matricula-plan-costo/${id}`, data);
  apiDeleteMatriculaPlanCosto  = (id: number)             => this.http.delete (`${this.apiUrl}/administration/matricula-plan-costo/${id}`);

  apiGetMatriculaCostos     = (params?: any)  => this.http.get    (`${this.apiUrl}/administration/matricula-costo`, {params}).pipe(map((r: any) => r.data));
  apiSaveMatriculaCosto     = (data: any)     => this.http.post   (`${this.apiUrl}/administration/matricula-costo-save/`, data);
  apiDeleteMatriculaCosto   = (id: number)    => this.http.delete (`${this.apiUrl}/administration/matricula-costo/${id}`);

}
