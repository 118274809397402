import { Component } from '@angular/core';
import { ModuloContabilidadService } from '../../modulo-contabilidad.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cont-comprobantes-list',
  templateUrl: './cont-comprobantes-list.component.html',
  styleUrl: './cont-comprobantes-list.component.css'
})
export class ContComprobantesListComponent {

  constructor (
    private moduloContabilidadService: ModuloContabilidadService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(){
    this.getComprobantes();
  }

  listaComprobantes: ComprobanteModel[] = [];
  // displayedColumns: string[] = ['cod_comprobante', 'dni_ruc_receptor', 'xml_firmado', 'sunat_confirmado'];
  displayedColumns: string[] = ['cod_comprobante', 'dni_ruc_receptor', 'sunat_confirmado'];
  dataSource: any;

  getComprobantes(): void {
    this.moduloContabilidadService.apiGetComprobantes()
      .subscribe((data: ComprobanteModel[]) => {
        this.listaComprobantes = data;
        this.dataSource = data;
      });
  }

  firmarXml(element: any): void {
    // Aquí llamas a tu API para firmar el XML
    // this.http.post('URL_DE_TU_API_PARA_FIRMAR_XML', { id: element.id }).subscribe((response: any) => {
    //   if (response.success) {
    //     element.xml_firmado = true;
    //   }
    // });
  }

  enviarASunat(element: any): void {
    // Aquí llamas a tu API para enviar a Sunat
    // this.http.post('URL_DE_TU_API_PARA_ENVIAR_A_SUNAT', { id: element.id }).subscribe((response: any) => {
    //   if (response.success) {
    //     element.sunat_confirmado = true;
    //   }
    // });

    this.moduloContabilidadService.apiEnviarSunat(element.id)
      .subscribe((data: any) => {
        this.getComprobantes();
      });
  }

}

export interface ComprobanteModel {
  id:               number;
  id_transaccion:   number;
  tipo_comprobante: number;
  cod_comprobante:  string;
  dni_ruc_receptor: string;
  xml_firmado:      boolean;
  sunat_confirmado: boolean;
  url_xml_firmado:  string;
  url_cdr:          string;
}
