<app-sv-page-content-header
  titulo="Lista de Áreas">
</app-sv-page-content-header>


<h2 class="text-center mb-4"> Programa: {{ serverNombrePrograma }}</h2>
<button class="btn btn-outline-primary mb-3" (click)="openDialogCrearArea()">
  <i class="fas fa-list-ul me-2"></i>Crear Área
</button>

<table mat-table [dataSource]="listAreasCurriculares" class="mat-elevation-z0">

  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef> Nombre </th>
    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
  </ng-container>

  <ng-container matColumnDef="detalle">
    <th mat-header-cell *matHeaderCellDef> Detalle </th>
    <td mat-cell *matCellDef="let element"> {{element.detalle}} </td>
  </ng-container>

    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element">
        <button class="btn-status-rounded btn-auto-width btn-purple" (click)="goToCompetencias(element.id)">
          <!-- <mat-icon class="btn-icon">add</mat-icon> -->
          Competencias
        </button>
        <button class="btn-status-rounded btn-auto-width btn-info" (click)="openDialogEditarArea(element)">
          <!-- <mat-icon class="btn-icon">add</mat-icon> -->
          Editar
        </button>
        <button class="btn-status-rounded btn-auto-width btn-danger" (click)="openDialogEliminarArea(element)">
          <!-- <mat-icon class="btn-icon">add</mat-icon> -->
          Eliminar
        </button>
      </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
