import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModuloMatriculaService } from '../../../../../modulo-matricula.service';
import { AdministrationSharedService } from 'src/app/shared-services/administration-shared.service';

@Component({
  selector: 'app-matri-proceso-main-paso2',
  templateUrl: './matri-proceso-main-paso2.component.html',
  styleUrl: './matri-proceso-main-paso2.component.css'
})
export class MatriProcesoMainPaso2Component {

  @Input() xMatriculaForm!: FormGroup;
  @Input() xIdGrupoSeleccionado: any;
  @Input() xIdEstudiante: number = 0;

  @Output() onGrupoSeleccionadoEmmiter = new EventEmitter<number>();

  isLoading: boolean = false;

  listSedes:        any[] = [];
  listNiveles:      any[] = [];
  listGrados:       any[] = [];
  listPeriodos:     any[] = [];
  listPlanTipos:    any[] = [];

  selectedSede:     number | string = '';
  selectedNivel:    number | string = '';
  selectedGrado:    number | string = '';
  selectedPeriodo:  number | string = '';
  selectedPlanTipo: number | string = '';

  listPlanesEstudio: any[] = [];

  constructor(
    private fb: FormBuilder,
    private administrationSharedService: AdministrationSharedService,
    private moduloMatriculaService: ModuloMatriculaService
  ) {}

  ngOnInit(): void {
    this.getSedesNivelesGrados();
    this.getPeriodosPlanTipos();
    this.getPlanesEstudio();

    this.xMatriculaForm.addControl('grupoDetalles', new FormControl(null));
  }

  getSedesNivelesGrados() {
    let params = { };
    this.administrationSharedService.apiGetSedesNivelesGrados(params)
    .subscribe((resp: any) => {
      this.listSedes = resp.data;
    });
  }

  getPeriodosPlanTipos() {
    let params = { 'activo': true };
    this.administrationSharedService.apiGetPeriodosPlanTipos(params)
      .subscribe((resp: any) => {
        this.listPeriodos = resp.data;
      });
  }

  getPlanesEstudio() {
    const params = {
      id_estudiante : this.xIdEstudiante,
      id_sede       : this.selectedSede,
      id_nivel      : this.selectedNivel,
      id_plan_tipo  : this.selectedPlanTipo,
      id_periodo    : this.selectedPeriodo,
      id_grado      : this.selectedGrado,
    };

    this.isLoading = true;

    this.moduloMatriculaService.apiGetPlanesEstudio(params).subscribe((data: any[]) => {
      this.listPlanesEstudio = data;
      this.isLoading = false;
    });
  }


  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  onSedeChange() {
    this.selectedNivel = '';
    this.selectedGrado = '';
    this.setNiveles();
    this.getPlanesEstudio();
  }

  onNivelChange() {
    this.selectedGrado = '';
    this.setGrados();
    this.getPlanesEstudio();
  }

  onPeriodoChange() {
    this.selectedPlanTipo = '';
    this.setPlanTipos();
    this.getPlanesEstudio();
  }

  applyFilters() {
    this.getPlanesEstudio();
  }

  onGrupoSeleccionado(id_grupo: number, plan: any) {
    this.xIdGrupoSeleccionado = id_grupo;
    this.xMatriculaForm.get('id_grupo')?.setValue(id_grupo);

    const grupoSeleccionado = plan.grupos.find((grupo: { id: number; }) => grupo.id === id_grupo) || null;

    const grupoDetalles = {
      nombre_sede : this.getNombreSede(plan.id_sede),
      nivel       : this.getNombreNivel(plan.id_sede, plan.id_nivel),
      grado       : this.getNombreGrado(plan.id_sede, plan.id_nivel, plan.id_grado),
      periodo     : this.getNombrePeriodo(plan.id_periodo),
      tipo_plan   : this.getNombrePlanTipo(plan.id_periodo, plan.id_plan_tipo),
      nombre_plan : plan.nombre,
      grupo       : grupoSeleccionado
    };

    this.xMatriculaForm.get('grupoDetalles')?.setValue(grupoDetalles);

    this.onGrupoSeleccionadoEmmiter.emit(id_grupo);
  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  setNiveles() {
    if (this.selectedSede) {
      let sede = this.listSedes.find(n => n.id === this.selectedSede);
      if (sede) {
        this.listNiveles = sede.niveles;
      }
    } else {
      this.listNiveles = [];
    }
    this.listGrados = [];
  }

  setGrados() {
    if (this.selectedNivel) {
      let nivel = this.listNiveles.find(n => n.id === this.selectedNivel);
      if (nivel) {
        this.listGrados = nivel.grados;
      }
    } else {
      this.listGrados = [];
    }
  }

  setPlanTipos() {
    if (this.selectedPeriodo) {
      let periodo = this.listPeriodos.find(n => n.id === this.selectedPeriodo);
      if (periodo) {
        this.listPlanTipos = periodo.plan_tipos;
      }
    } else {
      this.listPlanTipos = [];
    }
  }

  ////////////////////////////////////////////////// SECTOR //////////////////////////////////////////////////

  buildFeaturesArray(planEstudio: any) {
    return [
      { key: 'Sede',    value: this.getNombreSede(planEstudio.id_sede) },
      { key: 'Nivel',   value: this.getNombreNivel(planEstudio.id_sede, planEstudio.id_nivel) },
      { key: 'Grado',   value: this.getNombreGrado(planEstudio.id_sede, planEstudio.id_nivel, planEstudio.id_grado) },
      { key: 'Periodo', value: this.getNombrePeriodo(planEstudio.id_periodo) },
      { key: 'Tipo',    value: this.getNombrePlanTipo(planEstudio.id_periodo, planEstudio.id_plan_tipo) },
    ];
  }

  getNombreById(list: any[], id: number, defaultValue: string = 'Todos'): string {
    const item = list.find(el => el.id === id);
    return item ? item['nombre'] : defaultValue;
  }

  getNombreSede(idSede: number): string {
    return this.getNombreById(this.listSedes, idSede);
  }

  getNombreNivel(idSede: number, idNivel: number): string {
    const sede = this.listSedes.find((s: { id: number; }) => s.id === idSede);
    return sede ? this.getNombreById(sede.niveles, idNivel) : 'Todos';
  }

  getNombreGrado(idSede: number, idNivel: number, idGrado: number): string {
    const sede = this.listSedes.find((s: { id: number; }) => s.id === idSede);
    const nivel = sede?.niveles.find((n: { id: number; }) => n.id === idNivel);
    return nivel ? this.getNombreById(nivel.grados, idGrado) : 'Todos';
  }

  getNombrePeriodo(id: number): string {
    const periodo = this.listPeriodos.find(p => p.id === id);
    return periodo ? periodo.codigo : '';
  }

  getNombrePlanTipo(idPeriodo: number, id: number): string {
    const periodo = this.listPeriodos.find(p => p.id === idPeriodo);
    const planTipo = periodo.plan_tipos.find((tp: { id: number; }) => tp.id === id);
    return planTipo ? planTipo.nombre : '';
  }



}
