import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloMatriculaService } from '../../../../../modulo-matricula.service';
import { SharedPdfComprobantesService } from 'src/app/shared-services/shared-pdf-comprobantes.service';

@Component({
  selector: 'app-matri-proceso-main-paso1',
  templateUrl: './matri-proceso-main-paso1.component.html',
  styleUrl: './matri-proceso-main-paso1.component.css'
})

export class MatriProcesoMainPaso1Component {

  @Input() xMatriculaForm!: FormGroup;
  @Input() xIdEstudiante!: number;

  tiposDocumento: any[] = [
    { id: 1, nombre: 'DNI' },
    { id: 2, nombre: 'Pasaporte' },
  ];




  dataContrato: any;

  constructor(
    private fb: FormBuilder,
    private moduloMatriculaService: ModuloMatriculaService,
        private sharedPdfComprobantesService: SharedPdfComprobantesService,
  ) {}

  ngOnInit() {
    if (this.xIdEstudiante !== 0) {
      this.getPersonas();
    } else {
      this.addPersona(null,'estudiante');
    }

    this.xMatriculaForm.setValidators(this.validacionResponsableYFirmante);

  }

  get personas(): FormArray {
    return this.xMatriculaForm.get('personas') as FormArray;
  }

  getPersonaForm(relacion: string): FormGroup {
    const personasArray = this.xMatriculaForm.get('personas') as FormArray;
    const personaForm = personasArray.controls.find(
      (form: AbstractControl) => form.get('relacion')?.value === relacion
    );
    return personaForm as FormGroup;
  }

  getPersonas() {
    this.moduloMatriculaService.apiGetStudentAndParents(this.xIdEstudiante)
      .subscribe((data: any) => {
        this.addPersona(data.estudiante, 'estudiante');
        if (data.padre) this.addPersona(data.padre, 'padre', data.padre.pivot.es_responsable);
        if (data.madre) this.addPersona(data.madre, 'madre', data.madre.pivot.es_responsable);
        if (data.apoderado) this.addPersona(data.apoderado, 'apoderado', data.apoderado.pivot.es_responsable);
        });
  }

  addPersona(personaData: any, relacion: string, esResponsable: boolean = false) {
    personaData = personaData || {};
    const existingPersona = this.getPersonaForm(relacion);

    if (existingPersona) {
      existingPersona.get('hidden')?.setValue(false);
      existingPersona.enable();
    } else {
      const personaForm = this.fb.group({
        id:                   [personaData.id                  ?? ''],
        name:                 [personaData.name                ?? '', Validators.required],
        lastname_1:           [personaData.lastname_1          ?? '', Validators.required],
        lastname_2:           [personaData.lastname_2          ?? '', Validators.required],
        email:                [personaData.email               ?? '', Validators.email],
        documento_identidad:  [personaData.documento_identidad ?? '', Validators.required],
        tipo_documento:       [personaData.tipo_documento      ?? 1, Validators.required],
        domicilio:            [personaData.domicilio           ?? '', Validators.required],
        celular:              [personaData.celular             ?? '', Validators.required],
        relacion:             [relacion, Validators.required],
        responsable:          [esResponsable],
        firmante:             [false],
        hidden:               [false],
      });

      this.personas.push(personaForm);
    }
  }

  removePersona(relacion: string) {
    const personaForm = this.getPersonaForm(relacion);

    if (personaForm) {
      personaForm.get('hidden')?.setValue(true);
      personaForm.disable();
    }
  }



  seleccionarResponsable(relacion: string) {
    this.personas.controls.forEach((persona) => {
      if (persona.get('relacion')?.value === relacion) {
        persona.get('responsable')?.setValue(true);
      } else {
        persona.get('responsable')?.setValue(false);
      }
    });
  }

  validacionResponsableYFirmante(control: AbstractControl): { [key: string]: any } | null {
    const personas = (control.get('personas') as FormArray).controls;

    const responsableSeleccionado = personas.some(persona => persona.get('responsable')?.value === true);
    const firmanteSeleccionado = personas.some(persona => persona.get('firmante')?.value === true && persona.get('relacion')?.value !== 'estudiante');

    if (!responsableSeleccionado) return { noResponsable: true };
    if (!firmanteSeleccionado) return { noFirmante: true };

    return null;
  }



  imprimirContrato(){
    const params = {
      nombre: 'Contrato realizado',
      detalle: 'test detalle',
      dataContrato: this.dataContrato,
    };
    this.sharedPdfComprobantesService.imprimirContrato(params);
  }


}
