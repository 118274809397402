<div class="membrete">
  <!-- Fila principal: Datos y Logo -->
  <div class="membrete-row">
    <!-- Columna izquierda: Datos del colegio -->
    <div class="membrete-left">
      <div class="membrete-data">
        <div class="school-name-container">
          <h2 class="school-name">Colegio San José Juliaca</h2>
        </div>
        <div class="school-address-container">
          <p class="school-address">Jr. Gonzales Prada 456, Juliaca</p>
        </div>
        <div class="school-contact-container">
          <p class="school-contact">{{"Tel: (123) 456-7890 | info@sjj.com.pe"}}</p>
        </div>
      </div>
    </div>
    <!-- Columna derecha: Logo -->
    <div class="membrete-right">
      <div class="logo-container">
        <img src="/assets/Logo-SJ.png" alt="Logo" class="logo">
      </div>
    </div>
  </div>
  <!-- Título del Contrato -->
  <div class="membrete-title">
    <div class="title-container">
      <h3 class="contract-title">ACUERDO DE PRESTACIÓN DE SERVICIOS EDUCATIVOS</h3>
      <h3 class="contract-title">CONTRATO-1234567</h3>
    </div>
  </div>













  <div class="cards-container">
    <div class="card-person">
      <div class="card-person-header">
        <h4>Información del Estudiante</h4>
      </div>
      <div class="card-person-body">
        <div class="row-data">
          <span class="label">Nombres:</span>
          <span class="value">Juan Carlos</span>
        </div>
        <div class="row-data">
          <span class="label">Apellidos:</span>
          <span class="value">Rodríguez Pérez</span>
        </div>
        <div class="row-data">
          <span class="label">DNI:</span>
          <span class="value">45678912</span>
        </div>
        <div class="row-data">
          <span class="label">Celular:</span>
          <span class="value">987654321</span>
        </div>
        <div class="row-data">
          <span class="label">Domicilio:</span>
          <span class="value">Av. Los Pinos 123</span>
        </div>
      </div>
    </div>

    <div class="card-person">
      <div class="card-person-header">
        <h4>Información del Padre</h4>
      </div>
      <div class="card-person-body">
        <div class="row-data">
          <span class="label">Nombres:</span>
          <span class="value">Carlos Eduardo</span>
        </div>
        <div class="row-data">
          <span class="label">Apellidos:</span>
          <span class="value">Rodríguez Gómez</span>
        </div>
        <div class="row-data">
          <span class="label">DNI:</span>
          <span class="value">12345678</span>
        </div>
        <div class="row-data">
          <span class="label">Celular:</span>
          <span class="value">912345678</span>
        </div>
        <div class="row-data">
          <span class="label">Domicilio:</span>
          <span class="value">Av. Los Pinos 123</span>
        </div>
        <div class="row-data">
          <span class="label">Firma:</span>
          <span class="value">Sí</span>
        </div>
      </div>
    </div>

    <div class="card-person">
      <div class="card-person-header">
        <h4>Información de la Madre</h4>
      </div>
      <div class="card-person-body">
        <div class="row-data">
          <span class="label">Nombres:</span>
          <span class="value">María Fernanda</span>
        </div>
        <div class="row-data">
          <span class="label">Apellidos:</span>
          <span class="value">López García</span>
        </div>
        <div class="row-data">
          <span class="label">DNI:</span>
          <span class="value">87654321</span>
        </div>
        <div class="row-data">
          <span class="label">Celular:</span>
          <span class="value">998877665</span>
        </div>
        <div class="row-data">
          <span class="label">Domicilio:</span>
          <span class="value">Av. Los Pinos 123</span>
        </div>
        <div class="row-data">
          <span class="label">Firma:</span>
          <span class="value">Sí</span>
        </div>
      </div>
    </div>

    <div class="card-person">
      <div class="card-person-header">
        <h4>Información del Apoderado</h4>
      </div>
      <div class="card-person-body">
        <div class="row-data">
          <span class="label">Nombres:</span>
          <span class="value">Ana Isabel</span>
        </div>
        <div class="row-data">
          <span class="label">Apellidos:</span>
          <span class="value">Martínez Solís</span>
        </div>
        <div class="row-data">
          <span class="label">DNI:</span>
          <span class="value">11223344</span>
        </div>
        <div class="row-data">
          <span class="label">Celular:</span>
          <span class="value">911223344</span>
        </div>
        <div class="row-data">
          <span class="label">Domicilio:</span>
          <span class="value">Av. Central 321</span>
        </div>
        <div class="row-data">
          <span class="label">Firma:</span>
          <span class="value">Sí</span>
        </div>
      </div>
    </div>


  </div>














  <div class="category-name">
    Información de Pago
  </div>

  <div class="modern-table">
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Cobro</th>
          <th>Detalle</th>
          <th>Monto</th>
          <th>Fecha de Pago</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Matrícula</td>
          <td>Matrícula Regular</td>
          <td>S/. 100.00</td>
          <td>01/04/2025</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Marzo</td>
          <td>S/. 150.00</td>
          <td>15/04/2025</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Abril</td>
          <td>S/. 200.00</td>
          <td>30/04/2025</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Mayo</td>
          <td>S/. 200.00</td>
          <td>30/05/2025</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Junio</td>
          <td>S/. 200.00</td>
          <td>30/06/2025</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Julio</td>
          <td>S/. 200.00</td>
          <td>30/07/2025</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Agosto</td>
          <td>S/. 200.00</td>
          <td>30/08/2025</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Septiembre</td>
          <td>S/. 200.00</td>
          <td>30/09/2025</td>
        </tr>
        <tr>
          <td>9</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Octubre</td>
          <td>S/. 200.00</td>
          <td>30/10/2025</td>
        </tr>
        <tr>
          <td>10</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Noviembre</td>
          <td>S/. 200.00</td>
          <td>30/11/2025</td>
        </tr>
        <tr>
          <td>11</td>
          <td>Mensualidad</td>
          <td>Mensualidad Regular - Diciembre</td>
          <td>S/. 200.00</td>
          <td>30/12/2025</td>
        </tr>
      </tbody>
    </table>
  </div>











  <!-- <div class="group-details">
    <h3>Matriculado en el Grupo: 5° B - Secundaria</h3>
    <p><strong>Turno:</strong> Mañana</p>
    <p><strong>Sección:</strong> B</p>
    <p><strong>Director:</strong> Juan Pérez</p>
  </div> -->



  <div class="category-name">
    Información de Asignaturas
  </div>

  <!-- <div class="card-person" style="margin-top: 10mm;"> -->
  <div class="card-person">
    <div class="card-person-header">
      <h4>Información del Grupo</h4>
    </div>
    <div class="card-person-body">
      <div class="row-data">
        <span class="label">Sede:</span>
        <span class="value">Túpac Amaru</span>
      </div>
      <div class="row-data">
        <span class="label">Nivel:</span>
        <span class="value">Secundaria</span>
      </div>
      <div class="row-data">
        <span class="label">Grado:</span>
        <span class="value">5to</span>
      </div>
      <div class="row-data">
        <span class="label">Periodo:</span>
        <span class="value">2025-R</span>
      </div>
      <div class="row-data">
        <span class="label">Tipo:</span>
        <span class="value">Académico Regular</span>
      </div>
    </div>
  </div>

  <div class="asignaturas-table">
    <table>
      <thead>
        <tr>
          <th>Área</th>
          <th>Asignatura</th>
          <th>Docente</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="2">Matemáticas</td>
          <td>Álgebra</td>
          <td>Lic. Martínez</td>
        </tr>
        <tr>
          <td>Geometría</td>
          <td>Prof. Ramírez</td>
        </tr>
        <tr>
          <td rowspan="3">Ciencias</td>
          <td>Biología</td>
          <td>Ing. Gómez</td>
        </tr>
        <tr>
          <td>Química</td>
          <td>Lic. Rodríguez</td>
        </tr>
        <tr>
          <td>Física</td>
          <td>Prof. Sánchez</td>
        </tr>
        <tr>
          <td rowspan="2">Lenguaje</td>
          <td>Literatura</td>
          <td>Lic. Díaz</td>
        </tr>
        <tr>
          <td>Gramática</td>
          <td>Prof. Torres</td>
        </tr>
      </tbody>
    </table>
  </div>









  <div class="final-contract">
    <h2>ACUERDOS Y CONDICIONES</h2>
    <p>
      Por el presente contrato se celebra entre el <strong>COLEGIO SAN JOSÉ JULIACA</strong>, con RUC N° __________ y domicilio en Jr. __________ N° ___, Juliaca, representado por el Sr. __________, en adelante <strong>EL COLEGIO</strong>, y el Sr./Sra. __________, identificado con DNI N° __________, en adelante <strong>EL PADRE DE FAMILIA, TUTOR LEGAL O APODERADO</strong>, de conformidad con el D.S. 011-2012-ED, la Ley N° 26549, la Ley N° 28044 y su Reglamento, la R.M. 627-2016-MINEDU y demás normas aplicables.
    </p>

    <h3>I. OBJETO DEL CONTRATO</h3>
    <p>
      El presente contrato tiene por objeto regular la prestación de servicios educativos que <strong>EL COLEGIO</strong> brindará al (la) estudiante, de acuerdo al Proyecto Educativo Institucional y al Reglamento Interno de la institución.
    </p>

    <h3>II. ANEXO DE LA INSTITUCIÓN EDUCATIVA</h3>
    <p>
      Se reconoce como anexo el local ubicado en __________, con R.D. N° __________, donde se imparte el servicio educativo en los niveles de Inicial, Primaria y Secundaria.
    </p>

    <h3>III. RESPONSABLES DEL PAGO DE PENSIÓN</h3>
    <p>
      <strong>EL PADRE DE FAMILIA, TUTOR LEGAL O APODERADO</strong> se compromete a efectuar los pagos correspondientes a la matrícula y pensiones mensuales, de acuerdo al cronograma establecido por <strong>EL COLEGIO</strong>.
    </p>

    <h3>IV. COMPROMISO EDUCATIVO</h3>
    <p>
      <strong>EL COLEGIO</strong> se compromete a ofrecer una educación integral de calidad, fomentando valores, disciplina y el desarrollo de competencias en un ambiente seguro y responsable. Asimismo, <strong>EL PADRE DE FAMILIA, TUTOR LEGAL O APODERADO</strong> se obliga a cumplir con el Reglamento Interno y asegurar la asistencia y participación regular del (la) estudiante.
    </p>

    <h3>V. FORMALIZACIÓN DE LA MATRÍCULA</h3>
    <p>
      La formalización de la matrícula se producirá con la firma del presente contrato, el abono de la cuota de matrícula y la presentación de la documentación requerida. El incumplimiento de estos requisitos inhabilitará el acceso a los servicios educativos.
    </p>

    <h3>VI. CRONOGRAMA DE PENSIONES ESCOLARES</h3>
    <p>
      Las pensiones mensuales deberán abonarse según el siguiente cronograma:
    </p>
    <ul>
      <li>1.ª Mensualidad: 05 de marzo de 2024</li>
      <li>2.ª Mensualidad: 05 de abril de 2024</li>
      <li>3.ª Mensualidad: 05 de mayo de 2024</li>
      <li>… (continuará según calendario interno)</li>
    </ul>
    <p>
      Los pagos se realizarán mediante la cuenta bancaria indicada por <strong>EL COLEGIO</strong>, y el comprobante deberá ser presentado en Secretaría para su validación.
    </p>

    <h3>VII. VIGENCIA DEL CONTRATO</h3>
    <p>
      Este contrato tendrá vigencia desde el inicio del Año Académico 2024 hasta su culminación, conforme al calendario escolar oficial.
    </p>

    <h3>VIII. DERECHOS Y OBLIGACIONES DE LAS PARTES</h3>
    <ol>
      <li>
        <strong>Obligaciones de EL COLEGIO:</strong> Proveer una educación integral de calidad, garantizar condiciones adecuadas de infraestructura y seguridad, y cumplir con las disposiciones legales y normativas del Ministerio de Educación.
      </li>
      <li>
        <strong>Obligaciones del PADRE DE FAMILIA, TUTOR LEGAL O APODERADO:</strong> Efectuar puntualmente el pago de matrícula y pensiones, mantener actualizada la información de contacto, justificar inasistencias y participar en reuniones y actividades convocadas por <strong>EL COLEGIO</strong>.
      </li>
    </ol>

    <h3>IX. HORARIO DE CLASES Y NÚMERO MÁXIMO DE ALUMNOS</h3>
    <p>
      El horario regular de clases es el siguiente:
      <br>
      <strong>Inicial:</strong> 8:00 a.m. – 12:30 p.m.
      <br>
      <strong>Primaria:</strong> 7:45 a.m. – 1:30 p.m.
      <br>
      <strong>Secundaria:</strong> 7:30 a.m. – 2:00 p.m.
      <br>
      Para el período de invierno se aplicarán modificaciones según lo establecido en el reglamento interno.
    </p>

    <h3>X. DISPOSICIONES COMPLEMENTARIAS</h3>
    <p>
      <strong>EL COLEGIO</strong> no se responsabiliza por la pérdida de objetos personales del (la) estudiante, si estos no han sido debidamente cuidados o registrados. La matrícula no exime del cumplimiento del Reglamento Interno ni de otras obligaciones legales.
    </p>

    <h3>XI. AUTORIZACIONES DEL PADRE DE FAMILIA, TUTOR LEGAL O APODERADO</h3>
    <ol>
      <li>
        Autoriza a <strong>EL COLEGIO</strong> a publicar fotografías o videos de actividades académicas y extracurriculares con fines informativos y formativos.
      </li>
      <li>
        Autoriza a recibir comunicaciones por correo electrónico, teléfono o mensajería para asuntos relacionados con la actividad educativa y administrativa.
      </li>
    </ol>

    <h3>XII. SERVICIOS DE APOYO AL ESTUDIANTE</h3>
    <p>
      Se brindarán servicios complementarios tales como biblioteca, centro de cómputo, laboratorios, servicios de psicología, orientación vocacional y una plataforma virtual para reforzar el aprendizaje.
    </p>

    <h3>XIII. DELEGACIÓN DE CONFORMIDAD</h3>
    <p>
      El PADRE DE FAMILIA, TUTOR LEGAL O APODERADO delega su conformidad para que el (la) estudiante participe en actividades académicas, deportivas y culturales organizadas por <strong>EL COLEGIO</strong>, incluidas salidas o visitas programadas bajo supervisión institucional.
    </p>

    <h3>XIV. MEDIOS DE COMUNICACIÓN OFICIALES</h3>
    <p>
      Las notificaciones y comunicaciones se efectuarán a través de la dirección domiciliaria y/o el correo electrónico proporcionados. Cualquier cambio en estos datos deberá ser informado por escrito a <strong>EL COLEGIO</strong>.
    </p>

    <h3>XV. DECLARACIÓN DEL DOMICILIO</h3>
    <p>
      El PADRE DE FAMILIA, TUTOR LEGAL O APODERADO declara como domicilio el consignado en el presente contrato para efectos de notificaciones y comunicaciones. El no informar cambios en el domicilio exime a <strong>EL COLEGIO</strong> de la responsabilidad por la falta de entrega de documentos.
    </p>

    <h3>FIRMAS</h3>
    <p>
      Firman de conformidad en la ciudad de Juliaca, a ___ de __________ de 2024:
    </p>
    <p><strong>Representante de EL COLEGIO:</strong> Nombre y firma: __________________________________</p>
    <p><strong>Padre de Familia / Tutor Legal / Apoderado:</strong> Nombre y firma: __________________________________</p>
    <p><strong>DNI:</strong> __________________________</p>
  </div>














</div>
