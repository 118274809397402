import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private usuario: any;

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl;

  setUsuario(usuario: any): void {
    this.usuario = usuario;
  }

  getUsuario(): any {
    return this.usuario;
  }

  logout = ()=> this.http.post(this.apiUrl+'/auth/logout',{});


}
