import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModuloMatriculaService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl;

  apiGetEstudiantes(pageIndex: number, pageSize: number, searchTerm: string): Observable<any> {
    let params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('pageSize', pageSize.toString())
      .set('search', searchTerm);

    return this.http.get<any>(`${this.apiUrl}/matricula/get-estudiantes`, { params });
  }
  apiGetEstudiantesMatriculados = (params: any): Observable<any> => this.http.get<any>(`${this.apiUrl}/matricula/get-estudiantes-matriculados`, { params });

  apiSaveUsers  = (data: any) => this.http.post(`${this.apiUrl}/matricula/save-users`, data);
  // apiGetMatriculaPersonas = (params: any) => this.http.get(`${this.apiUrl}/matricula/get-personas`, {params});



  // apiGetPagoMatricula(params: any): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/matricula/get-pago-matricula`, { params }).pipe(map((r: any) => r.data));
  // }

  // apiRealizarMatricula  = (data: any) => this.http.post(`${this.apiUrl}/matricula/realizar-matricula`, data);
  apiAnularMatricula  = (data: any) => this.http.post(`${this.apiUrl}/matricula/anular-matricula`, data);

  apiGetContratosEstudiante   = (params?: any)  => this.http.get    (this.apiUrl+'/accounting/contratos-estudiante' , {params}) .pipe(map((r: any) => r.data));
  apiGetPagosEstudiante       = (params?: any)  => this.http.get    (this.apiUrl+'/accounting/pagos-estudiante'     , {params}) .pipe(map((r: any) => r.data));
  apiRealizarPagosEstudiante  = (data: any)     => this.http.post(`${this.apiUrl}/accounting/realizar-pagos-estudiante`, data);



  // PASO 1

  apiGetStudentAndParents(id_estudiante: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/matricula/paso-1/get-student-and-parents/${id_estudiante}`);
  }

  // PASO 2

  apiGetSedes         = (params?: any)  =>  this.http.get(this.apiUrl+'/matricula/paso-2/get-sedes'           , {params}) .pipe(map((r: any) => r.data));
  apiGetNiveles       = (params?: any)  =>  this.http.get(this.apiUrl+'/matricula/paso-2/get-niveles'         , {params}) .pipe(map((r: any) => r.data));
  apiGetGrados        = (params?: any)  =>  this.http.get(this.apiUrl+'/matricula/paso-2/get-grados'          , {params}) .pipe(map((r: any) => r.data));
  apiGetPlanTipos     = (params?: any)  =>  this.http.get(this.apiUrl+'/matricula/paso-2/get-plan-tipos'      , {params}) .pipe(map((r: any) => r.data));
  apiGetPeriodos      = (params?: any)  =>  this.http.get(this.apiUrl+'/matricula/paso-2/get-periodos'        , {params}) .pipe(map((r: any) => r.data));

  apiGetPlanesEstudio = (params?: any)  =>  this.http.get(this.apiUrl+'/matricula/paso-2/get-planes-estudio'  , {params}) .pipe(map((r: any) => r.data));

  // PASO 3

  apiGetPagoMatricula   = (params?: any)  =>  this.http.get (this.apiUrl + '/matricula/paso-3/get-pago-matricula'   , {params}) .pipe(map((r: any) => r.data));
  apiConfirmarMatricula = (data: any)     =>  this.http.post(this.apiUrl + '/matricula/paso-3/confirmar-matricula'  , data);

}
