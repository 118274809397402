<div class="esv-page">

  <sv-page-title
    [pageTitle]="'Estudiantes Matriculados'"
    [showGoBack]="true"
    [showReload]="true">
  </sv-page-title>

  <div class="header">

    <div class="element-section">
      <div class="element-section-title">Filtros de búsqueda</div>
      <div class="row-align-center">
        <input class="search-input" type="text" [(ngModel)]="searchTerm" [value]="searchTerm" placeholder="Buscar..." (keydown.enter)="getEstudiantesMatriculados()">
        <button class="esv-button search" (click)="getEstudiantesMatriculados()"> <mat-icon>search</mat-icon> Buscar / Filtrar </button>
      </div>
    </div>

    <div class="esv-filters-list">

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Sede</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedSede" (selectionChange)="onSedeChange()" >
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let sede of listSedes" [value]="sede.id">{{sede.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Nivel</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedNivel" (selectionChange)="onNivelChange()" >
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let nivel of listNiveles" [value]="nivel.id">{{nivel.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Grado</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic"  >
          <mat-select [(value)]="selectedGrado" (selectionChange)="applyFilters()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let grado of listGrados" [value]="grado.id">{{grado.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Periodo</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedPeriodo" (selectionChange)="onPeriodoChange()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let periodo of listPeriodos" [value]="periodo.id">{{periodo.codigo}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Tipo de Plan</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedPlanTipo" (selectionChange)="applyFilters()">
            <mat-option [value]="''">Todos</mat-option>
            <mat-option *ngFor="let planTipo of listPlanTipos" [value]="planTipo.id">{{planTipo.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="filter-select">
        <mat-label><mat-icon>filter_list</mat-icon>Estado</mat-label>
        <mat-form-field class="mat-ff-dynamic-width" appearance="outline" subscriptSizing="dynamic">
          <mat-select [(value)]="selectedEstado" (selectionChange)="applyFilters()">
            <mat-option [value]="'1'">Activo</mat-option>
            <mat-option [value]="'0'">Inactivo</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

  </div>

  <div class="body">

    <div class="element-section">
      <div class="element-section-title">Resultados</div>
    </div>

    <div *ngIf="isLoading">
      <app-sv-skeleton-card-list
        [numberCards]="pageSize"
        [numberChildren]="1"
        [childrenCircle]="false"
        [numberChips]="0">
      </app-sv-skeleton-card-list>
    </div>

    <div *ngIf="!isLoading">

      <ng-container *ngIf="pageList.length; else noResultsFound">
        <div class="esv-card-list">
          <sv-card-list
            *ngFor="let pageItem of pageList"
            [titulo]="pageItem?.nombre_completo"
            [descripcionArray]="[
              { key: 'DNI'    , value: pageItem.documento_identidad},
              { key: 'N° DOC' , value: pageItem.codigo},
              { key: 'Plan Estudio' , value: pageItem.nombre_plan_estudio},
            ]"
            [featuresChipsArray]="[
              { key: 'Sede'   , value: pageItem.nombre_sede     ?? 'Todos' },
              { key: 'Nivel'  , value: pageItem.nombre_nivel    ?? 'Todos' },
              { key: 'Grado'  , value: pageItem.nombre_grado    ?? 'Todos' },
              { key: 'Periodo', value: pageItem.nombre_periodo },
              { key: 'Tipo'   , value: pageItem.nombre_plan_tipo },
            ]">
          </sv-card-list>
        </div>
      </ng-container>


      <ng-template #noResultsFound>
        <sv-no-results-found></sv-no-results-found>
      </ng-template>

    </div>

    <mat-paginator [length]="pageTotal" [pageSize]="pageSize" [pageSizeOptions]="[1, 5, 10, 20]" (page)="pageEvent($event)">
    </mat-paginator>

  </div>

</div>


