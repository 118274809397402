<h2 mat-dialog-title>Asignar Usuarios al Rol</h2>
<mat-dialog-content>
  <div class="row gx-0">
    <div class="col">
      <h3>Usuarios con el Rol</h3>
      <ng-container *ngIf="usersWithRole.length; else sinUsuarios">
        <div *ngFor="let user of usersWithRole">
          <mat-icon>remove</mat-icon>
          {{ user.name }}
          <button mat-icon-button (click)="removeUser(user.id)">
            <mat-icon>remove_circle</mat-icon>
          </button>
        </div>
      </ng-container>
      <ng-template #sinUsuarios>
        <div>No hay usuarios con el rol</div>
      </ng-template>
    </div>
    <div class="col">
      <h3>Buscar Usuarios</h3>
      <mat-form-field appearance="outline">
        <mat-label>Buscar usuario</mat-label>
        <input matInput [formControl]="searchControl" placeholder="Buscar usuarios" />
      </mat-form-field>
      <ul>
        <li *ngFor="let user of searchResults">
          {{user.documento_identidad}} - {{ user.nombres }}
          <button mat-icon-button (click)="assignUser(user.id)">
            <mat-icon>add_circle</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button mat-button (click)="close()">Cerrar</button>
</mat-dialog-actions>
