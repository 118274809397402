import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl;

  // login = (params: any)=> this.http.get(this.apiUrl+'/auth/login', params);
  login = (params: any)=> this.http.post<any>(this.apiUrl+'/auth/login', params);
  login2 = (params: any)=> this.http.get(this.apiUrl+'/auth/login', params);
}
