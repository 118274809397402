<app-sv-page-content-header
  titulo="Configuración de Roles">
</app-sv-page-content-header>

<div class="page-content">
  <div class="page-content-header-search">
    <!-- <div class="search-container"></div> -->
    <div class="w-100 d-flex" style="justify-content: space-between; height: 40px;">
      <div style="font-size: 24px; font-weight: bold; display: flex; display: flex; vertical-align: middle; align-items: center; display: flex; vertical-align: middle; align-items: center;">Lista de Roles</div>
      <button class="btn-status btn-primary" (click)="openCrearRol()">Crear Rol</button>
    </div>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let rol"> {{rol.nombre}} </td>
      </ng-container>
      <ng-container matColumnDef="codigo">
        <th mat-header-cell *matHeaderCellDef> Codigo </th>
        <td mat-cell *matCellDef="let rol"> {{rol.codigo}} </td>
      </ng-container>
      <ng-container matColumnDef="activo">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let rol"> {{rol.activo ? 'Activo' : 'Desactivado'}} </td>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> Acciones </th>
        <td mat-cell *matCellDef="let rol">
          <!-- <button mat-icon-button (click)="openEditarRol(rol)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button (click)="deleteRol(rol.id)">
            <mat-icon>delete</mat-icon>
          </button> -->
          <div class="btn-list-container">
            <button class="btn-status-rounded btn-auto-width btn-light" (click)="openEditarRol(rol)">
              <mat-icon class="btn-icon">edit</mat-icon>
              Editar
            </button>
            <button class="btn-status-rounded btn-auto-width btn-purple" (click)="openAssignModulo(rol.id)">
              <mat-icon class="btn-icon">add</mat-icon>
              Módulos
            </button>
            <button class="btn-status-rounded btn-auto-width btn-info" (click)="openAssignUsuario(rol)" >
              <mat-icon class="btn-icon">people</mat-icon>
              Usuarios
            </button>
            <button class="btn-status-rounded btn-auto-width btn-danger" (click)="deleteRol(rol.id)">
              <mat-icon class="btn-icon">delete</mat-icon>
              Eliminar
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
