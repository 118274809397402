<!-- conf-usuarios-create.component.html -->
<h2 mat-dialog-title>{{ isEdit ? 'Editar Usuario' : 'Crear Usuario' }}</h2>
<mat-dialog-content>
  <form [formGroup]="usuarioForm" (ngSubmit)="onSubmit()">
    <div class="form-row mt-2">
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="name" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>2do Nombre</mat-label>
        <input matInput formControlName="name_2" required>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Apellido Paterno</mat-label>
        <input matInput formControlName="lastname_1" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Apellido Materno</mat-label>
        <input matInput formControlName="lastname_2" required>
      </mat-form-field>

    </div>

    <div class="form-row">
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
      </mat-form-field>
    </div>

    <div class="form-row">

      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Tipo de Documento</mat-label>
        <mat-select formControlName="tipo_documento" required>
          <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">{{ tipo.nombre }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Documento de Identidad</mat-label>
        <input matInput formControlName="documento_identidad" required>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Domicilio</mat-label>
        <input matInput formControlName="domicilio" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Celular</mat-label>
        <input matInput formControlName="celular" required>
      </mat-form-field>
    </div>

    <div class="form-row">
      <mat-form-field appearance="outline" class="flex-item">
        <mat-label>Usuario</mat-label>
        <input matInput formControlName="usuario" required>
      </mat-form-field>
      <mat-form-field *ngIf="!isEdit" appearance="outline" class="flex-item">
        <mat-label>Contraseña</mat-label>
        <input matInput type="password" formControlName="password" required>
      </mat-form-field>

    </div>


    <mat-dialog-actions align="end">
      <button mat-button type="button" (click)="onClose()">Cancelar</button>
      <button mat-button color="primary" type="submit">{{ isEdit ? 'Guardar Cambios' : 'Crear' }}</button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
