import { Component } from '@angular/core';
import { ScaffoldService } from './scaffold.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, filter } from 'rxjs';
import { ModuloInterface } from 'src/app/pages/modulo-configuracion/interfaces/modulo-interface';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-scaffold',
  // standalone: true,
  // imports: [],
  templateUrl: './scaffold.component.html',
  styleUrl: './scaffold.component.css'
})
export class ScaffoldComponent {

  // ngOnInit(){
  //   this.getModulosMenu();
  // }

  listaModulos: ModuloInterface[] = [];
  moduloParent: any;
  // xModuloId: any = this.activatedRoute.queryParams;
  xModuloId: any;
  xModuloUrl: any;
  xModuloNombre: any;
  menuLoaded: boolean = false;

  expandedModule: string | null = null;
  urlSeleccionada: string | null = null;

  usuario: any;

  constructor (
    private scaffoldService: ScaffoldService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

    private route: ActivatedRoute,

    private authService: AuthService,
  ) { }

    ngOnInit() {
      this.usuario = this.authService.getUsuario();

      console.log('INIT SCAFFOLD');
      this.activatedRoute.paramMap.subscribe(() => {
        const navigation = window.history.state;
        // if (navigation && navigation.id) {
        if (navigation) {
          this.xModuloId = navigation.id_modulo;
          this.xModuloNombre = navigation.modulo_nombre;
          this.xModuloUrl = navigation.url;
          console.log('Module ID:', this.xModuloId);
        } else {
          console.log('No navigation state found');
        }
      });
      console.log('END SCAFFOLD');

      // this.extractUrlSegment();





      // this.route.url.subscribe(segments => {
      //   console.log('segments: ', segments);
      //   if (segments.length > 1) {
      //     this.xModuloUrl = segments[1].path;
      //     console.log('Extracted xModuloUrl:', this.xModuloUrl);
      //     this.getModulosMenu();
      //   } else {
      //     console.error('URL segment is missing');
      //   }
      // });

      // this.router.events.pipe(
      //   filter(event => event instanceof NavigationEnd)
      // ).subscribe(() => {
      //   this.extractUrlSegmentAndLoadMenu();
      // });

      // Carga inicial cuando el componente se inicializa
      this.extractUrlSegmentAndLoadMenu();
      this.urlSeleccionada = this.router.url.replace('/modulos/', '');
    }

    extractUrlSegmentAndLoadMenu(): void {
      let child = this.route.firstChild;

      while (child) {
        if (child.snapshot.url.length) {
          this.xModuloUrl = child.snapshot.url[0].path;
          break;
        }
        child = child.firstChild;
      }

      console.log('Extracted xModuloUrl:', this.xModuloUrl);

      // Solo cargar el menú si no se ha cargado antes
      if (!this.menuLoaded) {
        this.getModulosMenu();
      }
    }






  // extractUrlSegment(): void {
  //   const urlSegments = this.route.snapshot.url;
  //   if (urlSegments.length > 1) {
  //     this.xModuloUrl = urlSegments[1].path; // Esto obtiene el segundo segmento de la URL
  //   }
  //   console.log('this.xModuloUrl', this.xModuloUrl);

  //   this.getModulosMenu();
  // }

  getModulosMenu(): void {
    console.log('this.xModuloId', this.xModuloId);
    var params = {
      'id_modulo': this.xModuloId,
      'url': this.xModuloUrl
    };
    this.scaffoldService.apiGetModulosSidenav(params)
      // .subscribe((data: ModuloInterface[]) => {
      .subscribe((data: any) => {
        this.moduloParent = data.parent;
        this.listaModulos = data.children;
        console.log('listaModulos: ', this.listaModulos)
      });
  }





  setExpanded(moduleName: string | null) {
    this.expandedModule = moduleName;
  }

  seleccionarModulo(modulo: ModuloInterface) {
    this.urlSeleccionada = modulo.url;

    console.log('this.urlSeleccionada: ', this.urlSeleccionada)
  }

  isModuloSeleccionado(modulo: ModuloInterface): boolean {
    // Obtener la URL actual y eliminar todo hasta "/modulos/"
    const urlActual = this.router.url.replace(/^.*\/modulos\//, ''); // Eliminar el dominio y la parte "/modulos/"

    // Verificar si la URL del módulo coincide exactamente o si la URL actual empieza con su ruta
    if (urlActual === modulo.url || urlActual.startsWith(modulo.url + '/')) {
      return true;
    }

    // Si el módulo tiene hijos, verificar si alguno de ellos está seleccionado
    if (modulo.hijos && modulo.hijos.length > 0) {
      return modulo.hijos.some(child => this.isModuloSeleccionado(child));
    }

    return false;
  }




}
