import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModuloMatriculaService } from '../../modulo-matricula.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-matri-proceso-resumen-matricula',
  templateUrl: './matri-proceso-resumen-matricula.component.html',
  styleUrls: ['./matri-proceso-resumen-matricula.component.css']
})
export class MatriProcesoResumenMatriculaComponent {

  constructor(
    public dialogRef: MatDialogRef<MatriProcesoResumenMatriculaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private moduloMatriculaService: ModuloMatriculaService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmarMatricula(): void {
  //   const selectedCostsForBackend = this.data.selectedCosts.map((cost: any) => ({
  //     id: cost.id,
  //     nombre: cost.nombre,
  //     monto_inicial: cost.monto_inicial,
  //     monto_final: cost.monto_final,
  //     fecha_pago_inicial: cost.fecha_pago_inicial ? formatDate(cost.fecha_pago_inicial, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
  //     fecha_pago_final: cost.fecha_pago_final ? formatDate(cost.fecha_pago_final, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
  //     tipo: cost.tipo,
  //   }));

  //   const allCostsForBackend = this.data.costos.map((cost: any) => ({
  //     id: cost.id,
  //     nombre: cost.nombre,
  //     monto_inicial: cost.monto_inicial,
  //     monto_final: cost.monto_final,
  //     fecha_pago_inicial: cost.fecha_pago_inicial ? formatDate(cost.fecha_pago_inicial, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
  //     fecha_pago_final: cost.fecha_pago_final ? formatDate(cost.fecha_pago_final, 'yyyy-MM-dd HH:mm:ss', 'en-EN') : null,
  //     tipo: cost.tipo,
  //   }));

  //   this.moduloMatriculaService.apiRealizarMatricula({
  //     id_estudiante: this.data.id_estudiante,
  //     id_grupo: this.data.selectedGrupoId,
  //     selected_costos: selectedCostsForBackend,
  //     all_costos: allCostsForBackend,
  //     tipo_comprobante: this.data.tipo_comprobante
  //   }).subscribe(response => {
  //     console.log('Matrícula realizada con éxito', response);
  //     this.dialogRef.close(true); // Close dialog and pass result
  //   });
  // }

    this.dialogRef.close(true);

  }
}
