import { Component, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-sv-pdf-ticket-deposito',
  templateUrl: './sv-pdf-ticket-deposito.component.html',
  styleUrl: './sv-pdf-ticket-deposito.component.css'
})
export class SvPdfTicketDepositoComponent {

  title: string = "";
  content: string = "";
  responseData: any;

  fechaHoraActual: Date;

  constructor(private cdr: ChangeDetectorRef) {
    this.fechaHoraActual = new Date();
  }

  ngOnInit(): void {
    setInterval(() => {
      this.fechaHoraActual = new Date();
    }, 1000);

    console.log("🔹 OnInit ejecutado. responseData:", this.responseData);
    this.cdr.detectChanges();
  }

  setData(params: any) {
    console.log("📌 Datos recibidos en setData:", params);
    this.title = params.nombre;
    this.content = params.detalle;
    this.responseData = params.responseDeposito;
    this.cdr.detectChanges();
  }
}
