import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VistaModulosComponent } from './components/main/vista-modulos.component';

const routes: Routes = [
  {path: '', component: VistaModulosComponent,},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VistaModulosRoutingModule { }
