<mat-card class="custom-card">
  <mat-card-header>
    <div class="header-content">
      <div class="title-group">
        <div class="title-text">{{titulo}}</div>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content>
    <!-- <div class="subtitle-text">Grupos</div> -->
    <div class="feature-list">
      <!-- <div class="feature-item" *ngFor="let feature of features">{{ feature }}</div> -->
      <div class="feature-item" *ngFor="let feature of featuresChipsArray"><b>{{feature.key}}: {{feature.value}}</b></div>
    </div>
    <div class="grupo-container" *ngFor="let grupo of grupos; let i = index" [ngClass]="{'selected': grupo.id === xIdGrupoSeleccionado}">
      <div class="grupo-circle">G{{ i + 1 }}</div>

      <div class="grupo-column">
        <div class="grupo-nombre">{{ grupo.nombre }}</div>
        <div>Vacantes disponibles: {{ grupo.cupos - grupo.matriculados_count }}/{{ grupo.cupos }}</div>
      </div>

      <div class="grupo-button">
        <button mat-flat-button
          [color]="grupo.id === xIdGrupoSeleccionado ? 'background' : 'primary'"
          (click)="seleccionarGrupo(grupo.id)">
          {{ grupo.id === xIdGrupoSeleccionado ? 'Seleccionado' : 'Seleccionar'}}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
