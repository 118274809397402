<div mat-dialog-title>{{ 'Competencia: ' + xCompetenciaModel?.nombre }}</div>


<div mat-dialog-content>

  <div class="flip-card" [class.flipped]="isFlipped">
    <div class="flip-card-inner">

      <div class="flip-card-front">



        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="h5">Lista de Desempeños</div>
          <button class="custom-action-button primary wpx200 m-0" type="button" (click)="flipCard()">
            Nuevo Desempeño
          </button>
        </div>

        <div class="table-container" style="max-height: 100%; overflow-y: auto; margin-top: 10px;">
          <table mat-table [dataSource]="desempenyosList" class="mat-elevation-z0">
            <!-- Define your columns here -->una
            <ng-container matColumnDef="nombre_grado">
              <th mat-header-cell *matHeaderCellDef> Grado </th>
              <td mat-cell *matCellDef="let element"> {{element.nombre_grado}} </td>
            </ng-container>

            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef> Nombre </th>
              <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>

            <ng-container matColumnDef="detalle">
              <th mat-header-cell *matHeaderCellDef> Detalle </th>
              <td mat-cell *matCellDef="let element"> {{element.detalle}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary" (click)="editDesempenyo(element)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" (click)="deleteDesempenyo(element)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>


      <div class="flip-card-back">

        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="h5">{{ isEditing ? 'Editar' : 'Crear' }} Desempeño</div>
          <button class="custom-action-button transparent wpx200 m-0" type="button" (click)="flipCard()">
            Cancelar
          </button>
        </div>

        <form [formGroup]="desempenyoForm" (ngSubmit)="saveDesempenyo()">
          <div class="row gx-3">
            <div class="col-md-6 d-flex flex-column">
              <mat-form-field appearance="outline" class="flex-textarea" subscriptSizing="dynamic">
                <mat-label>Nombre</mat-label>
                <textarea matInput formControlName="nombre" class="h15rem" required></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-6 d-flex flex-column">
              <mat-form-field class="mb-3" appearance="outline" subscriptSizing="dynamic">
                <mat-label>Grado</mat-label>
                <mat-select formControlName="id_grado_curricular">
                  <mat-option *ngFor="let grado of listGradosGurriculares" [value]="grado.id">
                    {{grado.nombre}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="flex-textarea" subscriptSizing="dynamic">
                <mat-label>Descripción</mat-label>
                <textarea matInput formControlName="descripcion" class="h15rem"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex justify-content-end align-items-center mb-3">
            <button class="custom-action-button primary wpx200 m-0" type="submit" [disabled]="desempenyoForm.invalid">
              {{ isEditing ? 'Actualizar' : 'Crear' }}
            </button>
          </div>
        </form>



      </div>
    </div>
  </div>



</div>



<div mat-dialog-actions align="center">
  <button class="custom-action-button transparent" type="button" (click)="onClose()">Cerrar</button>
</div>
