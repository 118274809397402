<div mat-dialog-title>{{ isEdit ? 'Editar' : 'Crear' }} Área Curricular</div>
<div mat-dialog-content>
  <form [formGroup]="areaForm" (ngSubmit)="onSubmit()" class="form-col">
    <div class="form-row mt-2">
      <mat-form-field appearance="outline" class="flex-input">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre" required>
      </mat-form-field>
    </div>
    <div class="form-row mt-2">
      <mat-form-field appearance="outline" class="flex-textarea" subscriptSizing="dynamic">
        <mat-label>Detalle</mat-label>
        <textarea matInput formControlName="detalle" class="flex-textarea-one"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>

<div mat-dialog-actions align="center">
  <button class="custom-action-button transparent" type="button" (click)="onClose()">Cancelar</button>
  <button class="custom-action-button primary" type="submit" (click)="onSubmit()" [disabled]="areaForm.invalid">{{ isEdit ? 'Guardar Cambios' : 'Crear' }}</button>
</div>
