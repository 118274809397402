import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';

@Component({
  selector: 'app-conf-modulos-create',
  templateUrl: './conf-modulos-create.component.html',
  styleUrls: ['./conf-modulos-create.component.css']
})
export class ConfModulosCreateComponent {
  moduloForm: FormGroup;
  isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfModulosCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private moduloConfiguracionService: ModuloConfiguracionService
  ) {
    // this.isEdit = data && data.modulo ? true : false;
    this.isEdit     = data.es_edit;
    const id_padre  = data.es_edit ? data.modulo.id_padre : data.modulo?.id    ? data.modulo.id         : null;
    const nivel     = data.es_edit ? data.modulo.nivel    : data.modulo?.nivel ? data.modulo.nivel + 1  : 1;
    const nombre    = data.es_edit ? data.modulo.name     : null;
    const url       = data.es_edit ? data.modulo.url      : data.modulo?.url   ? data.modulo.url + '/'  : null;

    this.moduloForm = this.fb.group({
      // id_padre: [data?.modulo?.id || ''],
      id_padre: [id_padre || ''],
      nivel: [nivel, Validators.required],
      nombre: [nombre, Validators.required],
      // url: [data?.modulo?.url || '', Validators.required],
      url: [url, Validators.required],
      activo: [data?.modulo?.activo || 1, Validators.required],
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.moduloForm.valid) {
      const formData = this.moduloForm.value;

      if (this.isEdit) {
        this.moduloConfiguracionService.apiUpdateModulo(this.data.modulo.id, formData).subscribe((response: any) => {
          this.dialogRef.close(true);
        });
      } else {
        this.moduloConfiguracionService.apiCreateModulo(formData).subscribe((response: any) => {
          this.dialogRef.close(true);
        });
      }
    }
  }
}
