import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/core/not-found/not-found.component';
import { ContEstudiantesListComponent } from './components/cont-estudiantes-list/cont-estudiantes-list.component';
import { ContEstudianteEstadoFinancieroComponent } from './components/cont-estudiante-estado-financiero/cont-estudiante-estado-financiero.component';
import { ContMainComponent } from './cont-main.component';
import { ContComprobantesListComponent } from './components/cont-comprobantes-list/cont-comprobantes-list.component';
import { ContEstudianteContratosComponent } from './components/cont-estudiante-contratos/cont-estudiante-contratos.component';

const routes: Routes = [{
  path: '',
  component: ContMainComponent,
  children: [
    {
      path: 'estudiantes',
      component: ContEstudiantesListComponent,
    },
    {
      path: 'estudiantes/:id',
      component: ContEstudianteEstadoFinancieroComponent,
    },
    {
      path: 'estudiantes/:id/mensualidades',
      component: ContEstudianteContratosComponent,
    },
    {
      path: 'comprobantes',
      component: ContComprobantesListComponent,
    },
    {
      path: '',
      redirectTo: 'estudiantes',
      pathMatch: 'full'
    },
    {
      path: '**',
      component: NotFoundComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModuloContabilidadRoutingModule { }
