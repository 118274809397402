import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { ConfSecretariaInterface } from '../../../interfaces/conf-secretaria.interface';
import { AdmiSedeInterface } from 'src/app/pages/modulo-administracion/interfaces/admi-sede.interface';
import { ModuloAdministracionService } from 'src/app/pages/modulo-administracion/modulo-administracion.service';

@Component({
  selector: 'app-conf-soporte-secretarias-edit',
  templateUrl: './conf-soporte-secretarias-edit.component.html',
  styleUrls: ['./conf-soporte-secretarias-edit.component.css']
})
export class ConfSoporteSecretariasEditComponent implements OnInit {
  secretariaForm: FormGroup;
  isEditing = false;
  listSedes: AdmiSedeInterface[] = [];
  searchResults: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ConfSoporteSecretariasEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private moduloConfiguracionService: ModuloConfiguracionService,
    private moduloAdministrationService: ModuloAdministracionService,
  ) {
    this.secretariaForm = this.fb.group({
      id: [''],
      id_sede: ['', Validators.required],
      id_persona: ['', Validators.required],
      nombre_persona: ['', Validators.required],  // Añadir campo para nombre completo
      nombre: ['', Validators.required],
      detalle: ['']
    });
  }

  ngOnInit(): void {
    // this.loadSedes();

    this.listSedes = this.data.listSedes;

    if (this.data && this.data.secretaria) {
      this.isEditing = true;
      const nombreCompleto = this.data.secretaria.nombre_persona || this.getFullName(this.data.secretaria);
      this.secretariaForm.patchValue({
        ...this.data.secretaria,
        nombre_persona: nombreCompleto  // Inicializar el campo con el nombre completo
      });
    }
  }

  // loadSedes(): void {
  //   this.moduloAdministrationService.apiGetSedes().subscribe((data: any[]) => {
  //     this.listSedes = data;
  //   });
  // }

  onSearchChange(searchTerm: string): void {
    if (searchTerm) {
      this.searchSecretarias(searchTerm);
    } else {
      this.searchResults = [];
    }
  }

  searchSecretarias(searchTerm: string): void {
    const params = {
      search_term: searchTerm,
    };
    this.moduloConfiguracionService.apiSearchSecretariaPersonas(params).subscribe((users: any[]) => {
      this.searchResults = users.map((user: any) => ({
        ...user,
        nombres: this.getFullName(user)
      }));
    });
  }

  selectSecretaria(secretaria: any): void {
    const nombreCompleto = this.getFullName(secretaria);
    this.secretariaForm.patchValue({
      id_persona: secretaria.id,
      nombre_persona: nombreCompleto  // Actualizar el nombre completo en el formulario
    });
    this.searchResults = [];
  }

  clearSelection(): void {
    this.secretariaForm.patchValue({ id_persona: null, nombre_persona: '' });
  }

  displaySecretaria(secretaria: any): string {
    return secretaria ? secretaria.nombres : '';
  }

  getFullName(user: any): string {
    return `${user.name} ${user.name_2 || ''} ${user.lastname_1} ${user.lastname_2}`.trim();
  }

  saveSecretaria(): void {
    if (this.secretariaForm.valid) {
      const formData = this.secretariaForm.value;

      if (this.isEditing) {
        this.moduloConfiguracionService.apiUpdateSecretaria(formData.id, formData).subscribe(() => {
          this.dialogRef.close(true);
        });
      } else {
        this.moduloConfiguracionService.apiCreateSecretaria(formData).subscribe(() => {
          this.dialogRef.close(true);
        });
      }
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
