import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ModuloMatriculaService } from '../../modulo-matricula.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-matri-estudiantes-list',
  templateUrl: './matri-estudiantes-list.component.html',
  styleUrl: './matri-estudiantes-list.component.css'
})
export class MatriEstudiantesListComponent {

  constructor (
    private moduloMatriculaService: ModuloMatriculaService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(){
    this.getEstudiantes();
  }

  listaUsuarios: Usuario[] = [];

  // Para el paginator
  totalUsuarios: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  searchTerm: string = '';

  getEstudiantes(): void {
    // this.moduloConfiguracionService.apiGetUsuarios()
    //   .subscribe((data: Usuario[]) => {
    //     console.log('data: ', data);
    //     this.listaUsuarios = data;
    //   }
    // );
    this.moduloMatriculaService.apiGetEstudiantes(this.pageIndex, this.pageSize, this.searchTerm)
      .subscribe((response: any) => {
        this.listaUsuarios = response.data;
        this.totalUsuarios = response.total;
      }
    );

  }

  // BUSQUEDA
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm = filterValue.trim().toLowerCase();
    if (event.type === 'click' || (event instanceof KeyboardEvent && event.key === 'Enter')) {
      this.pageIndex = 0;
      this.getEstudiantes();
    }
  }
  // PAGINACION
  pageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEstudiantes();
  }




  openNuevoEstudiante(): void {
    this.router.navigate(['/modulos/matricula/estudiantes/proceso/nuevo']);
  }

  openProcesoMatricula(id_estudiante: number): void {
    this.router.navigate([`/modulos/matricula/estudiantes/proceso/${id_estudiante}`],{
      state: { xId_estudiante: id_estudiante }
    });
  }

}


export interface Usuario {
  id:                  number;
  name:                string;
  name_2:              string;
  lastname_1:          string;
  lastname_2:          string;
  email:               string;
  usuario:             string;
  documento_identidad: string;
  tipo_documento:      number;
  domicilio:           string;
  celular:             null;
  nombres:             string;
}
