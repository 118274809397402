import { Component } from '@angular/core';
import { ModuloMatriculaService } from '../../modulo-matricula.service';
import { PageEvent } from '@angular/material/paginator';
import { ModuloAdministracionService } from 'src/app/pages/modulo-administracion/modulo-administracion.service';

@Component({
  selector: 'app-matri-estudiantes-matriculados-list',
  templateUrl: './matri-estudiantes-matriculados-list.component.html',
  styleUrl: './matri-estudiantes-matriculados-list.component.css'
})
export class MatriEstudiantesMatriculadosListComponent {
  constructor (
    private moduloMatriculaService: ModuloMatriculaService,
    private moduloAdministracionService: ModuloAdministracionService,
  ) { }

  ngOnInit(){
    this.getNiveles();
    this.getPeriodos();
    this.getTipoPlanes();
    // this.getPlanEstudios();
    this.getEstudiantesMatriculados();
  }

  // listaEstudiantes: Usuario[] = [];
  listaEstudiantes: any[] = [];

  listNiveles: any[] = [];
  listGrados: any[] = [];
  listPeriodos: any[] = [];
  listPlanTipos: any[] = [];

  selectedNivel: number | string = '';
  selectedGrado: number | string = '';
  selectedPlanTipo: number | string = '';
  selectedPeriodo: number | string = '';

  // Para el paginator
  totalEstudiantes: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  searchTerm: string = '';

  getEstudiantesMatriculados(): void {
    // this.moduloConfiguracionService.apiGetUsuarios()
    //   .subscribe((data: Usuario[]) => {
    //     console.log('data: ', data);
    //     this.listaUsuarios = data;
    //   }
    // );

    let params = {
      id_nivel: this.selectedNivel,
      id_plan_tipo: this.selectedPlanTipo,
      id_periodo: this.selectedPeriodo,
      id_grado: this.selectedGrado,
      page: this.pageIndex,
      pageSize: this.pageSize,
      search: this.searchTerm
    };

    this.moduloMatriculaService.apiGetEstudiantesMatriculados(params)
      .subscribe((response: any) => {
        this.listaEstudiantes = response.data;
        this.totalEstudiantes = response.total;
      }
    );

  }

  getPeriodos() {
    let params = { 'activo': true };
    this.moduloAdministracionService.apiGetPeriodos(params)
      .subscribe((data: any[]) => {
        this.listPeriodos = data;
      });
  }

  getNiveles() {
    let params = { 'activo': true, 'withGrados': true };
    this.moduloAdministracionService.getNiveles(params)
      .subscribe((data: any[]) => {
        this.listNiveles = data;
      });
  }

  getTipoPlanes() {
    this.moduloAdministracionService.getTipoPlanes()
      .subscribe((data: any[]) => {
        this.listPlanTipos = data;
      });
  }

  onNivelChange() {
    this.selectedGrado = '';
    this.getGrados();
    this.applyFilters();
  }

  getGrados() {
    if (this.selectedNivel) {
      let nivel = this.listNiveles.find(n => n.id === this.selectedNivel);
      if (nivel) {
        this.listGrados = nivel.grados;
      }
    } else {
      this.listGrados = [];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm = filterValue.trim().toLowerCase();
    if (event.type === 'click' || (event instanceof KeyboardEvent && event.key === 'Enter')) {
      this.pageIndex = 0;
      this.getEstudiantesMatriculados();
    }
  }
  applyFilters() {
    this.getEstudiantesMatriculados();
  }

  pageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEstudiantesMatriculados();
  }

  openAnularMatricula(id_estudiante: number, id_grupo: number){
    let params = {
      id_estudiante: id_estudiante,
      id_grupo: id_grupo,
    };

    this.moduloMatriculaService.apiAnularMatricula(params)
      .subscribe((response: any) => {
        this.getEstudiantesMatriculados();
      }
    );
  }






  getNombreNivel(id: number): string {
    const nivel = this.listNiveles.find(n => n.id === id);
    return nivel ? nivel.nombre : '';
  }

  getNombreTipoPlan(id: number): string {
    const tipoPlan = this.listPlanTipos.find(tp => tp.id === id);
    return tipoPlan ? tipoPlan.nombre : '';
  }

  getNombrePeriodo(id: number): string {
    const periodo = this.listPeriodos.find(p => p.id === id);
    return periodo ? periodo.codigo : '';
  }

}

export interface Usuario {
  id:                  number;
  name:                string;
  name_2:              string;
  lastname_1:          string;
  lastname_2:          string;
  email:               string;
  usuario:             string;
  documento_identidad: string;
  tipo_documento:      number;
  domicilio:           string;
  celular:             null;
  nombres:             string;
}
