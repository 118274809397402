import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-conf-programa-curricular-competencias-edit',
  templateUrl: './conf-programa-curricular-competencias-edit.component.html',
  styleUrl: './conf-programa-curricular-competencias-edit.component.css'
})
export class ConfProgramaCurricularCompetenciasEditComponent {

  competenciaForm: FormGroup;
  isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfProgramaCurricularCompetenciasEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private moduloConfiguracionService: ModuloConfiguracionService,
  ) {
    this.isEdit = data && data.competencia ? true : false;

    this.competenciaForm = this.fb.group({
      nombre: [data?.competencia?.nombre || '', Validators.required],
      detalle: [data?.competencia?.detalle || ''],
      id_area_curricular: [data?.id_area_curricular || '', Validators.required]
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.competenciaForm.valid) {
      const formData = this.competenciaForm.value;

      if (this.isEdit) {
        this.moduloConfiguracionService.apiUpdateCompetenciaCurricular(this.data.competencia.id, formData).subscribe((response: any) => {
          this.dialogRef.close(true);
        });
      } else {
        this.moduloConfiguracionService.apiCreateCompetenciaCurricular(formData).subscribe((response: any) => {
          this.dialogRef.close(true);
        });
      }
    }
  }

}
