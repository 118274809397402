import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
// import { RegistrarComponent } from './pages/registrar/registrar.component';
// import { IniciarcontratoComponent } from './iniciarcontrato/iniciarcontrato.component';
// import { FichaComponent } from './iniciarcontrato/ficha/ficha.component';
// import { ContratoComponent } from './iniciarcontrato/contrato/contrato.component';
// import { AuthGuard } from './auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { VistaModulosComponent } from './pages/vista-modulos/components/main/vista-modulos.component';
import { ScaffoldComponent } from './core/scaffold/scaffold.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { AuthGSJGuard } from './core/auth-gsj.guard';

// const routes: Routes = [

  // {path: 'registrar', component: RegistrarComponent},
  // {path: 'contrato', component: ContratoComponent, canActivate: [AuthGuard]},
  // {path: 'ficha', component: FichaComponent},
  // {path: 'newcontrato', component: IniciarcontratoComponent},

// ];

const config: ExtraOptions = {
  useHash: false,
};




// const routes: Routes = [

//   { path: 'menu', component: VistaModulosComponent, canActivate: [AuthGSJGuard] },
//   { path: '', redirectTo: 'menu', pathMatch: 'full' },
//   { path: 'login', component: LoginComponent },

//   {
//     path: 'modulos',
//     component: ScaffoldComponent,
//     canActivate: [AuthGSJGuard],
//     children: [
//       {
//         path: 'dashboard',
//         component: DashboardComponent
//       },
//       {
//         path: 'administracion',
//         loadChildren: () => import('./pages/modulo-administracion/modulo-administracion.module')
//           .then(m => m.ModuloAdministracionModule)
//       },
//       {
//         path: 'configuracion',
//         loadChildren: () => import('./pages/modulo-configuracion/modulo-configuracion.module')
//           .then(m => m.ModuloConfiguracionModule)
//       },
//       {
//         path: 'estudiante',
//         loadChildren: () => import('./pages/modulo-estudiante/modulo-estudiante.module')
//           .then(m => m.ModuloEstudianteModule)
//       },
//       {
//         path: 'financiero',
//         loadChildren: () => import('./pages/modulo-financiero/modulo-financiero.module')
//           .then(m => m.ModuloFinancieroModule)
//       },
//       {
//         path: 'matricula',
//         loadChildren: () => import('./pages/modulo-matricula/modulo-matricula.module')
//           .then(m => m.ModuloMatriculaModule)
//       },
//       {
//         path: 'padre',
//         loadChildren: () => import('./pages/modulo-padre/modulo-padre.module')
//           .then(m => m.ModuloPadreModule)
//       },
//       {
//         path: 'psicologia',
//         loadChildren: () => import('./pages/modulo-psicologia/modulo-psicologia.module')
//           .then(m => m.ModuloPsicologiaModule)
//       },
//       {
//         path: 'tutoria',
//         loadChildren: () => import('./pages/modulo-tutoria/modulo-tutoria.module')
//           .then(m => m.ModuloTutoriaModule)
//       },
//       {
//         path: '',
//         redirectTo: 'dashboard',
//         pathMatch: 'full'
//       },
//       {
//         path: '**',
//         component: NotFoundComponent,
//       }
//     ],
//   },
//   {
//     path: '**',
//     // component: NotFoundComponent,
//     redirectTo: 'menu',
//     // component: VistaModulosComponent,
//   }
// ]


const routes: Routes = [
  { path: '', redirectTo: 'modulos', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'modulos', component: VistaModulosComponent, canActivate: [AuthGSJGuard] },
  {
    path: 'modulos',
    component: ScaffoldComponent,
    canActivate: [AuthGSJGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'configuracion',
        loadChildren: () => import('./pages/modulo-configuracion/modulo-configuracion.module')
          .then(m => m.ModuloConfiguracionModule)
      },
      {
        path: 'administracion',
        loadChildren: () => import('./pages/modulo-administracion/modulo-administracion.module')
          .then(m => m.ModuloAdministracionModule)
      },
      {
        path: 'matricula',
        loadChildren: () => import('./pages/modulo-matricula/modulo-matricula.module')
          .then(m => m.ModuloMatriculaModule)
      },
      {
        path: 'contabilidad',
        loadChildren: () => import('./pages/modulo-contabilidad/modulo-contabilidad.module')
          .then(m => m.ModuloContabilidadModule)
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  },
  { path: '**', redirectTo: 'modulos' }
];











@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
