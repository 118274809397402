<!-- <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
  <mat-icon>menu</mat-icon>
</button> -->


<!-- <mat-toolbar color="primary" class="toolbar">
    <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
      <mat-icon>menu</mat-icon>
  </button>

  <div class="toolbar-center">
      <img src="assets/LOGO-BLANCO.png" alt="Logo" class="toolbar-logo" routerLink="/modulos">
  </div>

  <span class="toolbar-item-spacer"></span>

  <button mat-icon-button matTooltip="Notificaciones">
      <mat-icon>notifications</mat-icon>
  </button>
  <button mat-icon-button matTooltip="Mi perfil" [matMenuTriggerFor]="userAccountMenu">
      <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
      <button mat-menu-item routerLink="#">
          <mat-icon>person</mat-icon><span>Mi perfil</span>
      </button>
      <button mat-menu-item routerLink="#">
          <mat-icon>settings</mat-icon><span>Ajustes</span>
      </button>
      <mat-divider></mat-divider>
    <button mat-menu-item routerLink="#">
        <mat-icon>exit_to_app</mat-icon>Cerrar Sesión
    </button>
  </mat-menu>
</mat-toolbar> -->


<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav mode="side" [class.mat-elevation-z4]="true" class="app-sidenav" [opened]="true">
        <!-- <mat-nav-list>
          <mat-list-item routerLink="#">
              <mat-icon>dashboard</mat-icon><span>ITEM TEST</span>
          </mat-list-item>
          <mat-expansion-panel [class.mat-elevation-z0]="true">
              <mat-expansion-panel-header>
                  Nivel 2
              </mat-expansion-panel-header>
              <mat-nav-list>
                  <a mat-list-item routerLink="#">
                      <mat-icon>check</mat-icon><span>NIVEL 3</span>
                  </a>
                  <a mat-list-item routerLink="#">
                      <mat-icon>check</mat-icon><span>Nivel 3</span>
                  </a>
              </mat-nav-list>
          </mat-expansion-panel>
          <mat-expansion-panel [class.mat-elevation-z0]="true">
              <mat-expansion-panel-header>
                  Configurar algo
              </mat-expansion-panel-header>
              <mat-nav-list>
                  <a mat-list-item routerLink="#">Nivel 3</a>
                  <a mat-list-item routerLink="#">Nivel 3</a>
                  <a mat-list-item routerLink="#">Nivel 3</a>
              </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list> -->



    <div style="text-align: center;">
      <img src="assets/Logo-SJ.png" alt="Logo" style="width: 60%;" class="toolbar-logo" routerLink="/modulos">
    </div>

    <div style="margin: 1em 0em; text-align: center; font-weight: bold; padding: 2em; font-size: 16px;">
      <!-- {{xModuloNombre}} -->
      {{moduloParent?.nombre ?? ''}}
      <div style="width: 100%; height: 1px; background-color: #cccccc; margin: 1em auto 0;"></div>
    </div>




    <!-- <mat-nav-list>
      <ng-container *ngFor="let modulo of listaModulos">
        <ng-container *ngIf="modulo.hijos.length; else noChildren">
          <mat-expansion-panel
            [class.mat-elevation-z0]="true"
            [expanded]="true"
            (opened)="setExpanded(modulo.nombre)"
            (closed)="setExpanded(null)"
            [ngClass]="{'expansion-panel-active': expandedModule === modulo.nombre}">
            <mat-expansion-panel-header>
              <div class="flex-container">
                <mat-icon>check</mat-icon>
                <span class="header-text">{{modulo.nombre}}</span>
              </div>
            </mat-expansion-panel-header>
            <mat-nav-list>
              <a mat-list-item *ngFor="let moduloChild of modulo.hijos"
                  [routerLink]="moduloChild.url"
                  routerLinkActive="active-link">
                <div class="flex-container">
                  <mat-icon>check</mat-icon>
                  <span class="child-text">{{moduloChild.nombre}}</span>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </ng-container>
        <ng-template #noChildren>
          <mat-list-item [routerLink]="modulo.url"
                          routerLinkActive="active-link">
            <div class="flex-container">
              <mat-icon>check</mat-icon>
              <span class="no-children-text">{{modulo.nombre}}</span>
            </div>
          </mat-list-item>
        </ng-template>
      </ng-container>
    </mat-nav-list> -->


    <div class="new-sidenav-list" style="padding: 0em 2em;">
      <div *ngFor="let modulo of listaModulos" class="new-sidenav-list-item" [ngClass]="{'selected': isModuloSeleccionado(modulo)}">
        <div class="new-sidenav-list-item-parent" [routerLink]="modulo.url" routerLinkActive="active-link" (click)="seleccionarModulo(modulo)" [ngClass]="{'parent-selected': isModuloSeleccionado(modulo)}">
          <mat-icon>check</mat-icon>
          <span>{{modulo.nombre}}</span>
        </div>
        <div *ngFor="let moduloChild of modulo.hijos" class="new-sidenav-list-item-child" [ngClass]="{'selected': isModuloSeleccionado(moduloChild)}" (click)="seleccionarModulo(moduloChild)" [routerLink]="moduloChild.url" routerLinkActive="active-link">
          <mat-icon style="transform: scale(0.8);">check</mat-icon>
          <span>{{moduloChild.nombre}}</span>
        </div>
      </div>
    </div>





  </mat-sidenav>


  <mat-sidenav-content class="app-sidenav-content">
    <!-- <div color="primary" class="toolbar"> -->

      <!-- <button mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
          <mat-icon>menu</mat-icon>
      </button> -->

      <!-- <div class="toolbar-center">
          <img src="assets/LOGO-BLANCO.png" alt="Logo" class="toolbar-logo" routerLink="/modulos">
      </div> -->






      <!-- <div class="toolbar-v2-title">
        Configuración de Planes de Estudio
      </div> -->
      <!-- <span class="toolbar-item-spacer"></span> -->




      <!-- <button mat-icon-button matTooltip="Notificaciones">
          <mat-icon>notifications</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Mi perfil" [matMenuTriggerFor]="userAccountMenu">
          <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
          <button mat-menu-item routerLink="#">
              <mat-icon>person</mat-icon><span>Mi perfil</span>
          </button>
          <button mat-menu-item routerLink="#">
              <mat-icon>settings</mat-icon><span>Ajustes</span>
          </button>
          <mat-divider></mat-divider>
        <button mat-menu-item routerLink="#">
            <mat-icon>exit_to_app</mat-icon>Cerrar Sesión
        </button>
      </mat-menu> -->





      <!-- <div class="toolbar-v2-user">
        {{usuario.name ?? ''}} {{usuario.name_2 ?? ''}} {{usuario.lastname_1 ?? ''}} {{usuario.lastname_2 ?? ''}}
      </div> -->


    <!-- </div> -->

    <!-- <div class="app-sidenav-content">
    </div> -->

    <router-outlet>
    </router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
