<app-sv-page-content-header
  [titulo]="'Matriculando a ' + ((xIdEstudiante != 0)
  ? matriculaForm.get('estudiante')?.get('name')?.value + ' ' + matriculaForm.get('estudiante')?.get('lastname_1')?.value
  : 'Nuevo Estudiante')">
</app-sv-page-content-header>

<div class="page-content">

  <div class="page-content-body h-100">

    <div class="stepper-wrapper">
      <mat-horizontal-stepper [linear]="false" #stepper (selectionChange)="onStepChange($event)">

        <mat-step label="Confirmar datos">
          <app-matri-proceso-main-paso1></app-matri-proceso-main-paso1>
        </mat-step>

          <mat-step label="Seleccionar grupo">
          <app-matri-proceso-main-paso2></app-matri-proceso-main-paso2>
        </mat-step>

        <mat-step label="Pago de matrícula">
          <app-matri-proceso-main-paso3></app-matri-proceso-main-paso3>
        </mat-step>

      </mat-horizontal-stepper>
    </div>


  </div>



</div>


