<div mat-dialog-title class="dialog-header">
  <span class="dialog-title">Resumen de Matrícula</span>
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>

  <div class="row">

    <div class="col-md-6 col-sm-12">
      <h3 class="title-category">Personas</h3>
      <div class="person-list">
        <div
          class="container-persona"
          *ngFor="let persona of getPersonasArray(); let i = index"
        >
          <div class="grupo-circle">{{ i + 1 }}</div>
          <div class="grupo-column">
            <div class="grupo-nombre">
              {{ persona.name }} {{ persona.lastname_1 }} {{ persona.lastname_2 }}
            </div>
            <div class="grupo-info">
              <strong>Documento:</strong> {{ persona.documento_identidad }}
              (Tipo: {{ persona.tipo_documento }})
            </div>
            <div class="grupo-info">
              <strong>Relación:</strong> {{ persona.relacion }}
            </div>
            <div class="grupo-info">
              <strong>Correo:</strong> {{ persona.email }}
            </div>
            <div class="grupo-info">
              <strong>Dirección:</strong> {{ persona.domicilio }}
            </div>
            <div class="grupo-info">
              <strong>Celular:</strong> {{ persona.celular }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-sm-12">
      <h3 class="title-category">Detalles del Grupo</h3>
      <div class="grupo-details">
        <div>
          <strong>Sede:</strong> {{ grupoDetalles.nombre_sede }}
        </div>
        <div>
          <strong>Nivel:</strong> {{ grupoDetalles.nivel }}
        </div>
        <div>
          <strong>Grado:</strong> {{ grupoDetalles.grado }}
        </div>
        <div>
          <strong>Periodo:</strong> {{ grupoDetalles.periodo }}
        </div>
        <div>
          <strong>Tipo de Plan:</strong> {{ grupoDetalles.tipo_plan }}
        </div>
        <div>
          <strong>Plan de Estudio:</strong> {{ grupoDetalles.nombre_plan }}
        </div>
        <div>
          <strong>Nombre Grupo:</strong> {{ grupoDetalles.grupo?.nombre }}
        </div>
        <div>
          <strong>Código Grupo:</strong> {{ grupoDetalles.grupo?.codigo }}
        </div>
      </div>

      <div *ngIf="esNuevo">
        <h3 class="title-category">Detalles del Depósito</h3>
        <div class="deposito-details">
          <div>
            <strong>Monto:</strong> {{ matriculaForm.get('deposito')?.value.monto }}
          </div>
          <div>
            <strong>Tipo de Comprobante:</strong> {{ matriculaForm.get('deposito')?.value.tipo_comprobante }}
          </div>
          <div>
            <strong>Número de Receptor:</strong> {{ matriculaForm.get('deposito')?.value.nro_receptor }}
          </div>
          <div>
            <strong>Tipo de Depósito:</strong> {{ matriculaForm.get('deposito')?.value.tipo_deposito }}
          </div>
          <div>
            <strong>Número de Operación:</strong> {{ matriculaForm.get('deposito')?.value.nro_operacion }}
          </div>
        </div>
      </div>

    </div>

  </div>


</div>

<div mat-dialog-actions align="center">
  <button class="custom-action-button transparent" type="button" (click)="onClose()">Cancelar</button>
  <button class="custom-action-button primary" type="submit" (click)="confirmarMatricula()">Matricular</button>
</div>
