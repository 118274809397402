import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModuloConfiguracionService } from '../../../modulo-configuracion.service';

@Component({
  selector: 'app-conf-programa-curricular-areas-edit',
  templateUrl: './conf-programa-curricular-areas-edit.component.html',
  styleUrl: './conf-programa-curricular-areas-edit.component.css'
})
export class ConfProgramaCurricularAreasEditComponent {

  areaForm: FormGroup;
  isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfProgramaCurricularAreasEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private moduloConfiguracionService: ModuloConfiguracionService,
  ) {
    this.isEdit = data && data.area ? true : false;

    this.areaForm = this.fb.group({
      nombre: [data?.area?.nombre || '', Validators.required],
      detalle: [data?.area?.detalle || ''],
      id_programa_curricular: [this.isEdit ? data?.area.id_programa_curricular : data.id_programa_curricular || '', Validators.required]
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.areaForm.valid) {
      const formData = this.areaForm.value;

      if (this.isEdit) {
        this.moduloConfiguracionService.apiUpdateAreaCurricular(this.data.area.id, formData).subscribe((response: any) => {
          this.dialogRef.close(true);
        });
      } else {
        this.moduloConfiguracionService.apiCreateAreaCurricular(formData).subscribe((response: any) => {
          this.dialogRef.close(true);
        });
      }
    }
  }

}
