import { Component } from '@angular/core';

@Component({
  selector: 'app-conf-usuarios-main',
  template: `
    <router-outlet>
    </router-outlet>
  `,
  styles: [
  ]
})
export class ConfUsuariosMainComponent {

}
