import { Component } from '@angular/core';
import { ModuloContabilidadService } from '../../modulo-contabilidad.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-cont-estudiantes-list',
  templateUrl: './cont-estudiantes-list.component.html',
  styleUrl: './cont-estudiantes-list.component.css'
})
export class ContEstudiantesListComponent {

  constructor (
    private moduloContabilidadService: ModuloContabilidadService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(){
    this.getEstudiantes();
  }

  listaUsuarios: Usuario[] = [];

  // Para el paginator
  totalUsuarios: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  searchTerm: string = '';

  getEstudiantes(): void {
    this.moduloContabilidadService.apiGetEstudiantes(this.pageIndex, this.pageSize, this.searchTerm)
      .subscribe((response: any) => {
        this.listaUsuarios = response.data;
        this.totalUsuarios = response.total;
      }
    );

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm = filterValue.trim().toLowerCase();
    if (event.type === 'click' || (event instanceof KeyboardEvent && event.key === 'Enter')) {
      this.pageIndex = 0;
      this.getEstudiantes();
    }
  }
  // PAGINACION
  pageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEstudiantes();
  }

  goToEstudianteEstadoDeCuenta(id_estudiante: number): void {
    this.router.navigate([`/modulos/contabilidad/estudiantes/${id_estudiante}`]);
  }

  goToMensualidadesEstudiante(id_estudiante: number): void {
    this.router.navigate([`/modulos/contabilidad/estudiantes/${id_estudiante}/mensualidades`]);
  }

}


export interface Usuario {
  id:                  number;
  name:                string;
  name_2:              string;
  lastname_1:          string;
  lastname_2:          string;
  email:               string;
  usuario:             string;
  documento_identidad: string;
  tipo_documento:      number;
  domicilio:           string;
  celular:             null;
  nombres:             string;
}
